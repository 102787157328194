import React from 'react'
import * as Icon from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';
import { ReactComponent as Logo } from '../../assets/svg-icons/logo_footer.svg';
export default function Footer() {
  return (
    <>
   <footer className="footer-20192">
      <div className="site-section">
        <div className="container">

       
          <div className="row">

          <div className="col-xl-3 col-sm">
              <div className="logo-footer"> <Logo/>Connect Nex</div>
              <p className="copyright">
               
              </p>
            </div>
            <div className="col-xl-2 col-sm">
              <h3>Customers</h3>
              <ul className="list-unstyled links">
                {/*<li><a href="#">Help Center</a></li>*/}
                <li><a href="/report-issue">Report Issue</a></li>
              </ul>
            </div>
            <div className="col-xl-2 col-sm">
              <h3>Company</h3>
              <ul className="list-unstyled links">
                <li><a href="/about-us">About us</a></li>
                {/*<li><a href="#">Careers</a></li>*/}
                
              </ul>
            </div>
            <div className="col-xl-2 col-sm">
              <h3>Legal</h3>
              <ul className="list-unstyled links">
                <li><a href="/terms-and-cond">Terms &amp; Conditions</a></li>
                <li><a href="/policy-terms">Privacy Policy</a></li>
              </ul>
            </div>
            <div className="col-xl-3 col-md-3">
              <h3>Follow us</h3>
              <ul className="list-unstyled social">
                <li><a href="#"><Icon.Facebook size={30}/></a></li>
                <li><a href="#"><Icon.Twitter size={30}/></a></li>
                <li><a href="#"><Icon.Linkedin size={30}/></a></li>
                <li><a href="#"><Icon.Instagram size={30}/></a></li>
               </ul>
            </div>
            
          </div>
        </div>
      </div>
    </footer>
    </>
    )
}
