import { configureStore } from '@reduxjs/toolkit'
import counterReducer from '../redux-reducers/index'
import { pokemonApi } from '../rtk/pokemon';
import { jobsApi } from '../rtk/jobs';
import { authApi } from '../rtk/auth';
import {dashboardApi} from '../rtk/dashboard';
import { setupListeners } from '@reduxjs/toolkit/query';
import { searchApi } from '../rtk/Search';
import { profileApi } from '../rtk/profile';
import { MetaDataApis } from '../rtk/MetaData';
import  golbalReducer  from '../redux-reducers/global';
import  AuthState  from '../redux-reducers/authStore';
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import customizationReducer from './../redux-reducers/customizationReducer'
const persistConfig = {
  key: 'root',
  version: 1,
  storage,
}
const persistedReducer = persistReducer(persistConfig, AuthState);

export const store =  configureStore({
    reducer: {
        counter: counterReducer,
        golbalReducer:golbalReducer,
        authState:persistedReducer,
        customization:customizationReducer,
        [pokemonApi.reducerPath]: pokemonApi.reducer,
        [jobsApi.reducerPath]: jobsApi.reducer,
        [authApi.reducerPath]: authApi.reducer,
        [dashboardApi.reducerPath]: dashboardApi.reducer,
        [searchApi.reducerPath]: searchApi.reducer,
        [profileApi.reducerPath]:profileApi.reducer,
        [MetaDataApis.reducerPath]: MetaDataApis.reducer,
      },
      middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(pokemonApi.middleware),
})

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
//setupListeners(store.dispatch)