import React from 'react'

function Communication() {
  return (
    <div className='pad-10'>

      <div className='card-header flex-layout justify-content-between'>
        <div className='align-items-start flex-column'>
          Personal Information
          <p className='profile-tagline'>
            Update your personal informaiton
          </p>
        </div>
        <div className='align-items-end flex-column'>
          <button className='btn-style secondry-color'>save</button>
          <button className='btn-style secondry-color'>Reset</button>
        </div>


      </div>

      <div className='card-body'>
        

        <div class="form-group row pad-5">
          <label class="col-xl-3 col-lg-3 col-form-label font-12">Job Application Status</label>
          <div class="col-lg-9 col-xl-6">
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="option1" />
              <label class="form-check-label" for="inlineRadio1">No</label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="option2" />
              <label class="form-check-label" for="inlineRadio2">Yes</label>
            </div>
          </div>
        </div>

        <div class="form-group row pad-5">
          <label class="col-xl-3 col-lg-3 col-form-label font-12">Jobs List</label>
          <div class="col-lg-9 col-xl-6">
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="option1" />
              <label class="form-check-label" for="inlineRadio1">No</label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="option2" />
              <label class="form-check-label" for="inlineRadio2">Yes</label>
            </div>
          </div>
        </div>

        <div class="form-group row pad-5">
          <label class="col-xl-3 col-lg-3 col-form-label font-12">Promotion</label>
          <div class="col-lg-9 col-xl-6">
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="option1" />
              <label class="form-check-label" for="inlineRadio1">No</label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="option2" />
              <label class="form-check-label" for="inlineRadio2">Yes</label>
            </div>
          </div>
        </div>
        
      </div>
    </div>
  )
}

export default Communication