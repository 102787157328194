import React,{useEffect,useState} from 'react'
import * as Icon from 'react-bootstrap-icons';
import Skeleton from '@mui/material/Skeleton';


function SideBarJobsSkeleton(props) {

  return (
    <div className='card-body'>

       

      <div className='card-body'>
        <p className='title'>
       <Skeleton variant="rectangular" height={30} />
        </p>
        <p className='p-2'> <Skeleton variant="rectangular" height={30} /></p>
                    <div >
                        
                       
                    </div>
                    </div>
      



    </div>
  )
}

export default SideBarJobsSkeleton