import React, { useState,useEffect } from 'react'
import DocViewer, { PDFRenderer, PNGRenderer, DocViewerRenderers } from "@cyntler/react-doc-viewer";
import { useSelector } from 'react-redux';
import {useSaveResumeFileMutation} from './../../rtk/profile';
import notify from '../../utils/Utils';
import {useLazyGetResumeDetailsQuery} from './../../rtk/profile';
import FileViewer from 'react-file-viewer';
import Iframe from 'react-iframe'
export default function Resume(props) {
  const {setIsmodified} =props;
  const [selectedDocs, setSelectedDocs] = useState();
  const [errorMessage, setErrorMessage] = useState("");
  const [resumeDetails, setResumeDetails] = useState();
  const [fileType, setFileType] = useState("docx");
  const [file, setFile] = useState();
  const [resumeLoading, setResumeLoading] = useState(true);
  const userId = useSelector((state) => state.authState.userId);
  const usefilenamerId = useSelector((state) => state.authState.userId + state.authState.firstName);
  const [fetchResumeDetails, { data: projectData, error: projectError, isLoading: projectIsLoading }] = useLazyGetResumeDetailsQuery();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    fetchResumeDataList();
  }, [])


  async function fetchResumeDataList() {
    const response = await fetchResumeDetails();
    console.log("fetchResumeDataList Datarespi=osne :: " + JSON.stringify(response.data));
   
    //console.log("response.data.length ::"+response.data.length)
    setResumeDetails(response.data)
    //setFile(response.data);

    console.log("response.data.fileName ::"+response.data.fileName.split(".").pop());
    
   setSelectedDocs({
      uri: process.env.REACT_APP_HOSTNAME+"/index.php/profile/file?name="+response.data.fileName,
      fileName: response.data.fileName,
      fileType:response.data.fileName.split(".").pop()
     })
     setFileType(response.data.fileName.split(".").pop())
    setResumeLoading(false);
  }

  const [
    updateResume, // This is the mutation trigger
    { error: updateProgramError, isLoading: isUpdating }, // This is the destructured mutation result
  ] = useSaveResumeFileMutation()
  const handleSubmit = async() =>{
    console.log(" In handleSubmit")
    setLoading(true);
    const returned = await updateResume({ filename : selectedDocs.fileName,resume :file } ).unwrap();
    setLoading(false);
    console.log("returned ::"+JSON.stringify(returned))
    if (returned.status == 'success') {
      notify("success", "Success Message", "Resume uploaded Successfully");
    } else {
      notify("danger", "Rrror Message", "Error in saving skills");
    }
   
  }
  const docs = [
    // { uri: "https://url-to-my-pdf.pdf" },
    { uri: "" }, // Local File
  ];
  console.log("selectedDocs ::" + JSON.stringify(selectedDocs))
  const handleFileUpdate = (e) => {
    console.log("handleFileUpdate :: " + JSON.stringify(e.target.files[0]))
    //setResume([e.target.files[0]]);
  }

  console.log("fileType ::"+fileType)

  return (
    <div>

      <div className='pad-10'>
        <div className='card-header flex-layout justify-content-between'>
          <div className='align-items-start flex-column'>
            Resume details
            <p className='profile-tagline'>
              Update your Resume
            </p>
          </div>
          <div className='align-items-end flex-column'>
            <button className='btn-style secondry-color' onClick={handleSubmit} disabled={loading}>

           
             {!loading && <span className='indicator-label'>  Save Your Resume</span>}
                {loading && (
                  <span className='indicator-progress' style={{ display: 'block' }}>
                    Please wait...
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
            </button>

          </div>


        </div>
        <div className='card-body'>
          <div className="form-group row pad-5  ">

            <label className="col-xl-3 col-lg-3 col-form-label font-14"> </label>
            <div className="col-lg-9 col-xl-6">

              <input
                name="projectName"
                type="file"
                className={
                  'form-control'
                }
                onChange={(el) => {
                  const fileExt = el.target.files[0].name.split('.').pop();
                  console.log("fileExt ::" +  el.target.files[0].filePath)
                  
                  if (fileExt == 'pdf' || fileExt == 'docx') {
                    setSelectedDocs({
                      uri: window.URL.createObjectURL(el.target.files[0]),
                      fileName: usefilenamerId + Math.random() * 10,
                      fileType:fileExt
                     })
                    setFile(el.target.files[0]);
                    setFileType(fileExt);
                    setErrorMessage();
                  } else {
                    setErrorMessage("Only PDF or docx file is allowed")
                  }
                }

                }
              />
              <div className='error-message pt-2'>{errorMessage}</div>
            </div>

          </div>
        </div>
        {
          selectedDocs && 
         
        <>
        { fileType == 'docx' ? ( 
          <>
          
        <FileViewer fileType={selectedDocs.fileType} filePath={selectedDocs.uri} />
        </>) :(
         <>
       
         <DocViewer documents={[selectedDocs]}
          style={{height:1000}}
          pluginRenderers={DocViewerRenderers}
          />
          </>
        )
          
        }
        </>

 
   /*
          
         
*/
          
        }
        
      </div>
    </div>
  )
}
