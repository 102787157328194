import React,{useState,useEffect} from 'react'
import JobCard from '../jobCard/JobCard'
import { useLazyGetJobsByFilterQuery,useLazyGetApplliedJobQuery } from '../../rtk/jobs'
import { useSelector,useDispatch } from 'react-redux';
import JSpinner from '../jspinner/JSpinner';
import ReactPaginate from 'react-paginate';
import { updateApplied } from '../../redux-reducers/global';
import JobListComponentSkeleton from './JobListComponentSkeleton';

export default function JobListComponent() {
  const [skillsState, setSkillsState] = useState([]);
  const dispatch = useDispatch();
  const [jobTypeState, setJobTypeState] = useState([]);
  const skillsList = useSelector((state) => state.authState.skills);
  const searchQueryDataObj = useSelector((state) => state.golbalReducer.searchQueryData);
  const jobFilterDataObj = useSelector((state) => state.golbalReducer.jobFilterData);
  const [pageCount, setPageCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [appliedJobs, setAppliedJobs] = useState();
  const [loading, setLoading] = useState();
  const [trigger, result,{ 
    isLoading, isError, data, error 
   }] = useLazyGetJobsByFilterQuery(skillsState,jobTypeState);

   const [getaAppliedJobs] = useLazyGetApplliedJobQuery(skillsState,jobTypeState);

   
  useEffect(() => {

    const fetchAppliedJob = async () => {
      const returned = await getaAppliedJobs();
     console.log("fetchAppliedJob returned :::"+JSON.stringify(returned.data));
     setAppliedJobs(returned.data);
     dispatch(updateApplied(returned.data));
    }
   
    fetchAppliedJob();
   
    const fetchData = async () => {
      const returned = await callForData();
      if(returned.isSuccess) {
        setPageCount(Math.ceil(returned.data.totalCount[0].count/10));
      }
    }
    fetchData()
    .catch(console.error);
    
   }, [jobFilterDataObj,searchQueryDataObj])
   

   async function callForData(page, type){
    const search = window.location.search;
const params = new URLSearchParams(search);
const foo = params.get('search');
console.log("params::"+params);
console.log("search::"+foo);
    let json= null;
    if(type =='hook'){
      json = {  jobType : undefined, skills: undefined,page:pageNumber }
    }else {
      json = {  jobType : undefined, skills: undefined,page:page }
    }
    
    console.log("searchQueryDataObj.searchValue::"+searchQueryDataObj.searchValue);
    console.log("searchQueryDataObj.skillsList::"+skillsList);
    
    if(searchQueryDataObj.searchValue.length==0 && skillsList != undefined){
      console.log("In set preferered job ")
      json.skills= skillsList;
    }

    if(searchQueryDataObj.searchValue.length != 0){
      json.skills= searchQueryDataObj.searchValue;
    }
    if(jobFilterDataObj != undefined){
      json.jobType= jobFilterDataObj.toString();
    }
    console.log("json ;;; "+JSON.stringify(json))
    setLoading(true);
    var returned =await trigger(json);
    setLoading(false);
    return returned;
   }


const handlePageClick = ({ selected: selectedPage }) => {
   
    setPageNumber(selectedPage)
    callForData(selectedPage,"event");
};


  return (
    <div>
       {
       result.isError ? (
        <><div className='pad-t-30'>Oh no, there was an error</div></>
      ) : result.isLoading || result.isFetching ? (
        <><div
        style={{
           
            paddingTop:"20px"
        }}
    >
     <>
     <JobListComponentSkeleton/>
     <JobListComponentSkeleton/>
     <JobListComponentSkeleton/>
     <JobListComponentSkeleton/>
     <JobListComponentSkeleton/>
     </>
    </div>
    </>
      ) : result.isSuccess  &&  result.data.length ==0 ? (
        <>
       
       <div className='pad-t-30'> No Data To Display</div>
        </>
      ): result.isSuccess  ? (
        <>
      
        {
     appliedJobs && result.data.data.map((jobDetails, index) => (
        <>
        
        
              <JobCard jobDetails={jobDetails} appliedJobs={appliedJobs} setAppliedJobs ={setAppliedJobs}/>
           </>
            ))
          }
           
      </>
      ) : null}


     <ReactPaginate
     previousLabel={"prev"}
     nextLabel={"next"}
     breakLabel={"..."}
     pageCount={pageCount}
     marginPagesDisplayed={2}
     pageRangeDisplayed={5}
     onPageChange={handlePageClick}
     breakClassName={"break-me"}
     containerClassName={"pagination"}
     subContainerClassName={"pages pagination"}
     activeClassName={"active"}/>
    </div>
  )
}
