
import { useFormik } from 'formik'
import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import * as Yup from 'yup';
import clsx from 'clsx';
import { ReactComponent as YourSvg } from './../../assets/SVG-PL/business-electricity-connect-energy-commerce-svgrepo-com.svg';
import { useDispatch } from 'react-redux';
import { useGetLoginMutation } from '../../rtk/auth';
import notify from '../../utils/Utils';
import CryptoJS from 'crypto-js';
import { ReactComponent as Logo } from '../../assets/svg-icons/logo.svg';

export default function Login(props) {
  
  const {storeUserData,enableRegister,setIsOpenLogin,onHide} =props;

  const initialValues = {
    email: '',
    password: '',
  }
  const [
    login, // This is the mutation trigger
    {error: updateProgramError , isLoading: isUpdating }, // This is the destructured mutation result
  ] =useGetLoginMutation();
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  let navigate = useNavigate();
  const loginSchema = Yup.object().shape({
    email: Yup.string()
      .email('Wrong email format')
      .min(3, 'Minimum 3 symbols')
      .max(50, 'Maximum 50 symbols')
      .required('Email is required'),
    password: Yup.string()
      .min(3, 'Minimum 3 symbols')
      .max(50, 'Maximum 50 symbols')
      .required('Password is required'),
  })
  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true)
      initialValues.email = values.email;
      console.log("process.env.REACT_APP_SECRET_KEY ::"+process.env.REACT_APP_SECRET_KEY)
      const password =CryptoJS.AES.encrypt(values.password, process.env.REACT_APP_SECRET_KEY).toString();
      initialValues.password =password; 
      let errorres;
      const returned =await login(initialValues).unwrap().catch(res => errorres = res);
      console.log("Login returned ::"+JSON.stringify(returned))
      if(errorres != undefined){
        console.log("process.env. error parse ::"+errorres.data.errorMessage)
        setError(errorres.data.errorMessage);
        setLoading(false);
      }else {
        console.log("Login in login return succuess ")
        storeUserData(returned);
        onHide(false)
      }

      /*
      if(returned.userId != null){
        console.log("Login in login return succuess ")
        storeUserData(returned);
        onHide(false)
       }else {
        console.log("Login in login return not succuess ")
        setLoading(false);
        setError(errorres.data.errorMessage);
        //notify("danger","Rrror Message","Error in uploaded Successfully");
       }
        */
     }
  })

  return (
    <>
    <div className='row'>
      <div className='col-xl-6 col-md-6 d-none d-lg-block'>
      <div className='vertical-center'>
      <h4 className="text-center mb-4">Plan Next with Connect Nex , Welocme to Connect Nex</h4>
        <div className='justify-svg-center'><YourSvg/></div>
        </div>
        </div>
      <div className='col-md-6  col-sm-12'>
    
      <h5 className="text-center mb-4">Have an account? Login </h5>
      <div className='row justify-content-center error-message p-2'> {error}</div>
      {formik.status ? (
        <div>
          <div className='mb-lg-15 alert alert-danger'>
            <div className='alert-text font-weight-bold'>{formik.status}</div>
          </div>
        </div>
      ) : (
        ""
      )}
      <form className="login-form" onSubmit={formik.handleSubmit}>
        <div className="form-group">
          <div className="mb-3">
            <div>
              <label className="col-form-label font-14">Email</label>
            </div>
            <input type="email" placeholder="name@example.com"
              {...formik.getFieldProps('email')}
              className={clsx(
                'form-control',
                { 'is-invalid': formik.touched.email && formik.errors.email },
                {
                  'is-valid': formik.touched.email && !formik.errors.email,
                }
              )}
              name='email'
              autoComplete='off'
            />
             {formik.touched.email && formik.errors.email && (
              <div className='error-message p-2'> {formik.errors.email}</div>
             )}
          </div>
        </div>
        <div className="form-group">
          <div className="mb-3">
            <div>
              <label className="col-form-label font-14">Password</label>
            </div>
            <input type="password" placeholder="Password"
              {...formik.getFieldProps('password')}
              className={clsx(
                'form-control',
                { 'is-invalid': formik.touched.password && formik.errors.password },
                {
                  'is-valid': formik.touched.password && !formik.errors.password,
                }
              )}
            />
             {formik.touched.password && formik.errors.password && (
              <div className='error-message p-2'> {formik.errors.password}</div>
             )}
          </div>
        </div>
        <div className="form-group">
          <div >
          </div>
        </div>
        <div className="form-group">
          <div className='d-grid mb-10'>
            <button
              type='submit'
              className="btn btn-primary rounded submit"
              disabled={formik.isSubmitting || !formik.isValid}
            >
              {!loading && <span className='indicator-label'>Continue</span>}
              {loading && (
                <span className='indicator-progress' style={{ display: 'block' }}>
                  Please wait...
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>

            <div className="flex-layout justify-content-center ">
              Don't have account, <Link onClick={enableRegister} className='d-flex flex-row-reverse link ms-1'>Register</Link>
            </div>
           
          </div>
        </div>
      </form>
      </div>
      </div>
    </>
  )
}
