import React, { useState, useEffect } from 'react'
import { useGetJobTypesQuery,useGetSkillsQuery } from '../../rtk/jobs';
import CreatableSelect from 'react-select/creatable';
import { Formik, Field, Form, ErrorMessage,useFormikContext } from 'formik';
import * as Yup from 'yup';
import { stepperDetails } from '../../redux-reducers/global';
import { useSelector, useDispatch } from 'react-redux';
import JSpinner from '../../components/jspinner/JSpinner';
import ReactGA from "react-ga4";

function CreateJobPost(props) {
  const dispatch = useDispatch();
  const { profileFields, setProfileFields,submitRef } = props;
  const { data, error, isLoading } = useGetJobTypesQuery({skip:true});

  
  
  const [cValue, setCValue] = useState([]);
  const formikProps = useFormikContext()
  const initialJobCreate = {
    jobTitle: "",
    workType: "",
    jobType: new Array(),
    noOfOpening: "",
    jobReqTimePeriod: "",
    skills:new Array(),
    minExp:"",
    maxExp:"",
    period:""
  };

  function validationSchema() {
    return Yup.object().shape({
      hiringCompany: Yup.string()
      .min(2, 'Company name must be at least 2 characters'),
      clientJobLink: Yup.string()
      .min(5, 'job Link Url must be at least 5 characters'),
      workType: Yup.string().required('Work Type is required'),
      jobCity : 
      Yup.string().
      typeError("You must specify a number").
      test("workType","City is required",
        (value, validationContext) => {
          const {
            createError,
            parent: { workType }
          } = validationContext;
          if (!workType) {
            return createError({
              message: "Select employee work type"
            });
          }
          console.log("value::"+value + " workType::"+workType)
          if (workType !='Remote' && value  == undefined) {
            console.log("value inside ::"+value)
            return false;
          }else {
            return true;
          }
      }),
      
      jobState : Yup.string().
      typeError("You must specify a number").
      test("workType","State is required",
        (value, validationContext) => {
          const {
            createError,
            parent: { workType }
          } = validationContext;
          if (!workType) {
            return createError({
              message: "Select employee work type"
            });
          }
          console.log("value::"+value + " workType::"+workType)
          if (workType !='Remote' && value  == undefined) {
            console.log("value inside ::"+value)
            return false;
          }else {
            return true;
          }
      }) 
      .min(2, 'State must be at least 2 characters'),
      jobCountry: Yup.string().
      typeError("You must specify a number").
      test("workType","Country is required",
        (value, validationContext) => {
          const {
            createError,
            parent: { workType }
          } = validationContext;
          if (!workType) {
            return createError({
              message: "Select employee work type"
            });
          }
          console.log("value::"+value + " workType::"+workType)
          if (workType !='Remote' && value  == undefined) {
            console.log("value inside ::"+value)
            return false;
          }else {
            return true;
          }

        
      })
      .min(2, 'Country must be at least 2 characters'),
      jobTitle: Yup.string().required('Job Title is required')
              .min(4, 'Job Title must be at least 6 characters')
              .max(150, 'Job Title must not exceed 50 characters'),
      
      jobType: Yup.array()
        .required('Job Type is required').min(1),
      noOfOpening: Yup.number().required('No Of Opening is required'),
      jobReqTimePeriod: Yup.string()
        .required('Job Req Time Period is required'),
      skills: Yup.array()
        .required('Skills is required').min(1),
      minExp: Yup.number()
      .required('Min Exp is required'),
      maxExp:Yup.number()
    });
  }

  const handleCheckoutChange = (event) => {
    const { name, value } = event.target;
    setCValue(oldArray => [...oldArray, value]);
  }

  function multiSelect(value){
    setCValue(oldArray => [...oldArray, value]);
    
  }

  useEffect(() => {
   
  }, [])
  
   
  const styles = {
    multiValue: styles => {
      return {
        ...styles,
        backgroundColor: "papayawhip"
      };
    }
  };
  const options = [ ];
  if(data != undefined){
    data.SkillsData.map((item) => {
      options.push({'value': item.id, 'label': item.skillName});
    })
  }
  
  const myOnPasteHandler =()=>{
    console.log("In myOnPasteHandler ")
  }
  console.log("options::"+JSON.stringify(options))
   
  function handleSubmit(data) {
    setProfileFields(data);
   
    dispatch(stepperDetails({"presentStep":0,"toStep":1,"step0Data":true}));
    ReactGA.event({
      category: 'Job Post',
      action: 'Action : JOb posting',
      label:'JOb posting Done '
    });
  }
  
  return (
    <div className='pad-t-15'>
<Formik initialValues={profileFields} validationSchema={validationSchema}
          onSubmit={handleSubmit}>
           {({ errors, touched, resetForm,isValidating,handleChange,setFieldValue  }) => (
            <Form>
              
      <div className='card '>
        <div className='card-body'>
          <div className='card-header flex-layout justify-content-between'>
            <div className='align-items-start flex-column'>
              Create Job
              <p className='profile-tagline'>
                Update your Job post informaiton
                </p>
            </div>

          </div>

          {
            /*
                   <div className="form-group row pad-5">
                      <label className="col-xl-3 col-lg-3 col-form-label font-14">How would you like to post job</label>
                      <div className="col-lg-9 col-xl-6">
                        <div className="form-check form-check-inline">
                          <input className="form-check-input font-14" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="option1" />
                          <label className="form-check-label font-14" for="inlineRadio1">continue with you job post</label>
                        </div>
                        <div className="form-check form-check-inline">
                          <input className="form-check-input font-14" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="option2" />
                          <label className="form-check-label font-14" for="inlineRadio2">create new job post</label>
                        </div>
                      </div>
                    </div>
                   */
          }
          
          <div className='pad-t-10'>
          <div className="form-group row pad-5  ">
              <label className="col-xl-3 col-lg-3 col-form-label font-14">Hiring for company</label>
              <div className="col-lg-9 col-xl-6">
                
                 <Field
                  name="hiringCompany"
                  type="text"
                 
                  className={
                    'form-control' +
                    (errors.hiringCompany && touched.hiringCompany ? ' is-invalid' : '')
                  }
                />
                <ErrorMessage
                  name="hiringCompany"
                  component="div"
                  className="invalid-feedback"
                />
              </div>
            </div>
            <div className="form-group row pad-5  ">
              <label className="col-xl-3 col-lg-3 col-form-label font-14">Job link by client</label>
              <div className="col-lg-9 col-xl-6">
                
                 <Field
                  name="clientJobLink"
                  type="text"
                 
                  className={
                    'form-control' +
                    (errors.clientJobLink && touched.clientJobLink ? ' is-invalid' : '')
                  }
                />
                <ErrorMessage
                  name="clientJobLink"
                  component="div"
                  className="invalid-feedback"
                />
              </div>
            </div>

            <div className="form-group row pad-5  ">
              <label className="col-xl-3 col-lg-3 col-form-label font-14">Job Title</label>
              <div className="col-lg-9 col-xl-6">
                
                 <Field
                  name="jobTitle"
                  type="text"
                 
                  className={
                    'form-control' +
                    (errors.jobTitle && touched.jobTitle ? ' is-invalid' : '')
                  }
                />
                <ErrorMessage
                  name="jobTitle"
                  component="div"
                  className="invalid-feedback"
                />
              </div>
            </div>
            <div className="form-group row pad-5">
            <label className="col-xl-3 col-lg-3 col-form-label font-14">Where will employee work</label>
            <div className="col-lg-9 col-xl-6">
              <div className="form-check form-check-inline">
                 <Field className={"form-check-input font-14"+
                    (errors.workType && touched.workType ? ' is-invalid' : '')}
                  name="workType"
                  type="radio"
                  value="Remote"
                />
                <label className="form-check-label font-14" for="inlineRadio1">Remote</label>
              </div>
              <div className="form-check form-check-inline">

              <Field className={"form-check-input font-14"+
                    (errors.workType && touched.workType ? ' is-invalid' : '')}
                  name="workType"
                  type="radio"
                 
                  value="Office"
                />
               
                <label className="form-check-label font-14" for="inlineRadio2">Office</label>
              </div>
              <ErrorMessage
                  name="workType"
                  component="div"
                  className="invalid-feedback"
                />
                 <div className="form-check form-check-inline">

<Field className={"form-check-input font-14"+
      (errors.workType && touched.workType ? ' is-invalid' : '')}
    name="workType"
    type="radio"
   
    value="hybrid"
  />
 
  <label className="form-check-label font-14" for="inlineRadio2">Hybrid</label>
</div>
<ErrorMessage
    name="workType"
    component="div"
    className="invalid-feedback"
  />

                
            </div>
          </div>
            <div class="form-group row pad-5">
          <label class="col-xl-3 col-lg-3 col-form-label font-14">Job Location</label>
          <div class="col-lg-9 col-xl-6">
            <div class="row">
              <div class="col">
                <Field type="text" class={"form-control "+
                    (errors.jobCity && touched.jobCity ? ' is-invalid' : '')} placeholder="City" 
                 id="jobCity"
                 name="jobCity"
                 />
                  <div className="invalid-feedback"> {errors.jobCity} </div>    
              </div> - 
              <div class="col">
                <Field type="text" class={"form-control "+
                    (errors.jobState && touched.jobState ? ' is-invalid' : '')} placeholder="State" 
                 id="jobState"
                 name="jobState"
                 />
                  <div className="invalid-feedback"> {errors.jobState} </div>   
              </div>
              <div class="col">
                <Field type="text" class={"form-control "+
                    (errors.jobCountry && touched.jobCountry ? ' is-invalid' : '')} placeholder="Country" 
                 id="jobCountry"
                 name="jobCountry"
                 />
                  <div className="invalid-feedback"> {errors.jobCountry} </div>   
              </div>
             
              <ErrorMessage
                  name="jobCity"
                  component="div"
                  className="invalid-feedback"
                />
                <ErrorMessage
                  name="jobState"
                  component="div"
                  className="invalid-feedback"
                />
                <ErrorMessage
                  name="jobCountry"
                  component="div"
                  className="invalid-feedback"
                />
              
            </div>
            <div className="invalid-feedback"> {errors.jobCity} </div>           

          </div>
        </div>
          </div>
        </div>
      </div>

      <div className='card '>
        <div className='card-body'>
        




          <div className="form-group row pad-5">
            <label className="col-xl-3 col-lg-3 col-form-label font-14">Job Type</label>
            <div className="col-lg-9 col-xl-9">

              {error ? (
                  <div className='pad-t-30'> Oh no, there was an error</div>
              ) : isLoading ? (
                <><div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '30vh',
                }}
            >
               <JSpinner/>
            </div></>
              ) : data ? (
                <>
                  {
                    data.JobType.map((jobTypes, index) => (
                      <>
                        <div className="form-check form-check-inline">

                        <label>
              <Field className="form-check-input" type="checkbox" name="jobType" 
              value=  {jobTypes.jobType} />
              {jobTypes.jobType}
            </label>
                        </div>

                      </>
                    ))
                  }
                </>
              ) : null}

                <div role="group" aria-labelledby="checkbox-group">
          
                {errors.jobType && touched.jobType  ? (
          <div className='danger-font'>{errors.jobType}</div>
         ) : null}
          </div>

         </div>
          </div>

          <div className="form-group row pad-5">
            <label className="col-xl-3 col-lg-3 col-form-label font-14">Skills</label>
            <div className="col-lg-9 col-xl-6">
            
            <Field  component={CustomInputComponent} 
            myOnPasteHandler ={myOnPasteHandler}
            styles={styles+
              (errors.skills ? ' is-invalid' : '')}
               options={options} 
               id="skills"
             name="skills"
             onPaste={myOnPasteHandler}
            placeholder="Select Skill" 
            onChange={e=>setFieldValue('skills',e)}

/>
{errors.skills && touched.skills ? (
          <div className='danger-font'>{errors.skills}</div>
         ) : null}
             
              <ErrorMessage
                  name="skills"
                  component="div"
                  className="invalid-feedback"
                />
            </div>
            
          </div>
          <div class="form-group row pad-5">
          <label class="col-xl-3 col-lg-3 col-form-label font-14">Exp required for the job</label>
          <div class="col-lg-9 col-xl-6">
            <div class="row">
              <div class="col">
                <Field type="text" class={"form-control "+
                    (errors.minExp && touched.minExp ? ' is-invalid' : '')} placeholder="Minimum" 
                 id="minExp"
                 name="minExp"
                 />
              </div> - 
              <div class="col">
                <Field type="text" class={"form-control "+
                    (errors.maxExp && touched.maxExp ? ' is-invalid' : '')} placeholder="Maximum" 
                 id="maxExp"
                 name="maxExp"
                 />
              </div>
              
              
              {errors.minExp && errors.maxExp && touched.minExp && touched.maxExp   ? (
          <div className='danger-font'>{'Min and Max Salary is required'}</div>
         ) : null}
            </div>


          </div>
        </div>
        </div>
      </div>





      <div className='card '>
        <div className='card-body'>
          <div className="form-group row pad-5">
            <label className="col-xl-3 col-lg-3 col-form-label font-14">No. of opening</label>
            <div className="col-lg-9 col-xl-6">
              <Field as="select" className={"form-control "+
                    (errors.noOfOpening && touched.noOfOpening ? ' is-invalid' : '')}
                id="noOfOpening"
                name="noOfOpening"
              >
                <option value="">Select</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6">6-10</option>
                <option value="7">10-20</option>
                <option value="8">Multiple Position</option>
              </Field>
              <ErrorMessage
                  name="noOfOpening"
                  component="div"
                  className="invalid-feedback"
                />
            </div>
          </div>
          <div className="form-group row pad-5">
            <label className="col-xl-3 col-lg-3 col-form-label font-14">How quickly do you need to hire</label>
            <div className="col-lg-9 col-xl-6">
            <Field as="select" className={"form-control "+
                    (errors.jobReqTimePeriod && touched.jobReqTimePeriod ? ' is-invalid' : '')}
                id="jobReqTimePeriod"
              
                name="jobReqTimePeriod"
               
              >
                <option>Select</option>
                <option>Immediately  </option>
                <option>Less then month</option>
                <option>Less then 2 months </option>
                <option>Less then 3 months </option>

              </Field>
              <ErrorMessage
                  name="jobReqTimePeriod"
                  component="div"
                  className="invalid-feedback"
                />
            </div>
          </div>
          
        </div>
      </div>

      <button ref={submitRef} name="subBut" type="submit" style={{display:'none'}}>Submit</button>
<input type="hidden"  name="hiddenErrors" id="hiddenErrors" value={JSON.stringify(errors) }></input>
<input type="hidden"  name="hiddentouched" id="hiddenErrors" value={JSON.stringify(touched) }></input>

 
      </Form>
          )}
      </Formik>
      
    </div>
  )
}
const CustomInputComponent = ({
  
  field, // { name, value, onChange, onBlur }
  form: { touched, errors,handleChange,form }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  options,
  styles,
  myOnPasteHandler,
  ...props
}

) => (
 
<CreatableSelect

closeMenuOnSelect={true}
isMulti
options={options}
onPaste={myOnPasteHandler}
{...field} {...props}

/>

);


export default CreateJobPost