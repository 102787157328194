import React,{useEffect,useState} from 'react'
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import VerticalTab from './../mrt-table/VerticalTab'
import StatusCard from './../mrt-table/StatusCard'
import {useLazyGetJobAppliacationDetailsQuery} from './../../rtk/dashboard';
import { jobStatusDetails } from '../../redux-reducers/global';
import { useSelector, useDispatch } from 'react-redux';
import JSpinner from '../jspinner/JSpinner';

export default function ApplicationDetails() {
   
    const dispatch = useDispatch();
    const jobTabState = useSelector((state) => state.golbalReducer.jobTab);
    console.log("jobTabState :::::"+jobTabState)
    const [value, setValue] = React.useState(jobTabState);
    const [isLoading, setLoading] = useState(true);
    const [jobList, setJobList] = useState([]);
    const handleChange = (event, newValue) => {
        console.log("Setting newValue :"+newValue)
        dispatch(jobStatusDetails(newValue))
        setValue(newValue);
    };
    const [trigger, result,{ 
        isLoaded, isError, data, error 
       }] = useLazyGetJobAppliacationDetailsQuery();
       console.log("value ::"+value)

    useEffect(() => {
  const fetchAppliedJob = async () => {
        const returned = await trigger();
       console.log("fetchAppliedJob returned :::"+JSON.stringify(returned.data));
       setLoading(false);
       setJobList(returned.data);
       
      }
      fetchAppliedJob();
       },[jobList]);

    return (
        <div>
       {/*  <div className='card'>
<div className='flex-layout justify-content-center p-4'>
    <span className='font-14'>Application Details </span>
    </div>
</div>

       
               
                  !isLoading &&  jobList.map((job,index) => (<StatusCard key={index} job={job} setJobList={setJobList} jobList={jobList}/>))
               */
                  }


            {   
            <TabContext value={value}>
                <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
                    <Tabs value={value} onChange={handleChange} centered>
                        <Tab label="Application Details" value="Applied"/>
                        <Tab label="Interview Details" value="Interview"/> 

                    </Tabs>
                </Box>
                <TabPanel value="Applied">
                
                {
                  isLoading ?(<div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: '30vh',
                    }}
                >
                   <JSpinner/>
                </div>) : jobList.length !=0 ?
                ( jobList.map((job,index) => (<StatusCard key={index} job={job} setJobList={setJobList} jobList={jobList} type={jobTabState}/>))) :
                ("No data to show")
        
                }
                    
                </TabPanel>
                <TabPanel value="Interview">
                    
                {
                  isLoading ?(<div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: '30vh',
                    }}
                >
                   <JSpinner/>
                </div>) : jobList.length !=0 ?
                ( jobList.map((job,index) => (<StatusCard key={index} job={job} setJobList={setJobList} jobList={jobList} type={jobTabState}/>))) :
                ("No data to show")
               
               }

                </TabPanel>

    </TabContext> }
        </div>
    )
}
