import React,{useState,useEffect} from 'react'
import './JobCard.scss';
import comLogo from '../../assets/images/Hitachi-logo.jpg'
import * as Icon from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';
import ApplyJobPopUp from '../applyJobPopUp/ApplyJobPopUp';
import ReactGA from "react-ga4";
import AuthLayout from '../../auth/AuthLayout';
import LoginPopup from '../popupModel/LoginPopup';
import { useSelector } from 'react-redux';
import {useJobApplyMutation} from './../../rtk/jobs';
import notify from '../../utils/Utils';


function JobCard(props) {
    const [showLoginModal, setShowLoginModal] = useState(false);
    const isAuthenticated = useSelector((state) => state.authState.isAuthenticated);
    const [loginModal, setLoginModal] = useState(false);
    const [isApplied, setIsApplied] = useState(false);
    const { jobDetails,appliedJobs,setAppliedJobs } = props;
    const [loading, setLoading] = useState(false);
    console.log("jobDetails ::"+JSON.stringify(jobDetails));
    console.log("appliedJobs ::"+JSON.stringify(appliedJobs));
    const [
        updatePost, // This is the mutation trigger
        {error: updateProgramError , isLoading: isUpdating }, // This is the destructured mutation result
      ] =useJobApplyMutation();

      useEffect(() => {
       
        console.log("In iuse effect ::"+JSON.stringify(appliedJobs))
        var filteredObj = appliedJobs.find(function(item, i){
            if(item.job_id === jobDetails.jobId){
              return true;
            }
          });
          console.log("filteredObj ::"+JSON.stringify(filteredObj))
          if(filteredObj!=undefined){
            setIsApplied(true);
          }
      }, [appliedJobs])
      

function clickEventApply (){
    setShowLoginModal(true)
  
    ReactGA.event({
        category: 'Apply Job Model Opened',
        action: 'Action : Apply Job Model opned from job List for '+jobDetails.jobId,
        label:'Apply Job Model opned from job List for '+jobDetails.jobId
      });
}


const clickLoginApply = async(jobId)=>{
    if(!isAuthenticated){
        setLoginModal(true)
    }else {
    console.log("jobId ::"+jobId)
    let error = undefined;
    setLoading(true);
    const returned =await updatePost({jobId:jobId}).unwrap().catch(res => error = res);
    
    console.log("returned ::"+JSON.stringify(error))
    console.log("returned returned  ::"+JSON.stringify(returned))
    if(error !=undefined){
        notify("danger","Error Message",error.data.errorMessage);

    }else {
        console.log("Adding data ::"+JSON.stringify(returned))
        setAppliedJobs(returned)
    }
    }
   
  
    ReactGA.event({
        category: 'Apply Job Model Opened',
        action: 'Action : Apply Job Model opned from job List for '+jobDetails.jobId,
        label:'Apply Job Model opned from job List for '+jobDetails.jobId
      });
      setLoading(false);
}
    return (
        <div className="card card-job" >
            <div className="card-body">
                <div>
                    <div className='job-info f-left'>
                        <h5 className="card-title"><Link to={'/job/'+jobDetails.jobId}>{jobDetails.jobTitle} </Link></h5>
                        <p className='sub-header'>{jobDetails.company.length == 0 ?("Client not disclosed by recruiter ") : jobDetails.company} </p>
                        <div className='flex-layout'>
                            <div className=''>
                                <p className='sub-info'> <Icon.BriefcaseFill size={12} />
                                    <span className='pad-l-5'> 
                                        {
                                            jobDetails.expRequired.maxExp.length ==0 ?(
                                                <>
                                                 {jobDetails.expRequired.minExp} {" Years"}
                                                </>
                                            ):(
                                                <>
                                                 {jobDetails.expRequired.minExp}-{jobDetails.expRequired.maxExp} {" Years"}
                                                </>
                                            )
                                        }
                                       </span>
                                </p>
                            </div>
                            <div className='pad-l-10'>
                                <p className='sub-info'> <Icon.CurrencyDollar size={12} />
                                    <span className='pad-l-5'> 
                                        {
                                            jobDetails.compensation.maxComp == 0 ?(
                                                <>
                                                 {jobDetails.compensation.minComp}  {jobDetails.compensation.type}
                                                </>
                                            ):(
                                                <>
                                                 {jobDetails.compensation.minComp} - {jobDetails.compensation.maxComp} {jobDetails.compensation.type}
                                                </>
                                            )

                                        }
                                       </span>
                                </p>
                            </div>
                            {
                                jobDetails.location.city.length !=0 || jobDetails.location.state.length!=0 || jobDetails.location.country.length !=0 ? (
                               <>
                                <div className='pad-l-10'>
                                <p className='sub-info'> <Icon.GeoAltFill size={12} /> 
                                    <span className='pad-l-5'>{jobDetails.location.city}, {jobDetails.location.state},{jobDetails.location.country}</span>
                                </p>
                            </div>
                               </>
                                ) :(
                                    <div className='pad-l-10'>
                                    <p className='sub-info'> <Icon.GeoAltFill size={12} /> 
                                        <span className='pad-l-5'>Remote</span>
                                    </p>
                                </div>
                                )
                           }
                           
                        </div>
                        {
                            jobDetails.jobLink.length != 0 &&  <div >
                            <div className='flex-layout'>
                                <Icon.Link size={20} /> <p className='sub-info pad-l-5'>{jobDetails.jobLink}</p>
                            </div>
                        </div>
                        }
                       
                        <p className="card-text">
                            <span>Skills :</span>
                            {
                                jobDetails.skills.map((skills, index) => (
                                    <span className='skill-bg mar-l-5'> {skills.skillName}</span>
                                ))
                            }
                        </p>
                        <p className="card-text">
                            <span>Employment Type :</span>
                            {
                                jobDetails.jobtype.map((jobTyp, index) => (
                                    <span className='skill-bg mar-l-5'> {jobTyp.jobType}</span>
                                ))
                            }
                        </p>
                    </div>
                    <div className='comp-img f-right'>
                       {/*<img className='image-style' src={comLogo} /> */}

                    </div>
                </div>
            </div>
            <div className="card-footer text-muted ">
                <div className='flex-layout'>
                    <span className='pad-l-5'> {jobDetails.staus}</span>
                    <div className='flex-float-right'>
                        <span className='pad-l-5'>
                            
                            <ApplyJobPopUp
                                show={showLoginModal}
                                setShowLoginModal={setShowLoginModal}
                                onHide={() => setShowLoginModal(false)}
                                jobidProp={jobDetails.jobId}
                            />
                            {
                                isApplied ? (<button className='btn-style succes-color' disabled={true} > <Icon.Check2Circle/> Applied </button>):
                                (<>
                                {
                                    !isAuthenticated && <button className='btn-style secondry-color' onClick={() =>clickEventApply()}>Easy Apply</button>
                                }
                                
                                <button className='btn-style secondry-color' onClick={() =>clickLoginApply(jobDetails.jobId)} disabled={loading}>
                                {!loading && <span className='indicator-label'>Apply</span>}
              {loading && (
                <span className='indicator-progress' style={{ display: 'block' }}>
                  Please wait...
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
               </button>
                                </>)
                            }
                             
                            {/*<button className='btn-style secondry-color'>Save</button>*/}
                            
                            

                            
                            <LoginPopup
        show={loginModal}
        onHide={() => setLoginModal(false)}
      />
                        </span>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default JobCard