import PropTypes from 'prop-types';

// material-ui
import { useTheme, styled } from '@mui/material/styles';
import { Avatar, Box, List, ListItem, ListItemAvatar, ListItemText, Typography } from '@mui/material';

// project imports
import MainCard from './../ui-component/cards/MainCard';
import TotalIncomeCard from './../ui-component/cards/Skeleton/TotalIncomeCard';

// assets
import StorefrontTwoToneIcon from '@mui/icons-material/StorefrontTwoTone';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

// styles
const CardWrapper = styled(MainCard)(({ theme }) => ({
  overflow: 'hidden',
  position: 'relative',
  '&:after': {
    content: '""',
    position: 'absolute',
    width: 210,
    height: 210,
    background: `linear-gradient(210.04deg, ${theme.palette.warning.dark} -50.94%, rgba(144, 202, 249, 0) 83.49%)`,
    borderRadius: '50%',
    top: -30,
    right: -180
  },
  '&:before': {
    content: '""',
    position: 'absolute',
    width: 210,
    height: 210,
    background: `linear-gradient(140.9deg, ${theme.palette.warning.dark} -14.02%, rgba(144, 202, 249, 0) 70.50%)`,
    borderRadius: '50%',
    top: -160,
    right: -130
  }
}));

// ==============================|| DASHBOARD - TOTAL INCOME LIGHT CARD ||============================== //

const ProfileUpdateDetails = ({ isLoading }) => {
  const theme = useTheme();
  const isEmailVerified = useSelector((state) => state.authState.isEmailVerified);
  const email = useSelector((state) => state.authState.email);
  const emailVCode = useSelector((state) => state.authState.emailVCode);
  console.log("ProfileUpdateDetails isEmailVerified ::"+isEmailVerified +" email::"+email)
  

  return (
    <>
      {isLoading ? (
        <TotalIncomeCard />
      ) : (
        <CardWrapper border={false} content={false}>
          <Box sx={{ p: 2 }}>
            <List sx={{ py: 0 }}>
              <ListItem alignItems="center" disableGutters sx={{ py: 0 }}>
                <ListItemAvatar>
                  <Avatar
                    variant="rounded"
                    sx={{
                      ...theme.typography.commonAvatar,
                      ...theme.typography.largeAvatar,
                      backgroundColor: theme.palette.warning.light,
                      color: theme.palette.warning.dark
                    }}
                  >
                    <StorefrontTwoToneIcon fontSize="inherit" />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  sx={{
                    py: 0,
                    mt: 0.45,
                    mb: 0.45
                  }}
                  primary={<>
                  <Typography variant="div">
                    {
                      isEmailVerified == 0 && <div ><span className='error-message' > Verify your account, </span> <Link to={'/verify-account/'+emailVCode}>click here</Link></div> 
                    }
                   
                     </Typography>
                  <Typography variant="div"><div ><span className='error-message' >Keep you profile udpated</span> <a>click here</a></div>  </Typography>
                  
                  </>}
                 
                />
              </ListItem>
            </List>
          </Box>
        </CardWrapper>
      )}
    </>
  );
};

ProfileUpdateDetails.propTypes = {
  isLoading: PropTypes.bool
};

export default ProfileUpdateDetails;
