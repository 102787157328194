import React from 'react'
import Footer from './../../components/footer/Footer'
function PolicyTerms() {
  return (
    <>
    <div className='pad-t-70 box-115'>
    <div className='container-fluid'>
      <div className='row'>
      <div className='pad-t-15'>
    <h4>Understanding your responsibilities and rights Accuity user</h4>
    <div className='pad-t-15'>
    <h5>USE OF THIS SITE</h5>
    <p className="card-text">You agree not to
    <li>reproduce, copy, distribute, modify, publish, transmit, display, use, reuse, re-publicize, assign, sublicense, sell, or exploit for any public or commercial purpose, any portion of the Site, use of the Site, or access to the Site.</li>
    <li>attempt to change, add to, remove, deface, hack or otherwise interfere with this Site or any material or content displayed on this Site; and/or</li>
    <li>access or use this Site in any way that could or is intended to damage or impair the Site, or any server or network underlying the Site, or interfere with anyone else’s use and enjoyment of the Site.</li>

    </p>
        </div>

        <div className='pad-t-15'>
    <h5>THIRD PARTY ADVERTISEMENTS AND LINKS TO THIRD PARTY SITES</h5>
    <p className="card-text">The Sample Store may display advertisements from third parties, 
    such as banner advertisements and pop-up texts, 
    and links to third party sites, on the Site. 
    We are not responsible for the content of such advertisements or links, 
    any products, services or other materials relating to such advertisements,
     any linked site, or any link contained in a linked site. 
     The display of any such advertisement or link does not imply endorsement by
      The Sample Store of the advertisement or linked site or any content therein.
       IN NO EVENT WILL THE SAMPLE STORE BE LIABLE, DIRECTLY OR INDIRECTLY, 
       TO ANYONE FOR ANY DAMAGE OR LOSS ARISING FROM OR RELATING TO ANY USE,
        CONTINUED USE OR RELIANCE ON ANY THIRD PARTY ADVERTISEMENT DISPLAYED ON THE SITE, 
        ANY PRODUCTS, SERVICES OR OTHER MATERIALS RELATING TO ANY SUCH ADVERTISEMENT, 
    ANY LINKED THIRD PARTY SITE OR ANY LINK CONTAINED IN A LINKED SITE
    </p>
        </div>

        <div className='pad-t-15'>
    <h5>INTELLECTUAL PROPERTY RIGHTS</h5>
    <p className="card-text">All editorial content, information, photographs, illustrations, 
    artwork and other graphic materials, and names, 
    logos and trade marks on this Site are protected by copyright laws and/or other laws
     and/or international treaties, and belong to us and/or our suppliers, as the case may be.
      These works, logos, graphics, sounds or images may not be copied, reproduced, retransmitted,
       distributed, disseminated, sold, published, broadcasted or circulated whether
        in whole or in part,
     unless expressly permitted by us and/or our suppliers, as the case may be.
    </p>
    <p className="card-text">Nothing contained on the Site should be construed as 
    granting by implication, estoppel, or otherwise, any license or right to use any trademark
     displayed on the Site without our written permission. 
    Misuse of any trademarks or any other content displayed on the Site is prohibited.
    </p>
    <p className="card-text">We will not hesitate to take legal action against any unauthorized
     usage of its trade marks, name or symbols to preserve and protect its rights in the matter.
      All rights not expressly granted herein are reserved. Other product and 
    company names mentioned herein may also be the trade marks of their respective owners.
    </p>
        </div>

        <div className='pad-t-15'>
    <h5>CHANGES TO TERMS OF USE</h5>
    <p className="card-text">These Terms of Use may be modified from time to time. 
    Any modifications to these Terms of Use will be effective upon posting. 
    You should therefore read these Terms of Use before you place any order. 
    Your continued use of the Site after any modifications to the 
    Terms of Use indicates your acceptance of the modified Terms of Use
    </p>
    
        </div>
    </div>
    </div>
    </div>
    </div>
<Footer/>
    </>
  )
}

export default PolicyTerms