import React from 'react'
import Skeleton from '@mui/material/Skeleton';
function JobSkillsSkeleton(props) {
 
  return (
    <div><div className='card'>
    <div className='card-body'>
      <p className="card-text">

        
        <Skeleton variant="rectangular" height={40} />

      </p>

    </div>
  </div></div>
  )
}

export default JobSkillsSkeleton