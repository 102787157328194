import {React,useState,useEffect} from 'react'
import { Link, Outlet } from 'react-router-dom';
import Login from './component/Login';
import Register from './component/Register';
import ForgotPassword from './component/ForgotPassword';
import { storeUser} from './../redux-reducers/authStore';
import {  useDispatch } from 'react-redux';
export default function AuthLayout(props) {
  
    const {viewType,registerationType,onHide} =props;
    const [isOpenLogin, setIsOpenLogin] = useState(true);
    const [isOpenRegister, setIsRegister] = useState(false);
    const [isOpenForget, setIsOpenForget] = useState(false);
    const dispatch = useDispatch();

    function storeUserData(data){
      dispatch(storeUser(data));
    }

    useEffect(() => {
        if(viewType =='Login'){
            setIsOpenLogin(true);
            setIsRegister(false);
        }else if(viewType =='Register'){
            setIsRegister(true);
            setIsOpenLogin(false);
        }
      }, [viewType])
    
  function toggle() {
    setIsOpenLogin(wasOpened => !wasOpened);
  }
function enableForgotPassword(){
    setIsOpenLogin(false);
    setIsRegister(false);
    setIsOpenForget(true);
}
function enableRegister(){
    setIsOpenLogin(false);
    setIsRegister(true);
    setIsOpenForget(false);
}
function enableLogin(){
    console.log("In enableLogin")
    setIsOpenLogin(true);
    setIsRegister(false);
    setIsOpenForget(false);
}
  return (
    
    <div className="ftco-section">
      <div className="container">
			
			<div className="row justify-content-center">
				
					<div className="p-4 p-md-5">
                   
                    {isOpenLogin && (
		      	<Login storeUserData={storeUserData} setIsOpenLogin={setIsOpenLogin} enableRegister={enableRegister} onHide={onHide}/>
                    )}
                    {isOpenRegister && (
		      	<Register registerationType={registerationType} storeUserData={storeUserData} enableLogin={enableLogin} onHide={onHide}/>
                    )}
                    {isOpenForget && (
		      	<ForgotPassword isOpenForget={isOpenForget} setIsOpenForget={setIsOpenForget} enableLogin={enableLogin}/>
                    )}
            {
viewType =='Login' && !isOpenForget && (
    <Link onClick={enableForgotPassword} className='d-flex flex-row-reverse link ms-1'>Forgot Password</Link>
	       
)
           } 
            </div>
				</div>
			
		</div>
    </div>
  )
}
