import React from 'react'

import { useSelector, useDispatch } from 'react-redux'
import { decrement, increment,add2 } from '../../redux-reducers/index'
import {useGetPokemonByNameQuery} from '../../rtk/pokemon';
import { Link } from 'react-router-dom';
function CardCategory(props) {
    const {logoTech,name} =props;
  
  return (
    <div><div className="card" >
    
    <div className="card-body text-center">
    <div className='svg'>{logoTech}</div>
      <h5 className="card-title">{name} </h5>
      {/*<p className="card-text">130+ jobs</p>*/}
      <Link to="/jobs" className='btn-style primary-bg-color remove-underline'>View Jobs</Link>
      </div>
  </div></div>
  )
}

export default CardCategory