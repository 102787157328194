import React from 'react'
import Autocomplete from '../autoComplete/AutoComplete';
import MuiAutoComplete from './../../components/mui-autocomplete/index';
import './JobSearch.scss';
import { useGetSearchCriteriaQuery } from './../../rtk/Search'
function JobSearch() {
  const { data, error, isLoading } = useGetSearchCriteriaQuery();

  return (
    <div>
     
      <div className='wrapper'>
      <h4 className='pad-l-115'> Find your Dear job</h4>
     <div className="pad-t-10">
   
	
    {/*<Autocomplete  /> */}
    <div className='pad-l-115'>
      {
        data && <MuiAutoComplete  data={data} type={"homeSearch"}/>
      }
    
    </div>
		
	
    
</div>

<div className='pad-t-30 pad-l-115'>
      <h1> Hiring the best in the industry.</h1>
      <p>Learn,develop and grow with us</p>
      </div>

      </div>
    </div>
  )
}

export default JobSearch;