import React from 'react'
import AppRouter from '../router/AppRouter';
import route from '../router';
import { ReactNotifications } from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider } from '@mui/material';
import themes from './../themes';
import NavigationScroll from './navigation/NavigationScroll';
import { useSelector } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import Footer from './../components/footer/Footer'
export default function MasterLayout() {
  const customization = "";//useSelector((state) => state.customization);
  return (
    <div>
      <BrowserRouter>
       <StyledEngineProvider injectFirst>
      <ThemeProvider theme={themes(customization)}>
        <CssBaseline />
        <NavigationScroll>
      <ReactNotifications/>
      <AppRouter/>
      
      </NavigationScroll>
      </ThemeProvider>
      </StyledEngineProvider>
      
      </BrowserRouter>
     </div>
  )
}
