import React, { useState, useEffect } from 'react'
import { useFormik, Form, Field, Formik, useField, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import MuiFormikAutoComplete from './../ui-component/FormFields/MuiFormikAutoComplete';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import Modal from 'react-bootstrap/Modal';
import { duplicatProject } from './../../utils/Constant';
import * as Icon from 'react-bootstrap-icons';
import { Divider } from '@mui/material';
import { useLazyGetDataQuery } from './../../rtk/MetaData';
import { useSaveProjectDetailsMutation } from './../../rtk/profile'
import { useLazyGetProjectsDetailsQuery } from './../../rtk/profile'
import JSpinner from './../../components/jspinner/JSpinner';
import { monthNames } from './../../utils/Constant';
import notify from '../../utils/Utils';
import { useSelector } from 'react-redux';
import CardSkeleton from './skeleton/CardSkeleton';
export default function Projects(props) {
  const {setIsmodified} =props;
  const userId = useSelector((state) => state.authState.userId);
  const [showModal, setShowModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [projectDetailsList, setProjectDetailsList] = useState([]);
  const [companyData, setCompanyData] = useState();
  const [userCompanyData, setUserCompanyData] = useState();
  const [deleteList, setDeleteList] = useState([]);
  let [fetchTrigger, { data, error, isLoading, isFetching }] = useLazyGetDataQuery();
  const [projectLoading, setProjectLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [
    updateProjects, // This is the mutation trigger
    { error: updateProgramError, isLoading: isUpdating }, // This is the destructured mutation result
  ] = useSaveProjectDetailsMutation();
  const [fetchProjectsDetails, { data: projectData, error: projectError, isLoading: projectIsLoading }] = useLazyGetProjectsDetailsQuery(userId);
  async function fetchEducationDataList() {
    const response = await fetchProjectsDetails();
    setProjectDetailsList(response.data);
    setProjectLoading(false);
  }

  useEffect(() => {
    fetchEducationDataList();
    fetchCompanyData();
    fetchUserCompanyData();
  }, [])

  async function fetchCompanyData() {
    const response = await fetchTrigger("company");
    setCompanyData(response.data);
  }
  async function fetchUserCompanyData() {
    const response = await fetchTrigger("usercompany");
    setUserCompanyData(response.data);
  }


  const [initState, setInitState] = useState({
    projectId: '',
    projectName: '',
    tagedProject: null,
    clientName: null,
    projectStatus: '',
    projectType: '',
    projectFrom: '',
    projectTo: '',
    projectDesc: '',
  });

  function handelAddEducation() {
    setShowModal(true);
    setInitState({
      projectId: '',
      projectName: '',
      tagedProject: null,
      clientName: null,
      projectStatus: '',
      projectType: '',
      projectFrom: '',
      projectTo: '',
      projectDesc: '',
    })
    setIsEdit(false);
  }

  function hideModel() {
    setIsEdit(false);
    setShowModal(false);
  }

  function handleEditEducation(index) {
    const toEditEmployment = projectDetailsList.filter(a =>
      a.projectName === index
    );
    setInitState(toEditEmployment[0]);
    setIsEdit(true);
    setShowModal(true);
  }

  function handledeleteEductaion(index) {
    setProjectDetailsList(projectDetailsList.filter(a =>
      a.projectName !== index
    ));


    const eduList = projectDetailsList.filter(a =>
      a.projectName === index
    )[0];
    setDeleteList([...deleteList, eduList.projectId])


  }

  const saveEducationDetails = async () => {
    setLoading(true);
    var requestObject = { data: { projectDetails: projectDetailsList, deleteDetails: deleteList } }
   
    var returned = await updateProjects(requestObject).unwrap().catch(res => error = res);
    setLoading(false);
    if (returned.status == "success") {
    //  notify("success", "Success Message", "Details saved Successfully");
    } else {
      notify("danger", "Rrror Message", "Error in saving details");
    }
    setIsmodified(false);
  }

  return (
    <>
      <div className='pad-10'>
        <div className='card-header flex-layout justify-content-between'>
          <div className='align-items-start flex-column'>
            Projects Information
            <p className='profile-tagline'>
              Update your project informaiton
            </p>
          </div>
          
          <div className='align-items-end flex-column'>
          {
              projectDetailsList.length != 0 && 
              <>
             <button className='btn-style secondry-color' onClick={handelAddEducation}>Add Project Details</button>
             
              </>
          }
 <button className='btn-style secondry-color' type="submit" onClick={saveEducationDetails} >
            {!loading && <span className='indicator-label'>Save</span>}
              {loading && (
                <span className='indicator-progress' style={{ display: 'block' }}>
                  Please wait...
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
              </button>
           </div>
        </div>
      </div>
      <ProjectsComp show={showModal} onHide={hideModel} usercompanydata={userCompanyData} companydata={companyData} projectdetailslist={projectDetailsList} setprojectdetailslist={setProjectDetailsList} isedit={isEdit.toString()} initvalues={initState} setInitState={setInitState} setIsmodified={setIsmodified}/>

      {
        projectLoading ? (<>
        <CardSkeleton/>
        </>) : (
          projectDetailsList.length != 0 ?  (projectDetailsList.map((row, index) => (
            <>
              <div className='card no-border no-box-shadow' >
                <div className='card-header flex-layout justify-content-between'>
                  <b>
                    {
                      row.projectName + " in " + row.tagedProject.name
                    }
                  </b>
                  <div>
                    <button className='mar-l-5 no-border no-backgroud' onClick={() => handleEditEducation(row.projectName)} ><Icon.PencilSquare size={25} /> </button>
                    <button className='mar-l-5 no-border no-backgroud' onClick={() => handledeleteEductaion(row.projectName)}><Icon.ArchiveFill size={25} /></button>
                  </div>
                </div>
                <div className='card-body '>
                  <div>{row.clientName.name} </div>
                  <div>{row.projectType} | {row.projectStatus} | 
                    {monthNames[(row.projectFrom.split("-")[0])-1 ] + " " +row.projectFrom.split("-")[2]  } -
                    {row.projectTo == 'Till date' ? (" Present") : (monthNames[ (row.projectTo.split("-")[0])-1 ] + " " + row.projectTo.split("-")[2] )}
                  </div>

                  <div>{row.projectDesc}</div>
                </div>
              </div>
            </>

          ))) : (<> 
           <div
        style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '30vh',
        }}
    >
      <b>Nothing to display. <button className='btn-style secondry-color' onClick={handelAddEducation}>Add Project Details</button></b>
      </div> 
   </>))
      }
      <div>
      </div>
    </>

  )
}

export function ProjectsComp(props) {
  const [value, setValue] = React.useState(null);
  const [defaultDateValue, setDefaultDateValue] = React.useState();
  const { projectdetailslist, setprojectdetailslist, isedit, initvalues, onHide, setInitState, companydata, usercompanydata,setIsmodified } = props;
  const [errorMessage, setErrorMessage] = React.useState();
  const [isCurrentEmployment, setIsCurrentEmployment] = React.useState();

  const companyOption = companydata;

  const userFCompanyDataOption = usercompanydata;

  const dateValidation = (to, from,ctx) => {
    console.log("to ::"+to + " from:"+from )
    let errors = "projectTo";
    let isError = false;
    let msg=null;
    if(from == undefined){
      msg='Please select from Date' ;
    // isError=true;
    let ve = ctx.createError({ message: 'Please select from Date' });
      ve.errors = errors; // these are not shown
      console.log("ve.errors ::"+JSON.stringify(ve))
      return ve;
    }
    let fromDate,toDate,ve;
    if(!isError){
      fromDate= new Date(from);
      toDate= new Date(to);
    }
    if(fromDate > new Date()){
      msg='To date should be less then Today Date' ;
      // isError=true;
      let ve = ctx.createError({ message: 'To date should be less then from date' });
      ve.errors = errors; // these are not shown
      console.log("ve.errors ::"+JSON.stringify(ve))
      return ve;
    }
    return true;
  }

  useEffect(() => {
    setIsCurrentEmployment(initvalues.projectStatus);
  }, [onHide, isedit])

  const eductaionValidation = Yup.object().shape({

    projectName: Yup.string().required('Please Enter project name'),
    tagedProject: Yup.mixed().required('Please Tag this project'),
    clientName: Yup.mixed().required('Please select client name'),
    projectStatus: Yup.string().required('Please select project status'),
    projectType: Yup.string().required('Please select project type'),
    projectFrom: Yup.string().required('Please enter project start date'),
    projectTo: Yup.string().required('Please enter project end date').
    test("date test",(projectFrom,projectTo) =>{
      console.log("projectFrom::"+projectFrom +" projectTo::"+projectTo.parent.projectFrom)
      return dateValidation(projectFrom,projectTo.parent.projectFrom,projectTo);  
   }),
    projectDesc: Yup.string().required('Please enter project description'),

  });




  const handleCurrentEmployement = (event, setFieldValue) => {
    setIsCurrentEmployment(event.target.value);
    if (event.target.value === 'inprogress') {
      //initvalues.projectTo = "Till date"
      setFieldValue('projectTo', 'Till date')
    } else {
    }
  }
  return (
    <div> <Modal
      {...props}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
      </Modal.Header>
      <Modal.Body>
        <Formik
          enableReinitialize={true}
          initialValues={initvalues}
          validationSchema={eductaionValidation}
          onSubmit={(values, { setSubmitting, setErrors }) => {
            if (!isedit) {
              var index = -1;
              var filteredObj = projectdetailslist.find(function (item, i) {
                if (item.projectName === values.projectName) {
                  index = i;
                  return i;
                }
              });
              let tempArray = [...projectdetailslist];
              tempArray[index] = values;
              setprojectdetailslist(tempArray);
              onHide();
            } else {
              const isExisting = duplicatProject(projectdetailslist, values.projectName);
              console.log("Projetc ion isExisting "+isExisting)
              if (isExisting) {
                console.log("Projetc ion isExisting")
                setprojectdetailslist([...projectdetailslist, values])
                onHide();
              } else {
                setErrorMessage("Explicit error")
              }
            }
            setIsmodified(true);
          }}
        >
          {({ errors, touched, resetForm, isValidating, handleChange, setFieldValue }) => (
            <Form>
              <div className='card-header flex-layout justify-content-center'>
                <div className='align-items-start flex-column'>
                  Add Project Information
                  <p className='profile-tagline'>
                    Update your project informaiton
                  </p>
                </div>
                <div className='align-items-end flex-column'>


                </div>
              </div>
              <Divider />

              <div className='card-body'>
                <div className="form-group row pad-5  ">
                  <label className="col-xl-3 col-lg-3 col-form-label font-14">Project Title </label>
                  <div className="col-lg-9 col-xl-6">
                    <Field
                      name="projectName"
                      type="text"
                      disabled={isedit == "true"}
                      className={
                        'form-control' +
                        (errors.projectName && touched.projectName ? ' is-invalid' : '')
                      }
                    />
                    <ErrorMessage
                      name="projectName"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>
                </div>
                <div className="form-group row pad-5">
                  <label className="col-xl-3 col-lg-3 col-form-label font-12">Tag this Project</label>
                  <div className="col-lg-9 col-xl-6">
                    <MuiFormikAutoComplete keyName="tagedProject" options={userFCompanyDataOption} selectedValue={initvalues.tagedProject} error={errors.tagedProject} />
                    {touched.tagedProject && errors.tagedProject && (
                      <div className='error-message p-2'> {errors.tagedProject}</div>
                    )}

                  </div>
                </div>
                <div className="form-group row pad-5">
                  <label className="col-xl-3 col-lg-3 col-form-label font-12">Client</label>
                  <div className="col-lg-9 col-xl-6">
                    <MuiFormikAutoComplete keyName="clientName" options={companyOption} selectedValue={initvalues.clientName} error={errors.clientName} />
                    {touched.clientName && errors.clientName && (
                      <div className='error-message p-2'> {errors.clientName}</div>
                    )}
                  </div>
                </div>
                <div className="form-group row pad-5">
                  <label className="col-xl-3 col-lg-3 col-form-label font-14">Project status</label>
                  <div className="col-lg-9 col-xl-6">
                    <div className="form-check form-check-inline">

                      <Field className="form-check-input font-14"
                        name="projectStatus"
                        type="radio"
                        value="inprogress"
                        checked={isCurrentEmployment == 'inprogress'}
                        onChange={(e) => {
                          handleCurrentEmployement(e, setFieldValue)
                          setFieldValue("projectStatus", "inprogress")
                        }}
                      /><label className="form-check-label" htmlFor="inlineRadio1">In progress</label>
                    </div>
                    <div className="form-check form-check-inline">

                      <Field className="form-check-input font-14"
                        name="projectStatus"
                        type="radio"
                        value="finished"
                        checked={isCurrentEmployment == 'finished'}
                        onChange={(e) => {
                          handleCurrentEmployement(e, setFieldValue)
                          setFieldValue("projectStatus", "finished")
                        }}
                      />
                      <label className="form-check-label" htmlFor="inlineRadio2">Finished</label>
                    </div>
                    {touched.projectStatus && errors.projectStatus && (
                      <div className='error-message p-2'> {errors.projectStatus}</div>
                    )}
                  </div>

                </div>

                <div className="form-group row pad-5">
                  <label className="col-xl-3 col-lg-3 col-form-label font-14">Project Type</label>
                  <div className="col-lg-9 col-xl-6">
                    <div className="form-check form-check-inline">

                      <Field className="form-check-input font-14"
                        name="projectType"
                        type="radio"
                        value="fullTime"
                      /><label className="form-check-label" htmlFor="inlineRadio1">Full Time</label>
                    </div>
                    <div className="form-check form-check-inline">

                      <Field className="form-check-input font-14"
                        name="projectType"
                        type="radio"
                        value="partTime"
                      />
                      <label className="form-check-label" htmlFor="inlineRadio2">Part Time</label>
                    </div>
                    <div className="form-check form-check-inline">

                      <Field className="form-check-input font-14"
                        name="projectType"
                        type="radio"
                        value="freelancer"
                      />
                      <label className="form-check-label" htmlFor="inlineRadio2">Freelancer</label>
                    </div>
                    <div className="form-check form-check-inline">

                      <Field className="form-check-input font-14"
                        name="projectType"
                        type="radio"
                        value="internship"
                      />
                      <label className="form-check-label" htmlFor="inlineRadio2">Internship</label>
                    </div>
                    {touched.projectType && errors.projectType && (
                      <div className='error-message p-2'> {errors.projectType}</div>
                    )}
                  </div>
                </div>


                <div className="form-group row pad-5">
                  <label className="col-xl-3 col-lg-3 col-form-label font-12">Project duration</label>
                  <div className="col-lg-9 col-xl-6">
                    <div className="row">
                      <div className="col">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker className='custom-input' error={touched.projectFrom} views={['month', 'year']} defaultValue={dayjs(new Date(initvalues.projectFrom))} onChange={(value) => setFieldValue("projectFrom", new Date(value).getMonth() + 1 + "-1-" + new Date(value).getFullYear())} />
                        </LocalizationProvider>
                      </div>
                      <div className="col">
                        {
                          isCurrentEmployment != 'inprogress' && <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker className='custom-input' error={touched.projectTo} views={['month', 'year']} defaultValue={dayjs(new Date(initvalues.projectTo))} onChange={(value) => setFieldValue("projectTo", new Date(value).getMonth() + 1 + "-1-" + new Date(value).getFullYear())} />
                          </LocalizationProvider>
                        }
                      </div>
                    </div>
                    {//touched.projectFrom && errors.projectFrom && touched.projectTo && errors.projectTo && (
                      <div className='error-message p-2'> {errors.projectTo}</div>
                    //)
                    }
                  </div>
                </div>
                <div className="form-group row pad-5  ">
                  <label className="col-xl-3 col-lg-3 col-form-label font-14">Project description </label>
                  <div className="col-lg-9 col-xl-6">
                    < Field as="textarea"
                      className={
                        'form-control form-control-lg form-control-solid font-14' +
                        (errors.projectDesc && touched.projectDesc ? ' is-invalid' : '')
                      }
                      id="exampleFormControlTextarea1" rows="2"
                      type="text"
                      name="projectDesc"
                    ></Field>
                    {touched.projectDesc && errors.projectDesc && (
                      <div className='error-message p-2'> {errors.projectDesc}</div>
                    )}
                  </div>
                </div>

              </div>
              {
                !isedit ? (<> <div className='flex-layout justify-content-center'>
                  <button className='btn-style secondry-color' type="submit" >Edit Project details</button>
                </div></>) : (<div className='flex-layout justify-content-center'>
                  <button className='btn-style secondry-color' type="submit" >Add Project details</button>
                </div>)
              }

            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal></div>
  )
}



