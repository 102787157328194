import React from 'react'
import { useState } from 'react';
import comLogo from '../../assets/images/pexels-buro-millennial-1438081.jpg';
import SearchBanner from '../../components/searchBanner/SearchBanner';
import CardCategory from '../../components/categoryCard/CardCategory';
import * as Icon from 'react-bootstrap-icons';
import { NavLink } from 'react-router-dom';
import AccountSetting from './AccountSetting';
import Communication from './Communication';
import ChangePassword from './ChangePassword';
import ProgressCardComp from '../../components/progressCard/ProgressCardComp';
import JobPreference from './JobPreference';
function Account() {
  const [personal, setPersonal] = useState(true);
  const [account, setAccount] = useState(false);
  const [preference, setPreference] = useState(false);
  const [currentTab, setCurrentTab] = useState('communication');
  const [changePassword, setChangePassword] = useState(false);

  function showComp(value) {
    console.log("value ::"+value)
    const removeClassDoc = document.getElementById(currentTab);
    removeClassDoc.classList.remove('active');
    const aTag = document.getElementById(value);
    aTag.classList.add('active');

    if (value == 'communication') {
      setPersonal(true);
      setChangePassword(false);
      setAccount(false);
      setPreference(false);
    } else if (value == 'account') {
      setPersonal(false);
      setChangePassword(false);
      setAccount(true);
      setPreference(false);
    }else if (value == 'preference') {
      setPersonal(false);
      setChangePassword(false);
      setAccount(false);
      setPreference(true);
    }  
    else if (value == 'change-password') {
      setPersonal(false);
      setChangePassword(true);
      setAccount(false);
      setPreference(false);
    }
    setCurrentTab(value);
  }

  return (
    <div >

      <div className='container-fluid'>

        <div className='row'>
          <div className="col-3">
            <div className='card'>
              <div className='card-body'>

               
                <div className='pad-t-5'>

                 

                  <div className='profile-menu pad-10 pad-t-10'>
                    <div id="communication" className='menu-item pad-10 active' onClick={() => showComp('communication')}>
                      <a><Icon.PersonFill size={17} /> Communication </a>
                    </div>
                  {
                    /*
                    <div id="account" className='menu-item pad-10' onClick={() => showComp('account')}>
                      <a><Icon.DiscFill size={17} /> Account</a>
                    </div>
                    <div id="preference" className='menu-item pad-10' onClick={() => showComp('preference')}>
                      <a><Icon.DiscFill size={17} /> Job Preference</a>
                    </div>
                    */
                  }
                    <div id="change-password" className='menu-item pad-10' onClick={() => showComp('change-password')}>
                      <a><Icon.ShieldFillCheck size={17} />   Change password</a>
                    </div>
                  </div>


                </div>


              </div>

            </div>
          </div>
          <div className="col-9 ">
            <div className='card'>
            {personal ? <Communication /> : null}
              {changePassword ? <ChangePassword /> : null}
            {/*preference ? <JobPreference/>:null*/}

              {/*account ? <AccountSetting /> : null*/}
            
            
             
            </div>
          </div>

          
        </div>
      </div>

    </div>
  )
}

export default Account