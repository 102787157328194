import { useEffect, useState } from 'react';

// material-ui
import { Grid } from '@mui/material';

// project imports
import EarningCard from './../dashboard/EarningCard';
import PopularCard from './../dashboard/PopularCard';
import TotalOrderLineChartCard from './../dashboard/TotalOrderLineChartCard';
import TotalIncomeDarkCard from './../dashboard/TotalIncomeDarkCard';
import InterviewCard from './../dashboard/InterviewCard';
import ApplicationTrackCard from './../dashboard/ApplicationTrackCard';
import ProfileUpdateDetails from './../dashboard/ProfileUpdateDetails';

import TotalIncomeLightCard from './../dashboard/TotalIncomeLightCard';
import TotalGrowthBarChart from './../dashboard/TotalGrowthBarChart';
import { gridSpacing } from './../../redux-reducers/constant';
import MultiCarousel from '../dashboard/MultiCarousel';
import JobPreference from '../../components/job-preference/JobPreference';
import ServiceList from '../../components/serviceList/ServiceList';
import {useLazyGetdashboardDetailsQuery,useLazyGetChatDetailsQuery} from './../../rtk/dashboard';
import { Link } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import {  useDispatch } from 'react-redux';
import { updateSkills} from './../../redux-reducers/authStore';
import { jobStatusDetails } from '../../redux-reducers/global';

export default function CandidateDashbord() {
 
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [trigger, result,{ 
    isLoaded, isError, data, error 
   }] = useLazyGetdashboardDetailsQuery();
   const [chatTrigger] = useLazyGetChatDetailsQuery();

    const [isLoading, setLoading] = useState(true);
    const [isChatLoading, setChatLoading] = useState(true);
    const [jobList, setJobList] = useState();
    const [interviewData, setInterviewData] = useState();
    const [jobAppliedData, setJobAppliedData] = useState();
    const [jobTrends, setJobTrends] = useState({ categories : [] , skillsDetail :[] ,totalCount:0 });
    const [skillList, setSkillList] = useState();
    useEffect(() => {
      let skills ;
      const fetchAppliedJob = async () => {
        const returned = await trigger();
       setLoading(false);
       returned.data.countDetials.map(item =>{
        //if(item.status =='Applied'){
          setJobAppliedData (item.Applied);
        //}else if(item.status =='Interview'){
          setInterviewData(item.Interview);
        //}
      })
       setJobList(returned.data.jobList);
       setJobTrends(returned.data.jobTrends);
       setSkillList(returned.data.skills);
       skills=returned.data.skills;
       dispatch(updateSkills(returned.data.skills));
       fetchChatDetails();
      }

      const fetchChatDetails = async () => {
        if(skills.length ==0){
          skills=['java','react','mysql','oracle','AWS']
        }
        const returned = await chatTrigger(skills);
     
       
       var currentMonth = GetMonthName((new Date).getMonth());
        let index =0;
        let index1 =3;
        let totalCount =0;
        var now =(new Date).getMonth();
        const skillsSeries = []
        skills.map((item) => {
           skillsSeries.push({'name': item, 'data': []});
         })

        const chatdata ={ categories : [] , skillsDetail :skillsSeries ,totalCount:0 };
        const months =[];
       while (index < 4) {
        
        let mont= GetMonthName(now-index1);
        console.log("GetMonthName(now-index) ::"+mont + " - "+index1 + " index "+index)
        months.push(mont);

        skillsSeries.map((item) => {
          let value =0;
          returned.data.map((item1)=>{
           
            console.log("item ::"+JSON.stringify(item)+ " item1 =="+JSON.stringify(item1))

            if(item.name==item1.skill_name && item1.dateval == mont ){
              console.log("In item data")
              value= item1.count;
              totalCount =totalCount+item1.count;
             // item.data[index] = item1.count;
              //console.log("innifff  item ::"+JSON.stringify(item )+ " item1 :"+JSON.stringify(item1))
             
            }

          })
          item.data[index] = value;
        })
        index1 --;
     index ++;
      }
      chatdata.categories = months;
     chatdata.totalCount=totalCount;
      setJobTrends(chatdata);

      setChatLoading(false);
       }
      fetchAppliedJob();
      
    }, []);
  
    function GetMonthName(monthNumber) {
      var months = ['December', 'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
      return months[++monthNumber];
    }


    const handleClick =(type)=> {
      console.log("handleClick handleClick handleClick " +type)
      dispatch(jobStatusDetails(type))
      navigate("/auth/app/application-details");
    }
    return (
      <Grid container spacing={gridSpacing}>
         {/* first row */}
        <Grid item xs={12}>
          <Grid container spacing={gridSpacing} >
          <Grid item lg={4} md={6} sm={6} xs={12}>
            <div onClick={()=>handleClick("Interview")} className='pointer'>
          <InterviewCard isLoading={isLoading} data={interviewData} />
          </div>
            </Grid>
            <Grid item lg={4} md={6} sm={6} xs={12}>
            <div onClick={()=> handleClick("Applied")} className='pointer'>
            <ApplicationTrackCard isLoading={isLoading} data={jobAppliedData}/>
            </div>
            </Grid>
            <Grid item lg={4} md={6} sm={6} xs={12}>
              <Link to ='/auth/app/user-profile' className='remove-underline'>
            <ProfileUpdateDetails isLoading={isLoading} />
            </Link>
            </Grid>
           
          </Grid>
        </Grid>

        <Grid item xs={12}>
         <div> <MultiCarousel isLoading={isLoading} jobList={jobList}/></div>
        
        </Grid>


        {/* second row *}
         <ServiceList/>
        <Grid item xs={12}>
          <Grid container spacing={gridSpacing}>
          
            <Grid item lg={4} md={6} sm={6} xs={12}>
              <TotalOrderLineChartCard isLoading={isLoading} />
            </Grid>
            <Grid item lg={4} md={12} sm={12} xs={12}>
              <Grid container spacing={gridSpacing}>
                <Grid item sm={6} xs={12} md={6} lg={12}>
                  <TotalIncomeDarkCard isLoading={isLoading} />
                </Grid>
                <Grid item sm={6} xs={12} md={6} lg={12}>
                  <TotalIncomeLightCard isLoading={isLoading} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        */
    }
        <Grid item xs={12}>
          <Grid container spacing={gridSpacing}>
            <Grid item xs={12} md={8}>
              {
                !isChatLoading &&<TotalGrowthBarChart isLoading={isChatLoading} jobTrends={jobTrends}/>
              }
              
            </Grid>
            <Grid item xs={12} md={4}>
              <ServiceList isLoading={isLoading}/>
             
             {/*  <PopularCard isLoading={isLoading} /> */}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
       
         <div className='job-pref'>  <JobPreference/></div>
      
        </Grid>
      </Grid>
    );
}
