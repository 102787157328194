import React,{useState} from 'react'
import * as Icon from 'react-bootstrap-icons';
import { Link } from 'react-router-dom'
import JobStatusStepper from './JobStatusStepper';
import { useWithdrawApplicationMutation } from '../../rtk/jobs';

export default function StatusCard(props) {

    const { job ,setJobList,jobList,type} = props;
    const [loading, setLoading] = useState(false);
    const [
        withdrawApplication, // This is the mutation trigger
        {error: updateProgramError , isLoading: isUpdating }, // This is the destructured mutation result
      ] =useWithdrawApplicationMutation()


    const handleWithdraw= async(jobId) =>{
        console.log("in handleWithdraw ::"+jobId)
        setLoading(true);
        let errorres="";
        let data ={"jobId":jobId};
        const returned =await withdrawApplication(data).unwrap().catch(res => errorres = res);
     // console.log("handleWithdraw returned ::"+JSON.stringify(returned.data))
      setLoading(false);
      
      setJobList(jobList.filter(a =>
        a.jobId != jobId
      ));
      console.log("handleWithdraw jobList::"+JSON.stringify(jobList))
    }
   
    return (
        <div>
            {
                job.processStatus === type  && <div className="card card-job" >
                <div className="card-body">
                    <div className='row'>
                        <div className='col-md-6  col-sm-12'>
                            <div className='job-info f-left'>
                                <h5 className="card-title"><Link to={'/job/'+job.jobId}>{job.jobTitle} </Link></h5>
                                <p className='sub-header'>{job.company} </p>
                                <div className='flex-layout'>
                                    <div className=''>
                                        <p className='sub-info'> <Icon.BriefcaseFill size={12} />
                                            <span className='pad-l-5'> {job.expRequired.minExp}-{job.expRequired.maxExp} {job.expRequired.period}</span>
                                        </p>
                                    </div>
                                    <div className='pad-l-10'>
                                        <p className='sub-info'> <Icon.CurrencyDollar size={12} />
                                            <span className='pad-l-5'> {job.compensation.minComp} - {job.compensation.maxComp} {job.compensation.type}</span>
                                        </p>
                                    </div>
                                    <div className='pad-l-10'>
                                        <p className='sub-info'> <Icon.GeoAltFill size={12} />
                                            <span className='pad-l-5'>{job.location.city}, {job.location.state},{job.location.country}</span>
                                        </p>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className='col-md-6  col-sm-12'>
                            <JobStatusStepper status={job.processStatus}/>
                        </div>
                    </div>

                </div>

                <div className="card-footer text-muted ">
                   {
                    /*
 <div className='flex-layout'>
                        <span className='pad-l-5'> {"jobDetails.staus"}</span>

                    </div>
                    */
                   }
                   <div className='flex-layout justify-content-end'>
                   <button className='btn-style secondry-color' type="submit" onClick={()=>handleWithdraw(job.jobId)}
                  disabled= {loading}
                   >
                   {!loading && <span className='indicator-label'>Withdraw Application</span>}
              {loading && (
                <span className='indicator-progress' style={{ display: 'block' }}>
                  Please wait...
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
  
   </button>
    </div>
                </div>
            </div>
            }
            
        </div>
    )
}
