import { Button } from 'bootstrap';
import React, { Component,useEffect,useRef } from 'react';
import { useState } from 'react';
import Stepper from 'react-stepper-horizontal';
import Step from './ReviewApplication';
import CreateEmployer from './CreateEmployer';
import CreateJobPost from './CreateJobPost';
import JobDetails from './JobDetails';
import CompensationDetails from './CompensationDetails';
import JobDescribtion from './JobDescribtion';
import ReviewApplication from './ReviewApplication';
import { useAddNewJobMutation } from '../../rtk/jobs';
import { useSelector, useDispatch } from 'react-redux';
import { stepperDetails } from '../../redux-reducers/global';
import notify from '../../utils/Utils';
import { useNavigate } from "react-router-dom";

const JobPost = () => {
  const [currentPage, setCurrentPage] = useState(0);
  const [isNextBtnShow, setIsNextBtnShow] = useState(true);
  const [isPrevBtnShow, setIsPrevBtnShow] = useState(false);
  const [isSubmitBtnShow, setIsSubmitBtnShow] = useState(false);
  const stepperDetailsState = useSelector((state) => state.golbalReducer.stepperDetails);
  
  const [isNextBtnEnable, setIsNextBtnEnable] = useState(true);
  const [loading, setLoading] = useState(false);
  const [isPrevBtnEnable, setIsPrevBtnEnable] = useState(false);
  const [isSubmitBtnEnable, setIsSubmitBtnEnable] = useState(false);
  const [jobTypeValue, setJobTypeValue] = useState([]);
  const userId = useSelector((state) => state.authState.userId);
  const submitRef = useRef();
  const jobDetailsRef = useRef();
  const compDetailsRef = useRef();
  const dispatch = useDispatch();
  let navigate = useNavigate();
  useEffect(() => {
    let toStep = stepperDetailsState.toStep;
    let presentStep = stepperDetailsState.presentStep;
    setCurrentPage(toStep);
    if (toStep > 0) {
      setIsPrevBtnShow(true);
    }
    if (noOfSteps == toStep) {
      setIsSubmitBtnShow(true);
      setIsNextBtnShow(false);
    }
    if (noOfSteps != toStep) {
      setIsSubmitBtnShow(false);
      setIsNextBtnShow(true);
    }

}, [stepperDetailsState]);

  const initialProfile = {
    hiringCompany: "",
    clientJobLink: "",
    jobCity:"",
    jobState:"",
    jobCountry:"",
    jobTitle: "",
    workType: "",
    jobType: new Array(),
    noOfOpening: "",
    jobReqTimePeriod: "",
    minExp:"",
    maxExp:"",
    period:""
  };
  const jobDetails = {
    jobTag: "",
    jobDesc: "",
    JobDescText:""
  };
  const compDetails = {
    minSal: "",
    maxSal: "",
    compType: "",
    benifits: new Array()
  };

  const finalObj = {
    userId:userId,
    jobDetails :initialProfile,
    JobAdvDetails:jobDetails,
    compDetails:compDetails
  }

  const [profileFields, setProfileFields] = useState(initialProfile);
  const [jobDetailsState, setJobDetailsState] = useState(jobDetails);
  const [compDetailsState, setCompDetailsState] = useState(compDetails);
   
  const sections = [
    //{ title: 'Create Employer account', onClick: () => setCurrentPage(1) },
    { title: 'Create  job post', onClick: () => setCurrentPage(0) },
    { title: 'Job Details', onClick: () => setCurrentPage(1) },
    { title: 'Compensation Details', onClick: () => setCurrentPage(2) },
    { title: 'Review Application', onClick: () => setCurrentPage(3) }
  ];

  
  const [
    updatePost, // This is the mutation trigger
    {error: updateProgramError , isLoading: isUpdating }, // This is the destructured mutation result
  ] =useAddNewJobMutation()

  const noOfSteps = sections.length -1 ;
  const handleSubmit1 = async (e) => {
    console.log("In save changes ")
    finalObj.jobDetails =profileFields;
    finalObj.JobAdvDetails=jobDetailsState;
    finalObj.compDetails=compDetailsState;
    console.log("In save changes finalObj ::"+JSON.stringify(finalObj))
    try{
      setLoading(true);
     
      const returned =await updatePost(finalObj).unwrap();
     setLoading(false);
      if(returned ==true){
      //  if(true){
        notify("success","Success Message","Job saved Successfully");
        setProfileFields(initialProfile)
        setJobDetailsState(jobDetails)
        setCompDetailsState(compDetails)
        dispatch(stepperDetails({"presentStep":0,"toStep":0,"step0Data":true}));
      }
     // navigate("/jobs");
    } catch (error) {
      notify("danger","error messae","Error in saving Job");
    }
   e.preventDefault();
  };
  
 
  const next = () => {
    let toStep = stepperDetailsState.toStep;
    let shouldUpdateGlobalState =false;
    let ss ;
  let globalJson = "";
   if (toStep==0){
    ss = submitRef.current;//.click();
    globalJson = {"presentStep":0,"toStep":1,"step0Data":true}
    
    shouldUpdateGlobalState =true;
    //setCurrentPage(toStep);
   }else if(toStep == 1){
    ss= jobDetailsRef.current;
    globalJson = {"presentStep":1,"toStep":2,"step0Data":true}
    //setCurrentPage(toStep);
    shouldUpdateGlobalState = true;
   }else if(toStep == 2){
    ss= compDetailsRef.current;
    globalJson = {"presentStep":2,"toStep":3,"step0Data":true}
    //setCurrentPage(toStep);
    shouldUpdateGlobalState = true;
   }
 
   var clicked =ss.click();
   var isValid =ss.form.hiddenErrors.value ;
   console.log("JOPB post values ::"+ss.form +" globalJson ::"+JSON.stringify(globalJson) +" clicked ::"+clicked)
   console.log("JOb Post ::"+isValid)
   var ishiddentouched =ss.form.hiddentouched.value ;
   
   if(Object.keys(JSON.parse(isValid)).length === 0 && Object.keys(JSON.parse(ishiddentouched)).length !== 0){
   
    dispatch(stepperDetails(globalJson));
   }
   console.log("JOb Possdsadt ::")
  }
  const prev = () => {

    let toStep = stepperDetailsState.toStep;
    let presentStep = stepperDetailsState.presentStep;

    dispatch(stepperDetails({"presentStep":presentStep-1,"toStep":toStep-1}))
 }

  

 
  
  return (
    <div >
        <Stepper
          steps={sections}
          activeStep={currentPage}
          activeColor="rgba(0, 39, 69, 0.1411764706)"
          defaultBarColor="red"
          completeColor="#002745"
          completeBarColor="green"
          activeTitleColor="#002745"
          circleFontColor="#FFF"
          titleFontSize={13}
          completeOpacity="1"
        />
        <div className='pad-l-30 pad-r-30'>
          <div className='flex-column justify-content-center'>

           {
            /*
          {currentPage === 1 && (
              <>
                <CreateEmployer />
              </>
            )}

            */
           }
             {currentPage === 0 && (
              <>
                <CreateJobPost profileFields={profileFields} setProfileFields={setProfileFields}
                 
                 submitRef={submitRef} 
                />
              </>
            )}
            {currentPage === 1 && (
              <>
                <JobDetails jobDetailsState={jobDetailsState} setJobDetailsState={setJobDetailsState} jobDetailsRef={jobDetailsRef}/>
              </>
            )}
            {currentPage === 2 && (
              <>
                <CompensationDetails  compDetailsState={compDetailsState} setCompDetailsState={setCompDetailsState} compDetailsRef={compDetailsRef}/>
              </>
            )}
            {currentPage === 3 && (
              <>
                <ReviewApplication profileFields={profileFields} jobDetailsState={jobDetailsState} compDetailsState={compDetailsState}/>
              </>
            )}
          </div>
          <div className='flex-layout justify-content-between pad-10'>
            {isNextBtnShow && (
              <button className='btn-style secondry-color flex-right-position' type="submit" onClick={next} >Next</button>
            )
            }
            {isPrevBtnShow && (
              <button className='btn-style secondry-color f-left' onClick={prev}>Back</button>
            )
            }
            {isSubmitBtnShow && (
              <button className='btn-style secondry-color flex-right-position' onClick={handleSubmit1}>
                {!loading && <span className='indicator-label'>Save Post</span>}
              {loading && (
                <span className='indicator-progress' style={{ display: 'block' }}>
                  Please wait...
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
              </button>
           
              )
            }
            
          </div>
        </div>
     
    </ div>
  );
}

export default JobPost