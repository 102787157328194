import React from 'react'
import Footer from './../../components/footer/Footer'
function ReportIssue() {
  return (
    <>
    <div className='pad-t-70 box-115'>
    <div className='container-fluid'>
      <div className='row'>
    
    <div className='pad-t-15'>
    <h4>Raise a concern</h4>
    <p className="card-text">It is our constant endeavour to provide our customers with high standards of service.
         However, in the unlikely event of our services not meeting your expectations,
         we would like to hear from you</p>
    <p className="card-text">If any user have any complaint with relation to accessing your 
    account or if you have any queries related to how AccuitySavvy can help you in finding your next job,
     please email your complaint or query to <span className='bg-grey'>info@connectnex.com</span>
    All emails received will normally be responded  within 1 - 2 working days.</p>
    </div>
    </div>
    </div>
    </div>
   
    </>
  )
}

export default ReportIssue