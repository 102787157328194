import React from 'react'
import {Route, Routes, Navigate} from 'react-router-dom';
import JobPost from '../layout/post-job/JobPost';
import UserProfile from '../layout/userProfileUpdater/UserProfile';
import Account from '../layout/accountSetting/Account';
import DashboardRouter from './../layout/DashboardLayout/DashboardRouter';
import DashboardLayout from './../layout/DashboardLayout';
import Dashboard from './../layout/dashboard';
import CandidateDashbord from '../layout/DashboardLayout/CandidateDashbord';
import {  useSelector } from 'react-redux';
import ApplicationDetails from './../components/ApplicationDetails/ApplicationDetails';
import Page404 from '../layout/404Layout/Page404';

function PrivateRouter() {

  const userType = useSelector((state) => state.authState.userType);
  console.log("PrivateRouter userType :::"+userType)

  return (
    <Routes>
       {
        //<Route path='/user-profile' element={<UserProfile />} />
       } 
       
        <Route path='/app' element={<DashboardLayout />} >
          {
           userType == 'Candidate' && (
            <>
            <Route index element={<CandidateDashbord  />} />
            <Route path='dashboard' element={<CandidateDashbord />} />
            </>
           )
          }
          {
           userType == 'employeer' && 
           <>
           <Route index element={< Dashboard/>} />
           <Route path='dashboard'  element={< Dashboard/>} />
           <Route path='post-job' element={<JobPost />} />
           </>
          }
          <Route path='user-profile' element={<UserProfile />} />
          <Route path='account-setting' element={<Account />} />
          <Route path='application-details' element={<ApplicationDetails />} />
          <Route path='*' element={<Page404 />} />

          </Route>

        </Routes>
  )
}

export default PrivateRouter;