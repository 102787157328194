import React from 'react'
import Skeleton from '@mui/material/Skeleton';
import * as Icon from 'react-bootstrap-icons';

export default function JobListComponentSkeleton() {
  return (
    <div> <div className="card card-job" >
    <div className="card-body">
        <div>
            <div className='job-info f-left'>
                <h5 className="card-title"><Skeleton variant="rectangular" height={30} /></h5>
                <p className='sub-header pt-2'><Skeleton variant="rectangular" height={30} /> </p>
                <p className='sub-header pt-2'><Skeleton variant="rectangular" height={30} /> </p>
               
               
            </div>
            <div className='comp-img f-right'>
               {/*<img className='image-style' src={comLogo} /> */}
               <Skeleton variant="rectangular" height={30} />
            </div>
        </div>
    </div>
    <div className="card-footer text-muted ">
        <div className='flex-layout'>
            <span className='pad-l-5'>  <Skeleton variant="rectangular" height={30} /></span>
            <div className='flex-float-right'>
                <span className='pad-l-5'>
                   <Skeleton variant="rectangular" height={30} width={100}/>
                </span>
            </div>
        </div>

    </div>
</div></div>
  )
}
