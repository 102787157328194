import React from 'react'
import BoostProfile from './BoostProfile'
import PrepAndLearn from './PrepAndLearn'
export default function ServiceList(props) {
  const  {isLoading} =props;
  return (
    <div className='row'>
       
        <BoostProfile isLoading={isLoading}/>
       {
        /*
         <PrepAndLearn isLoading={isLoading}/>
         */
       }
       
    </div>
  )
}
