import React,{useEffect} from 'react'
import SearchBanner from '../../components/searchBanner/SearchBanner';
import * as Icon from 'react-bootstrap-icons';
import SideBarJobs from './SideBarJobs';
import JobHeaderDetails from './JobHeaderDetails';
import JobDescDetails from './JobDescDetails';
import PayDetails from './PayDetails';
import JobSkills from './JobSkills';
import OtherDetails from './OtherDetails';

import SideBarJobsSkeleton from './skeleton/SideBarJobsSkeleton';
import JobHeaderDetailsSkeleton from './skeleton/JobHeaderDetailsSkeleton';
import JobDescDetailsSkeleton from './skeleton/JobDescDetailsSkeleton';
import PayDetailsSkeleton from './skeleton/PayDetailsSkeleton';
import JobSkillsSkeleton from './skeleton/JobSkillsSkeleton';
import OtherDetailsSkeleton from './skeleton/OtherDetailsSkeleton';

import { useGetJobsByIdQuery } from '../../rtk/jobs';
import JSpinner from '../../components/jspinner/JSpinner';
import Footer from './../../components/footer/Footer'

function PublicJobDetails() {
  
  let jobId = window.location.pathname.split("/").pop();
  const { trigger ,data, error, isLoading } = useGetJobsByIdQuery(jobId);

  
  return (
    <>
      <div className='pad-t-70'>
        <SearchBanner />
       
       
        {
        error ? (
          <div className='pad-t-30'>Oh no, there was an error</div>
      ) : isLoading ? (
        <>
         <div className="row">
        <div className="col-lg-9 col-md-12">
            <JobHeaderDetailsSkeleton data={data}/>
            <div className="row">
              <div className="col-lg-8 col-md-12">
                <JobDescDetailsSkeleton data={data}/>
              </div>
              <div className="col-lg-4 col-md-12">
                <PayDetailsSkeleton data={data}/>
                <JobSkillsSkeleton data={data}/>
                <OtherDetailsSkeleton data={data}/>
              </div>
            </div>
            </div>
            <div className="col-lg-3 col-md-12">
            <div className='card'>
              <SideBarJobsSkeleton dataObj={data}/>
            </div>
          </div>
         
        </div>
        </>
      ) : data ? (
        <>
         <div className="row">
        <div className="col-lg-9 col-md-12">
            <JobHeaderDetails data={data}/>
            <div className="row">
              <div className="col-lg-8 col-md-12">
                <JobDescDetails data={data}/>
              </div>
              <div className="col-lg-4 col-md-12">
                <PayDetails data={data}/>
                <JobSkills data={data}/>
                <OtherDetails data={data}/>
              </div>
            </div>
            </div>
            <div className="col-lg-3 col-md-12">
            <div className='card'>
              <SideBarJobs dataObj={data}/>
            </div>
          </div>
         
        </div>
        </>
      ) : null
      }
    
         
          
      </div>
      
    </>
  )
}

export default PublicJobDetails