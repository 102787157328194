import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react'
// material-ui
import { useTheme } from '@mui/material/styles';
import { Avatar, Box, ButtonBase } from '@mui/material';

// project imports
import LogoSection from '../LogoSection';
import SearchSection from './SearchSection';
import ProfileSection from './ProfileSection';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Link, useNavigate } from 'react-router-dom';
import NotificationSection from './NotificationSection';
import { useSelector } from 'react-redux';
import NavDropdown from 'react-bootstrap/NavDropdown';

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const Header = ({ handleLeftDrawerToggle }) => {
  const isAuthenticated = useSelector((state) => state.authState.isAuthenticated);
  const [header, setHeader] = useState("header fixed-top");
  const theme = useTheme();
  let navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [anchorElS, setAnchorElS] = React.useState(null);
  const openS = Boolean(anchorElS);
  const [whiteHeader, setWhiteHeader] = React.useState(false);
  const [expand, setExpand] = useState("md");

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClicks = (event) => {
    setAnchorElS(event.currentTarget);
  };
  const handleClose = (link) => {
    setAnchorEl(null);
    navigate(link);
  };
  const handleCloses = (link) => {
    setAnchorElS(null);
    navigate(link);
  };
  const listenScrollEvent = event => {
    if (window.scrollY < 73) {
      setWhiteHeader(false);
      return setHeader("header fixed-top");

    } else if (window.scrollY > 70) {
      setWhiteHeader(true);
      return setHeader("header2 fixed-top");

    }
  };
  return (
    <>
      {/* logo & toggler button */}
      <Box
        sx={{
          width: 228,
          display: 'flex',
          [theme.breakpoints.down('md')]: {
            width: 'auto'
          }
        }}
      >
       {
        
         <Box component="span" sx={{ display: { xs: 'block', md: 'block' }, flexGrow: 1 }}>
           
           <LogoSection />
          
         
        </Box>
        
        }
      </Box>
      <Box sx={{ flexGrow: 1 }} > </Box>
      <Box sx={{ flexGrow: 1 }} > 
      <Navbar className={header} key={expand}   expand={expand}   fixed="top" >
      <Container fluid>
        {
          /*
<Navbar.Brand href="#">
              <Navbar.Brand href="/auth/app/dashboard"> <div className='logo-header'> <LogoSection /></div></Navbar.Brand>
      </Navbar.Brand>
          */
        }
      

       
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto header-menu">
           
            <div>
            {
                
                <NavDropdown
                title="Jobs"
                id={`offcanvasNavbarDropdown-expand-${expand}`}
                className='header-font'
              >
                  <NavDropdown.Item onClick={() => handleClose("/jobs")}>Recommended jobs</NavDropdown.Item>
                  <NavDropdown.Item onClick={() => handleClose("/auth/app/application-details")}>Application status</NavDropdown.Item>
                  <NavDropdown.Item onClick={() => handleClose("/jobs?search=all-jobs")}>All Jobs</NavDropdown.Item>
                </NavDropdown>
                
              }
            </div>

            <NavDropdown
                title="Services"
                id={`offcanvasNavbarDropdown-expand-${expand}`}
                className='header-font'
              >
                <NavDropdown.Item onClick={() => handleClose("/boost-profile")}>Boost profile</NavDropdown.Item>
                <NavDropdown.Item onClick={() => handleClose("/learn-and-prep")}>Learn and prepare for job</NavDropdown.Item>
                <NavDropdown.Item onClick={() => handleClose("/resume-assist")}>Resume builing Assistance</NavDropdown.Item>
                   </NavDropdown>
                </Nav>
              
    </Navbar.Collapse>
      </Container>
    </Navbar>
     </Box>
     <div className='top-z-index'>
      <SearchSection />
      </div>
      <Box sx={{ flexGrow: 1 }} > </Box>

      {/* notification & profile */}
     {
      //<NotificationSection />
       
     }
     <div className='top-z-index'>
     <ProfileSection />
     </div>
     
    </>
  );
};

Header.propTypes = {
  handleLeftDrawerToggle: PropTypes.func
};

export default Header;
