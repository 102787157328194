import React from 'react'

import Skeleton from '@mui/material/Skeleton';
function PayDetailsSkeleton(props) {
  return (
    <div> <div className='card'>
    <div className='card-body'>

      <div className="form-group row">
      <p className='p-2'><Skeleton variant="rectangular" height={40} /></p>
      </div>
      <p className='p-2'><Skeleton variant="rectangular" height={40} /></p>
      </div>
      <p className='p-2'><Skeleton variant="rectangular" height={40} /></p>
      
  </div></div>
  )
}

export default PayDetailsSkeleton