import React from 'react'
import { useState,useRef } from 'react';
import comLogo from '../../assets/images/pexels-buro-millennial-1438081.jpg';
import SearchBanner from '../../components/searchBanner/SearchBanner';
import CardCategory from '../../components/categoryCard/CardCategory';
import * as Icon from 'react-bootstrap-icons';
import { NavLink } from 'react-router-dom';
import PersonalDetails from './PersonalDetails';
import ProgressCardComp from '../../components/progressCard/ProgressCardComp';
import Education from './Education';
import Employment from './Employment';
import Achivement from './Achivement';
import Projects from './Projects';
import Resume from './Resume';
import Skills from './Skills';
import { useSelector } from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import { formatPhoneNumber } from 'react-phone-number-input'
import CardProfile from './CardProfile';
function UserProfile() {
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [personal, setPersonal] = useState(true);
  const [eduction, setEduction] = useState(false);
  const [employment, setEmployment] = useState(false);
  const [project, setProject] = useState(false);
  const [achivement, setAchivement] = useState(false);
  const [skills, setSkills] = useState(false);
  const [resume, setResume] = useState(false);
  const [ismodified, setIsmodified] = useState(false);
  const [currentTab, setCurrentTab] = useState('personal-info');
  const [previousTab, setPreviousTab] = useState(['','personal-info']);
  const [nextTab, setNextTab] = useState();
  const firstName = useSelector((state) => state.authState.firstName);
  const lastName = useSelector((state) => state.authState.lastName);
  const mobileName = useSelector((state) => state.authState.mobileName);
  const email = useSelector((state) => state.authState.email);
  const formRef = useRef();
  function showComp(value,bolStatu) {
    let proc =false;
    setNextTab(value)
    console.log("ismodified ::"+ismodified + " value ::"+value +" formRef ::"+JSON.stringify(formRef.current) + " current :"+(formRef.current ==null) + "currentTab ::"+currentTab + " bolStatu::"+bolStatu)
    /*if(currentTab =='personal-info' && !(formRef.current ==null)){
      console.log("formRef.current.dirty ::"+formRef.current.dirty)
      setIsmodified(formRef.current.dirty)
      proc =formRef.current.dirty;
    }else {
      proc =ismodified;
    }
     */
    
    if(bolStatu == undefined){
      bolStatu = ismodified;
    }

    setShowLoginModal(ismodified);
    if(!bolStatu){

      console.log("currentTab currentTab ::"+currentTab)
    const removeClassDoc = document.getElementById(currentTab);
    removeClassDoc.classList.remove('active');
    const aTag = document.getElementById(value);
    aTag.classList.add('active');
console.log(" formRef :"+JSON.stringify(formRef))
console.log(" formRef ismodified ismodified :"+ismodified)
   

    if (value == 'personal-info') {
      setPersonal(true);
      setEduction(false);
      setEmployment(false);
      setProject(false);
      setAchivement(false);
      setSkills(false);
      setResume(false);

    } else if (value == 'education') {
      setPersonal(false);
      setEduction(true);
      setEmployment(false);
      setProject(false);
      setAchivement(false);
      setSkills(false);
      setResume(false);

    }else if (value == 'employment') {
      setPersonal(false);
      setEduction(false);
      setEmployment(true);
      setProject(false);
      setAchivement(false);
      setSkills(false);
      setResume(false);

    } else if (value == 'project') {
      setPersonal(false);
      setEduction(false);
      setEmployment(false);
      setProject(true);
      setAchivement(false);
      setSkills(false);
      setResume(false);

    } else if (value == 'achivement') {
      setPersonal(false);
      setEduction(false);
      setEmployment(false);
      setProject(false);
      setAchivement(true);
      setSkills(false);
      setResume(false);

    }  else if (value == 'skills') {
      setPersonal(false);
      setEduction(false);
      setEmployment(false);
      setProject(false);
      setAchivement(false);
      setSkills(true);
      setResume(false);

    } 
    else if (value == 'resume') {
      setPersonal(false);
      setEduction(false);
      setEmployment(false);
      setProject(false);
      setAchivement(false);
      setSkills(false);
      setResume(true);

    }  

    console.log("valuevaluevalue ::"+value)
    setCurrentTab(value);
    let previousTab1 = [previousTab[1],'']
    previousTab1[1] = value;
    console.log("currentTab[1]::"+previousTab1[1])
    setPreviousTab(previousTab1);
  }
  }

  return (
    <div >

      <div className='container-fluid'>
      <PopUp
                                show={showLoginModal}
                                setShowLoginModal={setShowLoginModal}
                                onHide={() => setShowLoginModal(false)}
                                previoustab={previousTab[0]}
                                currentTab={currentTab}
                                setCurrentTab={setCurrentTab}
                                showcomp={showComp}
                                setismodified={setIsmodified}
                                formref={formRef}
                                nexttab={nextTab}
                            />
        <div className='row'>
          <div className="col-4">
            <div className='card'>
              <div className='card-body'>

                <div className='flex-layout justify-content-center'>

                  <div >  <CardProfile/></div>
                 

                </div>
                <div className='pad-t-5'>

                  <div className='pad-10 pad-t-10'>
                    <div className='flex-layout justify-content-center justify-content-between'>
                      <span className='profile-details-text'> Name :</span>
                      <span className='profile-details-text get-gray-color'> {firstName} {lastName}</span>
                    </div>
                    <div className='flex-layout justify-content-center justify-content-between'>
                      <span className='profile-details-text'> Number :</span>
                      <span className='profile-details-text get-gray-color'> {formatPhoneNumber(mobileName)}</span>
                    </div>
                    <div className='flex-layout justify-content-center justify-content-between'>
                      <span className='profile-details-text'> Email :</span>
                      <span className='profile-details-text get-gray-color'> {email}</span>
                    </div>
                  </div>

                  <div className='profile-menu pad-10 pad-t-10'>
                    <div id="personal-info" className='menu-item pad-10 active' onClick={() => showComp('personal-info')}>
                      <a><Icon.PersonFill size={17} /> Personal details </a>
                    </div>
                    <div id="resume" className='menu-item pad-10' onClick={() => showComp('resume')}>
                      <a><Icon.FileEarmarkBinaryFill size={17} /> Resume</a>
                    </div>
                    <div id="education" className='menu-item pad-10' onClick={() => showComp('education')}>
                      <a><Icon.MortarboardFill size={17} /> Education </a>
                    </div>
                    <div id="employment" className='menu-item pad-10' onClick={() => showComp('employment')}>
                      <a><Icon.BagFill size={17} /> Employment </a>
                    </div>
                    
                    <div id="project" className='menu-item pad-10' onClick={() => showComp('project')}>
                      <a><Icon.KanbanFill size={17} /> Projects</a>
                    </div>
                    <div id="skills" className='menu-item pad-10' onClick={() => showComp('skills')}>
                      <a><Icon.DiscFill size={17} /> Skills</a>
                    </div>
                    
                   
                  </div>


                </div>


              </div>

            </div>
          </div>
          <div className="col-8">
          <div className='card'>
          {/* <ProgressCardComp/> */}
</div>
            <div className='card'>
              {personal ? <PersonalDetails formRef={formRef} setIsmodified={setIsmodified} /> : null}
              {eduction ? <Education setIsmodified={setIsmodified}/> : null}
              {employment ? <Employment setIsmodified={setIsmodified}/> : null}
              {skills ? <Skills setIsmodified={setIsmodified}/> : null}
              {project ? <Projects setIsmodified={setIsmodified}/> : null}
              {achivement ? <Achivement setIsmodified={setIsmodified}/> : null}
              {resume ? <Resume setIsmodified={setIsmodified}/> : null}
              

            </div>
          </div>

         
        </div>
      </div>

    </div>
  )
}

const PopUp =(props) =>{
const {currentTab,setCurrentTab,onHide,showcomp,setismodified,previoustab,formref,nexttab} = props;
console.log("PopUp currentTab ::"+currentTab + " previoustab ::"+previoustab)
const handleBack =()=>{
  console.log("PopUp handleBack currentTab ::"+currentTab + " previoustab ::"+previoustab)
 // setCurrentTab(currentTab);
  showcomp(currentTab);
  onHide(true);
  //setismodified(false)
}

const handleSkip =()=>{
  console.log("currentTab::"+currentTab + " formref ::"+nexttab)
  setismodified(false);
  if(currentTab=='personal-info'){
    formref.current.dirty =false
  }
  setCurrentTab(nexttab);
 
  showcomp(nexttab,false);
  onHide(true);
}

  return (
    <>
      <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
      <div className='card-header flex-layout justify-content-between'>
            <div className='align-items-start flex-column'>
            You have some unsaved changes in {currentTab} details
              
            </div>

          </div>
      </Modal.Header>
      <Modal.Body>
      <div className="flex-layout justify-content-between ">
                     <button type="submit" className="btn btn-primary rounded submit p-2 px-4" onClick={()=>handleBack()}>Back to {currentTab} Details</button>
                     <button type="submit" className="btn btn-primary rounded submit p-2 px-4" onClick={()=>handleSkip()}> Skip </button>
      </div>
        
      
      </Modal.Body>
    
    </Modal>
    </>
  )
}

export default UserProfile