import { createSlice } from '@reduxjs/toolkit'

export const golbalReducer = createSlice({
  name: 'golbalReducer',
  initialState: {
    value: 0,
    searchQueryData:{searchValue:"",catagory: ""},
    jobFilterData:[],
    bannerSearch :{searchQuery:"",expDetails: "", location:""},
    stepperDetails: {presentStep:0,toStep:0,step0Data:false,step1Data:false},
    jobTab: "applied",
    appliedJob :[]
  },
  reducers: {
    increment: (state) => {

      
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.value += 1
    },
    searchQuery: (state,payload) => {
      state.searchQueryData.searchValue =payload.payload.searchValue;
      state.searchQueryData.catagory =payload.payload.catagory;
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      
    },
    jobsFilter:(state,payload) => {
     
     if(state.jobFilterData.includes(payload.payload)){
      const index = state.jobFilterData.indexOf(payload.payload);
      if (index > -1) { // only splice array when item is found
        state.jobFilterData.splice(index, 1); // 2nd parameter means remove one item only
      }
      
     }else{
      state.jobFilterData.push(payload.payload);
     }
      
    },
    bannerSearch:(state,payload) => {
      if(payload.payload.type=="searchQuery"){
        state.bannerSearch.searchQuery= payload.payload.value;
      }else if(payload.payload.type=="location"){
        state.bannerSearch.location= payload.payload.value;
      }else if(payload.payload.type=="exp"){
        state.bannerSearch.expDetails= payload.payload.value;
      }
      
    },
    stepperDetails:(state,payload) => {
      state.stepperDetails.presentStep=payload.payload.presentStep;
      state.stepperDetails.toStep=payload.payload.toStep;
     },
     jobStatusDetails:(state,payload) => {
      console.log("payload :::"+JSON.stringify(payload.payload))
      state.jobTab=payload.payload;
     },
     updateApplied:(state,payload) => {
      console.log("updateApplied payload :::"+JSON.stringify(payload.payload))
      state.appliedJob = payload.payload;
      /*var filteredObj = state.appliedJob.find(function(item, i){
        if(item === payload){
          return true;
        }
      });
      if (filteredObj){

      }else {
        state.appliedJob.push(payload);
      }
        */
     
     }
     
  },
 
}
)

// Action creators are generated for each case reducer function
export const { increment,searchQuery,jobsFilter,bannerSearch,stepperDetails,jobStatusDetails,updateApplied } = golbalReducer.actions

export default golbalReducer.reducer