import React, { useState, useEffect } from 'react'
import TextField from '@mui/material/TextField';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { Divider } from '@mui/material';
import Modal from 'react-bootstrap/Modal';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import * as Icon from 'react-bootstrap-icons';
import { useGetSkillsQuery } from '../../rtk/jobs';
import { useSaveUserSkillsMutation } from '../../rtk/profile';
import notify from '../../utils/Utils';
import { useSelector } from 'react-redux';
import {useLazyGetSkillssDetailsQuery} from './../../rtk/profile';
import JSpinner from './../../components/jspinner/JSpinner';
import Skeleton from '@mui/material/Skeleton';

export default function Skills(props) {
  const {setIsmodified} =props;
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [skillValue, setSkillValue] = useState();
  const [loading, setLoading] = useState(false);
  const [skillsList, setSkillsList] = useState([]);
  const [optionData, setOptionData] = useState([]);
  const [skillsDetailsList, setSkillsDetailsList] = useState([]);
  const [skillsLoading, setSkillstLoading] = useState(true);
  const { data, error, isLoading } = useGetSkillsQuery();
  const userId = useSelector((state) => state.authState.userId);
  const [fetchSkillsDetails, { data: projectData, error: projectError, isLoading: projectIsLoading }] = useLazyGetSkillssDetailsQuery();
  
  async function fetchEducationDataList() {
    const response = await fetchSkillsDetails();
    setSkillsDetailsList(response.data);
      setSkillsList(response.data)
    setSkillstLoading(false);
  }

  useEffect(() => {
    fetchEducationDataList();
  }, [])
  
function handleEditSkill(index) {
    setSkillValue(skillsList.filter(a =>
      a.skillName === index
    ));
    setIsEdit(true);
    setShowLoginModal(true);
  }

  const [
    updateSkills, // This is the mutation trigger
    { error: updateProgramError, isLoading: isUpdating }, // This is the destructured mutation result
  ] = useSaveUserSkillsMutation()

  function handledeleteSkill(index) {
    setSkillsList(skillsList.filter(a =>
      a.skillName !== index
    ));
  }

  function handelAddSkill() {
    setIsEdit(false);
    setSkillValue(undefined)
    setShowLoginModal(true);
  }
  const options = [];

  useEffect(() => {
    console.log("data ::"+JSON.stringify(data))
    setOptionData(undefined);
    if (data != undefined) {
      data.map((item) => {
        let index = options.findIndex((item1) => item1.skillName === item.skillName);

        if (index === -1) {
          // If not found, push a new object with the desired properties
          options.push({ 'id': item.id, 'skillName': item.skillName, 'skillLevel': "NA", isNew: false });
        } else {
          // If found, log a message indicating that the object already exists
        }
      })

    }
    setOptionData(options);
  }, [data])

  console.log("in optioiojoij ::"+JSON.stringify(optionData))

  function hideModel() {
    setIsEdit(false);
    setShowLoginModal(false);
  }

  const handleSumbit = async (data) => {
    setLoading(true);
    const returned = await updateSkills({ userId: userId, skills: JSON.stringify(skillsList) }).unwrap();
    setLoading(false);
    if (returned.status == 'success') {
     // notify("success", "Success Message", "Skills saved Successfully");
    } else {
      notify("danger", "Rrror Message", "Error in saving skills");
    }
    setIsmodified(false)
  }
  return (
    <div className='pad-10'>
      <div className='card-header flex-layout justify-content-between'>
        <div className='align-items-start flex-column'>
          Skills Information
          <p className='profile-tagline'>
            Update your skill informaiton
          </p>
        </div>
        <SkillsChild
            show={showLoginModal}
            onHide={hideModel}
            skillValue={skillValue}
            isEdit={isEdit}
            setIsEdit={setIsEdit}
            options={optionData}
            setSkillValue={setSkillValue}
            skillsList={skillsList} setSkillsList={setSkillsList} setShowLoginModal={setShowLoginModal}
            setIsmodified={setIsmodified}
          />
        <div className='align-items-end flex-column'>
        {
              skillsList.length != 0 && (
                <>
          <button className='btn-style secondry-color' onClick={handelAddSkill}>

            Add Skill
          </button>
         
         
          </>
     ) }
      <button className='btn-style secondry-color' onClick={handleSumbit} disabled={loading}>
            
            {!loading && <span className='indicator-label'>Save</span>}
                {loading && (
                  <span className='indicator-progress' style={{ display: 'block' }}>
                    Please wait...
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
            </button>
        </div>
      </div>
      <div>

        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell className='font-14-bold'>Skill Name</TableCell>
                <TableCell className='font-14-bold' align="left">Skill level</TableCell>
               
              </TableRow>
            </TableHead>
            <TableBody>
 {
        skillsLoading ? (<>
        
        <TableRow
                  key={"row.name"}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    <b><Skeleton variant="rectangular" height={30} /></b>
                  </TableCell>
                  <TableCell align="left"><Skeleton variant="rectangular" height={30} /></TableCell>
                  <TableCell align="left">
                  <Skeleton variant="rectangular" height={30} />
                  <Skeleton variant="rectangular" height={30} />
                  </TableCell>
                </TableRow>
        </>) : (
              skillsList.length != 0 ? (skillsList.map((row, index) => (
                <TableRow
                  key={row.name}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    <b>{row.skillName}</b>
                  </TableCell>
                  <TableCell align="left">{row.skillLevel}</TableCell>
                  <TableCell align="left">
                    <button className='mar-l-5 no-border no-backgroud' onClick={() => handleEditSkill(row.skillName)}><Icon.PencilSquare size={25} /> </button>
                    <button className='mar-l-5 no-border no-backgroud' onClick={() => handledeleteSkill(row.skillName)}><Icon.ArchiveFill size={25} /></button>
                  </TableCell>
                </TableRow>
              ))) : (<> 
              <div
        style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '30vh',
        }}
    >
      <b>Nothing to display. <button className='btn-style secondry-color' onClick={handelAddSkill}>Add skills</button></b>
      </div> 
              </>)

            )
          }
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  )
}


export function SkillsChild(props) {
  const { skillsList, setSkillsList, setShowLoginModal, skillValue, isEdit, setIsEdit, setSkillValue, options,setIsmodified } = props;
  const [value, setValue] = useState({
    id: "",
    skillName: "",
    skillLevel: ""

  });
  const [error, setError] = React.useState(false);
  function handleAddSkills() {
    setIsmodified(true)
    setIsEdit(false)
    setSkillValue("");
    if (value.skillLevel.length == 0 && value.skillName.length == 0) {
      setError(true);
    } else {
      setError(false);
      setShowLoginModal(false);
    }
  }

  useEffect(() => {
    if (skillValue != undefined && skillValue.length > 0 && isEdit == true) {
      setValue(skillValue[0])
    } else {
      setValue({
        id: "",
        skillName: "",
        skillLevel: "",
        isNew: false
      })
    }

  }, [skillValue, isEdit])

  console.log("options skill; up  ::"+JSON.stringify(options))

  const filter = createFilterOptions();
  return (
    <Modal
      {...props}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
      </Modal.Header>
      <Modal.Body>
        <div className='card-header flex-layout justify-content-center'>
          <div className='align-items-start flex-column'>
            Add Skills Information
            <p className='profile-tagline'>
              Update your skill informaiton
            </p>
          </div>
          <div className='align-items-end flex-column'>
          </div>
        </div>
        <Divider />
        <div>
          <div className='card-body'>
            <div className="form-group row pad-5">
              <label className="col-xl-3 col-lg-3 col-form-label font-14">Skill</label>
              <div className="col-lg-9 col-xl-6">
                <Autocomplete
                  value={value.skillName}
                  onChange={(event, newValue) => {
                    if (typeof newValue.skillName === 'string') {
                      setValue({
                        ...value,
                        id: newValue.id,
                        skillName: newValue.skillName.replace("Add", "").replaceAll("\"", ""),
                        isNew: newValue.isNew
                      });
                    } else if (newValue && newValue.inputValue) {
                      // Create a new value from the user input
                      setValue({
                        ...value,
                        id: newValue.id,
                        skillName: newValue.inputValue.replace("Add", "").replaceAll("\"", ""),
                        isNew: newValue.isNew
                      });
                    } else {
                      setValue({ ...value, id: newValue.id, skillName: newValue.skillName.replace("Add", "").replaceAll("\"", ""), isNew: newValue.isNew });
                    }

                    if ( skillsList.length != 0) {
                      let pos = skillsList.findIndex(val => val.skillName === newValue.skillName)
                      if (pos !== -1) {
                        let ski = JSON.parse(JSON.stringify(skillsList));
                        ski[pos].skillName = newValue.skillName.replace("Add", "").replaceAll("\"", "");
                        setSkillsList(ski);
                      } else {
                        let ski = JSON.parse(JSON.stringify(skillsList));
                      ski.push({ id: newValue.id, skillName: newValue.skillName.replace("Add", "").replaceAll("\"", ""), skillLevel: "", isNew: newValue.isNew })
                      setSkillsList(ski); 
                    }

                    } else {
                      let ski = JSON.parse(JSON.stringify(skillsList));
                      ski.push({ id: newValue.id, skillName: newValue.skillName.replace("Add", "").replaceAll("\"", ""), skillLevel: "", isNew: false })
                      setSkillsList(ski); 
                    }
                  }}
                  filterOptions={(options, params) => {
                    const filtered = filter(options, params);
                    const { inputValue } = params;
                    const isExisting = options.some((option) => inputValue === option.skillName);
                    if (inputValue !== '' && !isExisting) {
                      filtered.push({
                        id: inputValue,
                        skillName: `Add "${inputValue}"`,
                        isNew: true
                      });
                    }
                    return filtered;
                  }}
                  selectOnFocus
                  clearOnBlur
                  handleHomeEndKeys
                  id="free-solo-with-text-demo"
                  options={options}
                  getOptionLabel={(option) => {
                    // Value selected with enter, right from the input
                    if (typeof option === 'string') {
                      return option;
                    }
                    // Add "xxx" option created dynamically
                    if (option.inputValue) {
                      return option.inputValue;
                    }
                    // Regular option
                    return option.skillName;
                  }}
                  renderOption={(props, option) => <li {...props}>{option.skillName}</li>}
                  freeSolo
                  renderInput={(params) => (
                    <TextField {...params} label="" />
                  )}
                />
              </div>
            </div>
            <div className="form-group row pad-5">
              <label className="col-xl-3 col-lg-3 col-form-label font-14">Skill level </label>
              <div className="col-lg-9 col-xl-6">
                <select className={"form-control "}
                  id="skillLevel"
                  name="skillLevel"
                  onChange={(option) => {
                    setValue({ ...value, skillLevel: option.target.value })
                    let ski = JSON.parse(JSON.stringify(skillsList));
                    let pos = ski.map(val => val.skillName).indexOf(value.skillName)
                    ski[pos].skillLevel = option.target.value;
                    setSkillsList(ski);
                  }}
                >
                  <option value="">Select</option>
                  <option value="Beginner">Beginner</option>
                  <option value="Intermediate">Intermediate</option>
                  <option value="Advanced">Advanced</option>
                </select>
                {
                  error && <div className='error-message'>
                    Please provide valid details
                  </div>
                }

              </div>
            </div>

          </div>


          <div className='flex-layout justify-content-center'>

            <button className='btn-style secondry-color' onClick={handleAddSkills}>Add skill</button>
          </div>



        </div>


      </Modal.Body>

    </Modal>


  )
}



