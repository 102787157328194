import React from 'react'
import image1 from '../../assets/images/icons8-training-100.png'
import image2 from '../../assets/images/drone-4256093_1920-removebg-preview.png'
import image3 from '../../assets/images/developer-3461405_1920-removebg-preview.png'
import * as Icon from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';

import {FinanceCoinBusinesswomanCareer,OnlineLearing,Gethired} from '../svg/Icon'
function AboutUsBanner() {
  return (
    <>
    <div className='card bg-plane no-border no-box-shadow' >
    <div className='card-body '>
        <div className='row'>
    <div className="col-xl-4 col-md-4">
    <div className='card trans no-border no-box-shadow text-center'> 
    
    <div className='svg'><FinanceCoinBusinesswomanCareer /></div>
      <h5 className="card-title">Compensation </h5>
      <p className="card-text">Get help from a caring and knowledgeable professional. Let us know.</p>
      <Link className="nav-link font-weight-bold link-color" to="/jobs">Read more <Icon.ArrowRight/></Link>
     
    </div>
    </div>
        
    <div className="col-xl-4 col-md-4">
    <div className='card trans no-border no-box-shadow text-center'> 
    
    <div className='svg'><OnlineLearing /></div>
      <h5 className="card-title">Training </h5>
      <p className="card-text">Get help from a caring and knowledgeable professional. Let us know.</p>
      <Link className="nav-link font-weight-bold link-color" to="/jobs">Read more <Icon.ArrowRight/></Link>
     
    </div>
    </div>

        
    <div className="col-xl-4 col-md-4">
    <div className='card trans no-border no-box-shadow text-center'> 
    
    <div className='svg'><Gethired /></div>
      <h5 className="card-title">Get Hired </h5>
      <p className="card-text">Get help from a caring and knowledgeable professional. Let us know.</p>
      <Link className="nav-link font-weight-bold link-color" to="/jobs">Read more <Icon.ArrowRight/></Link>
     
    </div>
    </div>
        
        </div>
        </div>
    </div>
    </>
  )
}

export default AboutUsBanner