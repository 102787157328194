import React,{useState,useEffect} from 'react'
import { Formik, Field, Form, ErrorMessage,useFormikContext } from 'formik';
import * as Yup from 'yup';
import { useSelector, useDispatch } from 'react-redux';
import { stepperDetails } from '../../redux-reducers/global';

function CompensationDetails(props) {

  const {compDetailsState, setCompDetailsState,compDetailsRef} =props;
  const [cValue ,setCValue] =useState([]);
  const dispatch = useDispatch();
  const handleCheckoutChange = (event) => {
    const { name, value } = event.target;
    setCValue(oldArray => [...oldArray, value]);
    }
    function validationSchema() {
      return Yup.object().shape({
        minSal: Yup.string().required('Min Salary is required'),
        maxSal: Yup.string(),
        compType: Yup.string().required('Compensation Type is required'),
        benifits: Yup.array(),
       
      });
    }
  useEffect(() => { setCompDetailsState(() => {
    return {
      ...compDetailsState,
      ['benifits']: cValue
    };
  });
    }, [cValue])
  
  const handleChange = (event) => {
    const { name, value } = event.target;
    setCompDetailsState(() => {
        return {
          ...compDetailsState,
          [name]: value
        };
      });
    
  };
  function handleSubmit(data) {
    setCompDetailsState(data);
    dispatch(stepperDetails({"presentStep":2,"toStep":3,"step1Data":true}))
    
  }
  
  return (
    <div className='pad-t-15'>
<Formik
          initialValues={compDetailsState}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          
        >
           {({ errors, touched, resetForm,isValidating,handleChange,setFieldValue  }) => (
            <Form>
    <div className='card '>
      <div className='card-body'>
        <div className='card-header flex-layout justify-content-between'>
          <div className='align-items-start flex-column'>
            Describe Compensation Details for the job
           
          </div>

        </div>

        <div className='pad-t-10'>
          
        <div class="form-group row pad-5">
          <label class="col-xl-3 col-lg-3 col-form-label font-14">Pay Range</label>
          <div class="col-lg-9 col-xl-6">
            <div class="row">
              <div class="col">
                <Field type="text" class="form-control" placeholder="Minimum salary" 
                 id="minSal"
                 name="minSal"
                 />
              </div> - 
              <div class="col">
                <Field type="text" class="form-control" placeholder="Maximum salary" 
                 id="maxSal"
                 name="maxSal"
                 />
              </div>
              {errors.minSal && errors.maxSal && touched.minSal && touched.maxSal   ? (
          <div className='danger-font'>{'Min and Max Salary is required'}</div>
         ) : null}
            </div>
            

          </div>
        </div>
        <div className="form-group row pad-5">
            <label className="col-xl-3 col-lg-3 col-form-label font-14">Compensation Type</label>
            <div className="col-lg-9 col-xl-6">
              <Field as="select" className={"form-control "+
                    (errors.compType && touched.compType ? ' is-invalid' : '')}
                id="compType"
             
                name="compType"
                
              >
                <option value="">Select</option>
                <option value="Per Hour">Per Hour</option>
                <option value="Per week">Per week</option>
                <option value="Per month">Per month</option>
                <option value="Per year">Per year</option>
                <option value="One type">One type</option>
              </Field>
              <ErrorMessage
                  name="compType"
                  component="div"
                  className="invalid-feedback"
                />
            </div>
          </div>
        <div class="form-group row pad-5">
          <label class="col-xl-3 col-lg-3 col-form-label font-14">Benefits offerings</label>
          <div class="col-lg-9 col-xl-9">
            <div class="form-check form-check-inline">
            <Field class="form-check-input" type="checkbox"
               id="benifits"
               name="benifits"
               value="Overtime pay" 
               />
              <label class="form-check-label" for="inlineCheckbox1">Overtime pay</label>
            </div>
            <div class="form-check form-check-inline">
              <Field class="form-check-input" type="checkbox"
                id="benifits"
                name="benifits"
                value="Shift allowance" />
              <label class="form-check-label" for="inlineCheckbox2">Shift allowance </label>
            </div>{

              /*
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="checkbox" id="inlineCheckbox3" value="option3" disabled />
              <label class="form-check-label" for="inlineCheckbox3">3 (disabled)</label>
            </div>
              */
            }
            <div class="form-check form-check-inline">
              <Field class="form-check-input" type="checkbox" 
               id="benifits"
               name="benifits"
               value="Joining bonus"
                />
              <label class="form-check-label" for="inlineCheckbox2">Joining bonus</label>
            </div>


            <div class="form-check form-check-inline">
              <Field class="form-check-input" type="checkbox" 
              id="benifits"
              name="benifits"
              value="yearly bonus " />

              <label class="form-check-label" for="inlineCheckbox2">yearly bonus </label>
            </div>

            <div class="form-check form-check-inline">
              <Field class="form-check-input" type="checkbox" id="benifits"
              name="benifits"
              value="other"
               />
              <label class="form-check-label" for="inlineCheckbox2">other</label>
            </div>
            {errors.benifits && touched.benifits    ? (
          <div className='danger-font'>{errors.benifits}</div>
         ) : null}
          </div>
        </div>
        </div>

        <button ref={compDetailsRef} name="subBut" type="submit" style={{display:'none'}}>Submit</button>
<input type="hidden"  name="hiddenErrors" id="hiddenErrors" value={JSON.stringify(errors) }></input>
<input type="hidden"  name="hiddentouched" id="hiddenErrors" value={JSON.stringify(touched) }></input>

 

      </div>
    </div>
    </Form>
       )}
   </Formik>
  </div>
  )
}

export default CompensationDetails