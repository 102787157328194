import React from 'react'
import * as Icon from 'react-bootstrap-icons';
function PayDetails(props) {
  const {data} =props;
  return (
    <div> <div className='card'>
    <div className='card-body'>

      <div className="form-group row">
        <label className=" col-form-label font-14">Pay:

          <Icon.CurrencyDollar size={16} />
          {
            data.compensation.maxComp.length != 0 ?(
              <span className='title'> {data.compensation.minComp}{" "+data.compensation.type}</span>
            ):(
              <span className='title'> {data.compensation.minComp} - {data.compensation.maxComp} {" "+data.compensation.type}</span>
            )
          }
         
        </label>

      </div>
      {
        data.benifits.length != 0 && <>
         <p className='title'>Benifits</p>
      <p>
      {
      data.benifits !=0 && data.benifits.map(
  (item, i, arr) => <span className='skill-bg mar-l-5'>{item} </span>)

}
    </p>
</>
      }
     
    </div>
  </div></div>
  )
}

export default PayDetails