import { ErrorMessage, useFormik } from 'formik'
import React, { useState } from 'react'

import * as Yup from 'yup';
import clsx from 'clsx';
import { ReactComponent as YourSvg } from './../../assets/SVG-PL/business-electricity-connect-energy-commerce-svgrepo-com.svg';
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux';
import sha256 from 'crypto-js/sha256';
import hmacSHA512 from 'crypto-js/hmac-sha512';
import Base64 from 'crypto-js/enc-base64';
import CryptoJS from 'crypto-js';
import { useRegisterMutation } from '../../rtk/auth';
import notify from '../../utils/Utils';
import PhoneInput from 'react-phone-number-input'

export default function Register(props) {

const {registerationType,storeUserData,enableLogin,onHide} =props;
  const initialValues = {
    fname:'',
    lname:'',
    email: '',
    password: '',
    mobile:'',
    registerationtype:''
  }
  const [
    doRegister, // This is the mutation trigger
    {error: updateProgramError , isLoading: isUpdating }, // This is the destructured mutation result
  ] =useRegisterMutation()

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  let navigate = useNavigate();
  const loginSchema = Yup.object().shape({
    fname: Yup.string()
      .min(3, 'Minimum 3 character is required')
      .max(50, 'Maximum 50 character is required')
      .required('First name is required'),
      lname: Yup.string()
      .min(3, 'Minimum 3 character is required')
      .max(50, 'Maximum 50 character is required')
      .required('Last name is required'),
    email: Yup.string()
      .email('Wrong email format')
      .min(3, 'Minimum 3 character is required')
      .max(50, 'Maximum 50 character is required')
      .required('Email is required'),
      mobile: Yup.string()
      .min(3, 'Minimum 3 character is required')
      .max(50, 'Maximum 50 character is required')
      .required('Mobile number is required'),
    password: Yup.string()
      .min(3, 'Minimum 3 character is required')
      .max(50, 'Maximum 50 character is required')
      .required('Password is required')
  }
    )
  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true)
      
      initialValues.email=values.email;
      initialValues.fname=values.fname;
      initialValues.lname=values.lname;
      initialValues.mobile=values.mobile;
      initialValues.registerationtype=registerationType;
      console.log("initialValues in registerationtype ::"+registerationType);
      console.log("initialValues in values.password ::"+JSON.stringify(initialValues));
      //const encrypted = CryptoJS.AES.encrypt(values.password, "test");
     
    var encrypted = CryptoJS.AES.encrypt(values.password, process.env.REACT_APP_SECRET_KEY).toString();
    
       initialValues.password = encrypted;
      console.log("initialValues in register ::"+JSON.stringify(initialValues));
      console.log("process.env.hostnameurl ::"+process.env.REACT_APP_HOSTNAME)
      let errorres ;
      const returned =await doRegister(initialValues).unwrap().catch(res => errorres = res);
      console.log("process.env. returned ::"+JSON.stringify(returned))
      console.log("process.env. error ::"+JSON.stringify(errorres))
      if(errorres != undefined){
        console.log("process.env. error parse ::"+errorres.data.errorMessage)
        if(errorres.data.errorMessage == undefined){
          console.log("I fffff ::")
          setError("Internal Error");
        }else {
        
          setError(errorres.data.errorMessage);
        }
       
        setLoading(false);
      }else {
        setLoading(false);
        storeUserData(returned);
        onHide(false);
       // navigate('/auth/dashboard');
        navigate('/auth/app/dashboard');
      }
     
      
     
     //if(returned.status =='success'){
       // notify("success","Success Message","Resume Upload Successfully");
       
         /* ReactGA.event({
            category: 'Job Applied ',
            action: 'Action : Job Applied from popup for Job Id'+jobidProp,
            label:'Job Applied from popup for JOb Id'+jobidProp
          });
*/
       // setConfirmation(true);
        
      // }else {
       /* ReactGA.event({
          category: 'Job Applied ',
          action: 'Action : Job Applied from popup for Job Id'+jobidProp + " Failed",
          label:'Job Applied from popup for JOb Id'+jobidProp +" Failed"
        });
        */
       // notify("danger","Rrror Message","Error in uploaded Successfully");
      // }
      /* try{
         const data = await service.login(values.email,values.password);
         dispatch(saveAuth(data));
         navigate('/auth/dashboard');
       }catch(error){
         setStatus('The login details are incorrect');
         setSubmitting(false);
         setLoading(false);
       }
 */
    }
  })


  return (
    <>
     <div className='row'>
      <div className='col-xl-6 col-md-6 d-none d-lg-block'>
        <div className='vertical-center'>
      <h4 className="text-center mb-4">Plan Next with Connect Nex , Welocme to Connect Nex</h4>
        <div className='justify-svg-center'><YourSvg/></div>
        </div>
        </div>
      <div className='col-md-6  col-sm-12'>
        
       
      <h5 className="text-center mb-4">Create your account</h5>
      <div className='row justify-content-center error-message p-2'> {error}</div>
      {formik.status ? (
        <div>

          <div className='mb-lg-15 alert alert-danger'>
            <div className='alert-text font-weight-bold'>{formik.status}</div>
          </div>
        </div>
      ) : (
        ""
      )}

      <form className="login-form" onSubmit={formik.handleSubmit}>
      <div className="form-group">
          <div className="mb-3">
            <div>
              <label className="col-form-label font-14">First name </label>
            </div>
            <input type="fname" placeholder="First name"
              {...formik.getFieldProps('fname')}
              className={clsx(
                'form-control',
                { 'is-invalid': formik.touched.fname && formik.errors.fname },
                {
                  'is-valid': formik.touched.fname && !formik.errors.fname,
                }
              )}

              name='fname'
              autoComplete='off'
            />
             {formik.touched.fname && formik.errors.fname && (
              <div className='error-message p-2'> {formik.errors.fname}</div>
             )}
          </div>
        </div>
        <div className="form-group">
          <div className="mb-3">
            <div>
              <label className="col-form-label font-14">Last name</label>
            </div>
            <input type="lname" placeholder="Last name"
              {...formik.getFieldProps('lname')}
              className={clsx(
                'form-control',
                { 'is-invalid': formik.touched.lname && formik.errors.lname },
                {
                  'is-valid': formik.touched.lname && !formik.errors.lname,
                }
              )}

              name='lname'
              autoComplete='off'
            />
            {formik.touched.lname && formik.errors.lname && (
              <div className='error-message p-2'> {formik.errors.lname}</div>
             )}
          </div>
        </div>
        <div className="form-group">
          <div className="mb-3">
            <div>
              <label className="col-form-label font-14">Email</label>
            </div>
            <input type="email" placeholder="name@example.com"
              {...formik.getFieldProps('email')}
              className={clsx(
                'form-control',
                { 'is-invalid': formik.touched.email && formik.errors.email },
                {
                  'is-valid': formik.touched.email && !formik.errors.email,
                }
              )}

              name='email'
              autoComplete='off'
            />
            {formik.touched.email && formik.errors.email && (
              <div className='error-message p-2'> {formik.errors.email}</div>
             )}
          </div>
        </div>
        <div className="form-group">
          <div className="mb-3">
            <div>
              <label className="col-form-label font-14">Password</label>
            </div>
            <input type="password" placeholder="Password"
              {...formik.getFieldProps('password')}
              className={clsx(
                'form-control',
                { 'is-invalid': formik.touched.password && formik.errors.password },
                {
                  'is-valid': formik.touched.password && !formik.errors.password,
                }
              )}
            />
            {formik.touched.password  && formik.errors.password && (
              <div className='error-message p-2'> {formik.errors.password}</div>
             )}
          </div>
        </div>
        <div className="form-group">
          <div className="mb-3">
            <div>
              <label className="col-form-label font-14">Mobile</label>
            </div>
            <PhoneInput
              placeholder="Enter phone number"
              onChange={(e)=>formik.setFieldValue("mobile",e)}/>

           
             {formik.touched.mobile && formik.errors.mobile && (
              <div className='error-message p-2'> {formik.errors.mobile}</div>
             )}
          </div>
        </div>
        <div className="form-group">
          <div >

           

          </div>

        </div>
        <div className="form-group">

          <div className='d-grid mb-10'>
            <button
              type='submit'

              className="btn btn-primary rounded submit"
              disabled={formik.isSubmitting || !formik.isValid}
            >
              {!loading && <span className='indicator-label'>Continue</span>}
              {loading && (
                <span className='indicator-progress' style={{ display: 'block' }}>
                  Please wait...
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
            <div className="flex-layout justify-content-center ">
             Already have an Account,  <Link onClick={enableLogin} className='d-flex flex-row-reverse link ms-1'>Login</Link>
            </div>
           
          </div>
        </div>
      </form>
</div>
</div>
    </>
  )
}
