import React from 'react'
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import {useFormikContext} from 'formik'

const filter = createFilterOptions();

export default function MuiFormikAutoComplete(props) {
    const {options ,selectedValue ,keyName,error,isDisabled} =props;
    const [value, setValue] = React.useState(selectedValue);
    const formikProps = useFormikContext()

    return (
    <div><Autocomplete
    value={value }
    disabled={isDisabled}
    onChange={(event, newValue) => {
      if (typeof newValue === 'string') {
        console.log("newValue in string :::"+JSON.stringify(newValue))
        setValue({
          name: newValue.replace("Add", "").replaceAll("\"", ""),
        });
      } else if (newValue && newValue.inputValue) {
        console.log("newValue in sss:::"+JSON.stringify(newValue))
        // Create a new value from the user input
        setValue({
          name: newValue.inputValue.replace("Add", "").replaceAll("\"", ""),
        });
      } else {
        console.log("newValue :::"+JSON.stringify(newValue))
        console.log("newValue sdsdd :::"+newValue.name.replace("Add", "").replaceAll("\"", ""))
        setValue({id:newValue.id ,name:newValue.name.replace("Add", "").replaceAll("\"", "")});
      }
      formikProps.setFieldValue(keyName,{id:newValue.id ,name:newValue.name.replace("Add", "").replaceAll("\"", "")})
    }}
    filterOptions={(options, params) => {
      const filtered = filter(options, params);

      const { inputValue } = params;
      // Suggest the creation of a new value
      console.log("inputValue ::"+JSON.stringify(inputValue))
      const isExisting = options.some((option) => inputValue === option.name);
      if (inputValue !== '' && !isExisting) {
        filtered.push({
          id:inputValue,
          name: `Add "${inputValue}"`,
        });
      }

      return filtered;
    }}
    selectOnFocus
    clearOnBlur
    handleHomeEndKeys
    id="free-solo-with-text-demo"
    options={options}
    
    getOptionLabel={(option) => {
      // Value selected with enter, right from the input
      if (typeof option === 'string') {
        return option;
      }
      // Add "xxx" option created dynamically
      if (option.inputValue) {
        return option.inputValue;
      }
      // Regular option
      return option.name;
    }}
    renderOption={(props, option) => <li {...props}>{option.name}</li>}
    freeSolo

    renderInput={(params) => (
      <TextField {...params} label="" error={error}/>
    )}
  /></div>
  )
}
