import React from 'react'

function JobDescDetails(props) {
  const {data} =props;
  const AppHTML = () => <div dangerouslySetInnerHTML={{ __html: data.jobDesc }} />;
  return (
    <div><div className='card'>
    <div className='card-body'>
      <p className='title'><h2>{data.jobTag}</h2></p>
      <p className='font-14'><AppHTML/></p>
      
      
    </div>
  </div></div>
  )
}

export default JobDescDetails