import React, { useState, useEffect } from 'react'
import "./header.scss";
import LoginPopup from '../popupModel/LoginPopup';
import RegisterPopup from '../popupModel/RegisterPopup';
import EmployeerRegisterPopup from '../popupModel/EmployeerRegisterPopup';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { useSelector } from 'react-redux';

import { useDispatch } from 'react-redux';
import { ReactComponent as Logo } from '../../assets/svg-icons/logo.svg';
import { logout } from './../../redux-reducers/authStore';
import { Link, useNavigate } from 'react-router-dom';
import ProfileSection from './../../layout/DashboardLayout/Header/ProfileSection'
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Offcanvas from 'react-bootstrap/Offcanvas';

import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

export default function Header() {
  const [header, setHeader] = useState("header fixed-top");
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showRegisterModal, setShowRegisterModal] = useState(false);
  const [showEmpRegisterModal, setShowEmpRegisterModal] = useState(false);
  const [whiteHeader, setWhiteHeader] = React.useState(false);
  const isAuthenticated = useSelector((state) => state.authState.isAuthenticated);
  const dispatch = useDispatch()
  let navigate = useNavigate();
  const handleLogout = async () => {
    console.log('Logout');
    dispatch(logout());
    navigate('/home');
  };
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [anchorElS, setAnchorElS] = React.useState(null);
  const openS = Boolean(anchorElS);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClicks = (event) => {
    setAnchorElS(event.currentTarget);
  };
  const handleClose = (link) => {
    setAnchorEl(null);
    navigate(link);
  };
  const handleCloses = (link) => {
    setAnchorElS(null);
    navigate(link);
  };

  const listenScrollEvent = event => {
    if (window.scrollY < 73) {
      setWhiteHeader(false);
      return setHeader("header fixed-top");

    } else if (window.scrollY > 70) {
      setWhiteHeader(true);
      return setHeader("header2 fixed-top");

    }
  };
  const [expand, setExpand] = useState("md")
  useEffect(() => {
    window.addEventListener("scroll", listenScrollEvent);
   // window.addEventListener("resize", handleResize)
    return () => window.removeEventListener("scroll", listenScrollEvent);
  }, []);


  return (
    <>
         <Navbar className={header}key={expand}   expand={expand}   fixed="top" >
            <Container fluid>
              <Navbar.Brand href="#">
               
                {
          isAuthenticated ? (<Navbar.Brand href="/auth/app/dashboard"> <div className='logo-header'><Logo />Connect Nex</div></Navbar.Brand>
          ) : (<Navbar.Brand href="/home"> <div className='logo-header'><Logo />Connect Nex</div></Navbar.Brand>
          )
        }
                </Navbar.Brand>
                <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
             
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto pad-l-190">
            {
              // <Nav.Link href="/">Home</Nav.Link>
            }
           
            <div>
             
              {
                !isAuthenticated ? ( 
                  <NavDropdown
                  title="Jobs"
                  id={`offcanvasNavbarDropdown-expand-${expand}`}
                  className='header-font'
                >
                 
                  <NavDropdown.Item  onClick={() => handleClose("jobs?search=it-jobs")}>IT Jobs</NavDropdown.Item >
                  <NavDropdown.Item  onClick={() => handleClose("jobs?search=backend-jobs")}>Backend Jobs</NavDropdown.Item >
                  <NavDropdown.Item  onClick={() => handleClose("jobs?search=frontend-jobs")}>Front End jobs</NavDropdown.Item >
                  <NavDropdown.Item  onClick={() => handleClose("jobs?search=all-jobs")}>All Jobs</NavDropdown.Item >
                </NavDropdown>) : (
                  <NavDropdown
                  title="Jobs"
                  id={`offcanvasNavbarDropdown-expand-${expand}`}
                  className='header-font'
                >
                    <NavDropdown.Item onClick={() => handleClose("/jobs")}>Recommended jobs</NavDropdown.Item>
                    <NavDropdown.Item onClick={() => handleClose("/auth/app/application-details")}>Application status</NavDropdown.Item>
                    <NavDropdown.Item onClick={() => handleClose("/jobs?search=all-jobs")}>All Jobs</NavDropdown.Item>
                  </NavDropdown>
                )
              }
            </div>
            <NavDropdown
                  title="Service"
                  id={`offcanvasNavbarDropdown-expand-${expand}`}
                   className='header-font'
                >
                   <NavDropdown.Item onClick={() => handleClose("/boost-profile")}>Boost profile</NavDropdown.Item>
                <NavDropdown.Item onClick={() => handleClose("/learn-and-prep")}>Learn and prepare for job</NavDropdown.Item>
                <NavDropdown.Item onClick={() => handleClose("/resume-assist")}>Resume builing Assistance</NavDropdown.Item>
                  </NavDropdown>
            </Nav>
          {
            <Nav>
              {
                isAuthenticated && <><ProfileSection /></>
              }
              {
                !isAuthenticated &&
                <>
                  <div className='pad-lr-10'>
                    <button className='btn-style secondry-color' onClick={() => setShowLoginModal(true)}>
                      Login
                    </button>
                    <LoginPopup
                      show={showLoginModal}
                      onHide={() => setShowLoginModal(false)}
                    />
                    <button className='btn-style secondry-color' onClick={() => setShowRegisterModal(true)}>
                      Register
                    </button>
                    <RegisterPopup
                      show={showRegisterModal}
                      onHide={() => setShowRegisterModal(false)}
                      registerationtype={"Candidate"}
                    />
                  </div>
                  <div className='vl '></div>
                  <button className='btn-style secondry-color' onClick={() => setShowEmpRegisterModal(true)}>
                    Employer Register
                  </button>
                  <EmployeerRegisterPopup
                    show={showEmpRegisterModal}
                    onHide={() => setShowEmpRegisterModal(false)}
                    registerationtype={"employeer"}
                  />
                </>
              }
            </Nav>
          }
        </Navbar.Collapse>

              <Navbar.Offcanvas
                id={`offcanvasNavbar-expand-${expand}`}
                aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                placement="end"
              >
                <Offcanvas.Header closeButton>
                  <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                  {
          isAuthenticated ? (<Navbar.Brand href="/auth/app/dashboard"> <div className='logo-header'><Logo />Connect Nex{expand}</div></Navbar.Brand>
          ) : (<Navbar.Brand href="/home"> <div className='logo-header'><Logo />Connect Nex {expand}</div></Navbar.Brand>
          )
        }
                  </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                  <Nav className="justify-content-end flex-grow-1 pe-3">
                  {
                isAuthenticated && <>
                  <Nav.Link href="/auth/app/dashboard"  className='header-font'>Dashboard</Nav.Link>
                  <Nav.Link href="/auth/app/user-profile"  className='header-font'>View Profile</Nav.Link>
                 
                </>

                  }
                 
                   
             
              {
                !isAuthenticated ? ( 
                  <NavDropdown
                  title="Jobs"
                  id={`offcanvasNavbarDropdown-expand-${expand}`}
                  className='header-font'
                >
                 
                  <NavDropdown.Item  onClick={() => handleClose("jobs?search=it-jobs")}>IT Jobs</NavDropdown.Item >
                  <NavDropdown.Item  onClick={() => handleClose("jobs?search=backend-jobs")}>Backend Jobs</NavDropdown.Item >
                  <NavDropdown.Item  onClick={() => handleClose("jobs?search=frontend-jobs")}>Front End jobs</NavDropdown.Item >
                  <NavDropdown.Item  onClick={() => handleClose("jobs?search=all-jobs")}>All Jobs</NavDropdown.Item >
                </NavDropdown>) : (
                  <NavDropdown
                  title="Jobs"
                  id={`offcanvasNavbarDropdown-expand-${expand}`}
                  className='header-font'
                >
                    <NavDropdown.Item onClick={() => handleClose("/jobs")}>Recommended jobs</NavDropdown.Item>
                    <NavDropdown.Item onClick={() => handleClose("/auth/app/application-details")}>Application status</NavDropdown.Item>
                    <NavDropdown.Item onClick={() => handleClose("/jobs?search=all-jobs")}>All Jobs</NavDropdown.Item>
                  </NavDropdown>
                )
              }
            <NavDropdown
                  title="Service"
                  id={`offcanvasNavbarDropdown-expand-${expand}`}
                   className='header-font'
                >
                   <NavDropdown.Item onClick={() => handleClose("/boost-profile")}>Boost profile</NavDropdown.Item>
                <NavDropdown.Item onClick={() => handleClose("/learn-and-prep")}>Learn and prepare for job</NavDropdown.Item>
                <NavDropdown.Item onClick={() => handleClose("/resume-assist")}>Resume builing Assistance</NavDropdown.Item>
                  </NavDropdown>
                 
                  {
                !isAuthenticated &&
                <>
                  <Nav.Link href="/home"  className='header-font'>Home</Nav.Link>
                  <div className='pad-lr-10'>
                    <Nav.Link  className='header-font' onClick={() => setShowLoginModal(true)}>
                      Login
                    </Nav.Link>
                    <LoginPopup
                      show={showLoginModal}
                      onHide={() => setShowLoginModal(false)}
                    />
                    <Nav.Link  className='header-font' onClick={() => setShowRegisterModal(true)}>
                      Register
                    </Nav.Link >
                    <RegisterPopup
                      show={showRegisterModal}
                      onHide={() => setShowRegisterModal(false)}
                      registerationtype={"Candidate"}
                    />
                     <Nav.Link  className='header-font'  onClick={() => setShowEmpRegisterModal(true)}>
                    Employer Register
                  </Nav.Link >
                  </div>
                 
                 
                  <EmployeerRegisterPopup
                    show={showEmpRegisterModal}
                    onHide={() => setShowEmpRegisterModal(false)}
                    registerationtype={"employeer"}
                  />
                </>
              }
                {
                isAuthenticated && <>
                  <Nav.Link  className='header-font'  onClick={handleLogout}>LogOut</Nav.Link>
                </>

                  }

                  </Nav>
                 
                </Offcanvas.Body>
              </Navbar.Offcanvas>
            </Container>
          </Navbar>
        
      </>
  )
}
