import React,{useEffect} from 'react'
import CardCarousel from '../../components/carousel/CardCarousel'
import CardCategory from '../../components/categoryCard/CardCategory'
import { ReactComponent as YourSvg } from '../../assets/SVG-PL/java-logo-col.svg';
import { ReactComponent as Python } from '../../assets/SVG-PL/python-col.svg';
import { ReactComponent as Php } from '../../assets/SVG-PL/php-svgrepo-com.svg';
import { ReactComponent as Javascript } from '../../assets/SVG-PL/javascript-svgrepo-com.svg';
import { ReactComponent as ReactLogo } from '../../assets/SVG-PL/react-svgrepo-com.svg';
import { ReactComponent as Angular } from '../../assets/SVG-PL/angular-svgrepo-com.svg';
import { ReactComponent as Dotnet } from '../../assets/SVG-PL/dotnet.svg';
import { ReactComponent as Linux } from '../../assets/SVG-PL/linux-svgrepo-com.svg';
import { useState } from 'react';

function CardCategoryLayout() {
    const [width, setWidth] = React.useState(window.innerWidth);
    const [cardNo,setCardNo] = useState();
    const sm= 576;
    const md= 768;
    const lg= 992;
    const xl= 1200;
    const xxl= 1400;
    useEffect(() => {
        updateWidthAndHeight();
        window.addEventListener("resize", updateWidthAndHeight);
        return () => window.removeEventListener("resize", updateWidthAndHeight);
    }, [])
    const updateWidthAndHeight = () => {
        
if(window.innerWidth < sm){
    setCardNo(1);
}else if(window.innerWidth > sm && window.innerWidth < md){
    setCardNo(2);
}else if(window.innerWidth > md && window.innerWidth < lg){
    setCardNo(3);
}else if(window.innerWidth > lg && window.innerWidth < xl){
    setCardNo(6);
}else if(window.innerWidth > xl && window.innerWidth < xxl){
    setCardNo(6);
}else if(window.innerWidth > xxl ){
    setCardNo(6);
}
        
        setWidth(window.innerWidth);
      
      };
    return (
        <div>
            <div className="centered">

            <CardCarousel
                            show={cardNo}
                        >
                          <CardCategory logoTech={<YourSvg/>} name="Java"/>
                          <CardCategory logoTech={<Python/>} name="Python"/>
                          <CardCategory logoTech={<Php/>} name="PhP"/>
                          <CardCategory logoTech={<Javascript/>} name="Java Script"/>
                          <CardCategory logoTech={<ReactLogo/>} name="React"/>
                          <CardCategory logoTech={<Angular/>} name="Angular"/>
                          <CardCategory logoTech={<Dotnet/>} name=".net"/>
                          <CardCategory logoTech={<Linux/>} name="Linux"/>
                        </CardCarousel>
               
                
            </div>
        </div>
    )
}

export default CardCategoryLayout