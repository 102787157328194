import React from 'react'
import { Formik, Field, Form, ErrorMessage, useFormikContext } from 'formik';
import * as Yup from 'yup';

function ChangePassword() {
  const initValue = {
    oldPassword: "",
    newPassword: "",
    confirmPassword: ""
  }

  function validationSchema() {
    return Yup.object().shape({
      oldPassword: Yup.string().required('First name is required'),
      newPassword: Yup.string().required('Last name is required'),
      confirmPassword: Yup.string().required('Email is required')
      });
  }
  const handleSubmit = async () => {

  }
  return (
    <div>
        <div className='pad-10'>

<div className='card-header flex-layout justify-content-between'>
  <div className='align-items-start flex-column'>
    Change Password
    <p className='profile-tagline'>
      Update your Password 
    </p>
  </div>
  <div className='align-items-end flex-column'>
    <button className='btn-style secondry-color'>save</button>
    <button className='btn-style secondry-color'>Reset</button>
  </div>


</div>

<div className='card-body'>
  
{ <Formik
        initialValues={initValue}
        validationSchema={validationSchema} s
        onSubmit={handleSubmit}
        
      >
        {({ errors, touched, resetForm, isValidating, handleChange, setFieldValue }) => (
          <>
          <div className="form-group row pad-5  ">
                <label className="col-xl-3 col-lg-3 col-form-label font-14">Old Password</label>
                <div className="col-lg-9 col-xl-6">

                  <Field
                    name="oldPassword"
                    type="text"

                    className={
                      'form-control' +
                      (errors.oldPassword && touched.oldPassword ? ' is-invalid' : '')
                    }
                  />
                  <ErrorMessage
                    name="oldPassword"
                    component="div"
                    className="invalid-feedback"
                  />
                </div>
              </div>
              <div className="form-group row pad-5  ">
                <label className="col-xl-3 col-lg-3 col-form-label font-14">New Password</label>
                <div className="col-lg-9 col-xl-6">

                  <Field
                    name="newPassword"
                    type="text"

                    className={
                      'form-control' +
                      (errors.newPassword && touched.newPassword ? ' is-invalid' : '')
                    }
                  />
                  <ErrorMessage
                    name="newPassword"
                    component="div"
                    className="invalid-feedback"
                  />
                </div>
              </div>
              <div className="form-group row pad-5  ">
                <label className="col-xl-3 col-lg-3 col-form-label font-14">Confirm Password</label>
                <div className="col-lg-9 col-xl-6">

                  <Field
                    name="confirmPassword"
                    type="text"

                    className={
                      'form-control' +
                      (errors.confirmPassword && touched.confirmPassword ? ' is-invalid' : '')
                    }
                  />
                  <ErrorMessage
                    name="confirmPassword"
                    component="div"
                    className="invalid-feedback"
                  />
                </div>
              </div>
              
              </>
)}
 </Formik>
    }  
</div>
</div>
    </div>
  )
}

export default ChangePassword