import React from 'react'
import Skeleton from '@mui/material/Skeleton';
function JobDescDetailsSkeleton(props) {
  return (
    <div><div className='card'>
    <div className='card-body'>
      <p className='title'><h2> <Skeleton variant="rectangular" height={40} /></h2></p>
      <p className='p-2'> <Skeleton variant="rectangular" height={30} /></p>
      <p className='p-2'> <Skeleton variant="rectangular" height={30} /></p>
      <p className='p-2'> <Skeleton variant="rectangular" height={30} /></p>
      <p className='p-2'> <Skeleton variant="rectangular" height={30} /></p>
      <p className='p-2'> <Skeleton variant="rectangular" height={30} /></p>
      <p className='p-2'> <Skeleton variant="rectangular" height={30} /></p>
      
      
    </div>
  </div></div>
  )
}

export default JobDescDetailsSkeleton