import React, { useEffect, useState, useRef } from 'react'
import { useLazyGetProfileDetailsQuery } from './../../rtk/profile';
import { useSelector } from 'react-redux';
import { Formik, Field, Form, ErrorMessage, useFormikContext } from 'formik';
import * as Yup from 'yup';
import { useSaveProfileMutation } from '../../rtk/profile';
import notify from '../../utils/Utils';
import JSpinner from './../../components/jspinner/JSpinner';
import { updateStoreUser} from './../../redux-reducers/authStore';
import {  useDispatch } from 'react-redux';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import UserProfileSkeleton from './skeleton/UserProfileSkeleton';

function PersonalDetails(props) {
  const {formRef,setIsmodified} = props;
  const userId = useSelector((state) => state.authState.userId);
  const [fetchTrigger, { data, error, isLoading, isFetching }] = useLazyGetProfileDetailsQuery(userId);
  const [detailsLoading, setDetailsLoading] = useState(false);
  const dispatch = useDispatch();
  
  useEffect(() => {
    async function fetchData() {
      const response = await fetchTrigger(userId);
      profileDetails.userId = response.data.user_id;
      profileDetails.firstName = response.data.firstName;
      profileDetails.lastName = response.data.lastName;
      profileDetails.email = response.data.email;
      profileDetails.mobileNumber = response.data.mobileName;
      profileDetails.age = response.data.age;
      profileDetails.gender = response.data.gender;
      setProfileDetailData(profileDetails);
      setLoadContent(true)
      
    }
    fetchData()
    
    }, [])
  const [loadContent, setLoadContent] = useState(false);
  const [profileDetailData, setProfileDetailData] = useState();

  useEffect(() => {
    console.log("formRef changed", formRef.current);
  }, [formRef]);
  const [
    updateSkills,// This is the mutation trigger
    { error: updateProgramError, isLoading: isUpdating, isSuccess }, // This is the destructured mutation result
  ] = useSaveProfileMutation();

  const profileDetails = {
    userId: "",
    firstName: "",
    lastName: "",
    email: "",
    mobileNumber: "",
    userType: ""

  }
  function validationSchema() {
    return Yup.object().shape({
      firstName: Yup.string().required('First name is required'),
      lastName: Yup.string().required('Last name is required'),
      email: Yup.string().required('Email is required'),
      age: Yup.string().required('Age is required'),
      mobileNumber: Yup.string().required('Mobile number is required'),
      lastName: Yup.string().required('Last name is required'),

    });
  }
  const handleSubmit = async () => {
    const requestObject = { data: formRef.current.values, userid: userId }
    setDetailsLoading(true);
    const returned = await updateSkills(requestObject).unwrap().catch(res => console.log("res :::" + JSON.stringify(res)));
    setDetailsLoading(false);
    console.log("formRef before-->"+JSON.stringify(formRef))
    if (error == undefined) {
      setIsmodified(false);
      dispatch(updateStoreUser(formRef.current.values));
    //  notify("success", "Success Message", "Details saved Successfully");
      console.log("formRef after-->"+JSON.stringify(formRef))
    } else {
      notify("danger", "Rrror Message", "Error in saving details");
    }
  }



  return (
    <div className='pad-10'>
      <div className='card-header flex-layout justify-content-between'>
        <div className='align-items-start flex-column'>
          Personal Information
          <p className='profile-tagline'>
            Update your personal informaiton
          </p>
        </div>
        <div className='align-items-end flex-column'>
          
          <button className='btn-style secondry-color' form="form1" disabled={detailsLoading}>
          {!detailsLoading && <span className='indicator-label'>Save</span>}
              {detailsLoading && (
                <span className='indicator-progress' style={{ display: 'block' }}>
                  Please wait...
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}

          </button>

        </div>
      </div>
      {
        !loadContent ? ( <><UserProfileSkeleton />
      </>) : (
         <PersonalDetailsComp profileDetails={profileDetailData} validationSchema={validationSchema} handleSubmit={handleSubmit} formRef={formRef} setIsmodified={setIsmodified}/>
      )}


    </div>
  )
}



export function PersonalDetailsComp(props) {
  const { profileDetails, validationSchema, handleSubmit, formRef,setIsmodified } = props;
  const [phoneNumber, setPhoneNumber] = useState()
  function chane(){
    console.log("In changes  ")
  }
  return (
    <div>{
      <Formik
        initialValues={profileDetails}
        validationSchema={validationSchema} 
        onSubmit={handleSubmit}
        innerRef={formRef}
        onChange={() => {
          console.log('changing');
        }}
      >
        {({ errors, touched, resetForm, isValidating, handleChange, setFieldValue ,values}) => (
          <Form id="form1" onChange={() => {
            setIsmodified(true);
          }}>

            <div className='card-body'>
            <div className="form-group row pad-5  ">
                <label className="col-xl-3 col-lg-3 col-form-label font-14">Email</label>
                <div className="col-lg-9 col-xl-6">

                  <Field
                    name="email"
                    type="text"
                    disabled
                    className={
                      'form-control' +
                      (errors.email && touched.email ? ' is-invalid' : '')
                    }
                  />
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="invalid-feedback"
                  />
                </div>
              </div>
              <div className="form-group row pad-5  ">
                <label className="col-xl-3 col-lg-3 col-form-label font-14">First name</label>
                <div className="col-lg-9 col-xl-6">

                  <Field
                    name="firstName"
                    type="text"
                    
                    className={
                      'form-control' +
                      (errors.firstName && touched.firstName ? ' is-invalid' : '')
                    }
                  />
                  <ErrorMessage
                    name="firstName"
                    component="div"
                    className="invalid-feedback"
                  />
                </div>
              </div>
              <div className="form-group row pad-5  ">
                <label className="col-xl-3 col-lg-3 col-form-label font-14">Last name</label>
                <div className="col-lg-9 col-xl-6">

                  <Field
                    name="lastName"
                    type="text"

                    className={
                      'form-control' +
                      (errors.lastName && touched.lastName ? ' is-invalid' : '')
                    }
                  />
                  <ErrorMessage
                    name="lastName"
                    component="div"
                    className="invalid-feedback"
                  />
                </div>
              </div>
             
              <div className="form-group row pad-5  ">
                <label className="col-xl-3 col-lg-3 col-form-label font-14">Mobile number</label>
                <div className="col-lg-9 col-xl-6">
                

                  <Field name="mobileNumber">
             {({
               field, // { name, value, onChange, onBlur }
               form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
               meta,
             }) => (
              <PhoneInput
              placeholder="Enter phone number"
              value={values.mobileNumber}
              onChange={(e)=>setFieldValue("mobileNumber",e)}/>
             
             )}
           </Field>
                  
                  <ErrorMessage
                    name="mobileNumber"
                    component="div"
                    className="invalid-feedback"
                  />
                </div>
              </div>
              <div className="form-group row pad-5  ">
                <label className="col-xl-3 col-lg-3 col-form-label font-14">Age</label>
                <div className="col-lg-9 col-xl-6">

                  <Field
                    name="age"
                    type="text"

                    className={
                      'form-control' +
                      (errors.age && touched.age ? ' is-invalid' : '')
                    }
                  />
                  <ErrorMessage
                    name="age"
                    component="div"
                    className="invalid-feedback"
                  />
                </div>
              </div>

              <div className="form-group row pad-5">
                <label className="col-xl-3 col-lg-3 col-form-label font-12">Gender</label>
                <div className="col-lg-9 col-xl-6">
                  <div className="form-check form-check-inline">

                    <Field className={"form-check-input font-14" +
                      (errors.gender && touched.gender ? ' is-invalid' : '')}
                      name="gender"
                      type="radio"
                      value="male"
                    /><label className="form-check-label" htmlFor="inlineRadio1">Male</label>
                  </div>
                  <div className="form-check form-check-inline">

                    <Field className={"form-check-input font-14" +
                      (errors.gender && touched.gender ? ' is-invalid' : '')}
                      name="gender"
                      type="radio"
                      value="female"
                    />
                    <label className="form-check-label" htmlFor="inlineRadio2">Female</label>
                  </div>
                  <ErrorMessage
                    name="gender"
                    component="div"
                    className="invalid-feedback"
                  />
                </div>
              </div>

            </div>
          </Form>
        )}
      </Formik>
    }</div>
  )
}


export default PersonalDetails