import React from 'react'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Grid } from '@mui/material';
import { gridSpacing } from './../../redux-reducers/constant';
import TotalIncomeDarkCard from './../dashboard/TotalIncomeDarkCard';
import JobSqCard from '../../components/jobSquareCard/JobSqCard';
import TotalIncomeCard from './../ui-component/cards/Skeleton/TotalIncomeCard';
import { Link } from 'react-router-dom';

export default function MultiCarousel(props) {
  const {jobList,isLoading} = props;

  
    const responsive = {
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 3,
          slidesToSlide: 3 // optional, default to 1.
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 2,
          slidesToSlide: 2 // optional, default to 1.
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1,
          slidesToSlide: 3 // optional, default to 1.
          
        }
      };
  return (
    <div>
        {isLoading ? (
          <TotalIncomeCard />
        ) :(
       <Carousel responsive={responsive}
  swipeable={true}
  draggable={true}
  showDots={false}
  ssr={true} // means to render carousel on server-side.
  infinite={true}
  autoPlay={true}
  autoPlaySpeed={10000}
  keyBoardControl={true}
 customTransition="all 1"
  transitionDuration={500}
  //containerClass="carousel-container"
  //removeArrowOnDeviceType={["tablet", "mobile"]}
  deviceType={"desktop"}
  arrows={true}
  //dotListClass="custom-dot-list-style"
  //itemClass="carousel-item-padding-40-px"
>
{jobList && jobList.map((job, index) => (
            <div> 
            <>
            <Link to ={'/job/'+job.jobId} className='remove-underline'>
          <JobSqCard isLoading={false} data={job} />
          </Link>
            </></div>
            
          ))}
          

   
     
  


  
            
</Carousel>
  )
}
    </div>
  )
}
