import React from 'react'

import AutoCompleteLocationBanner from '../autoComplete/AutoCompleteLocationBanner';
import AutoCompleteSearchBanner from '../autoComplete/AutoCompleteSearchBanner';
import { increment, searchQuery ,bannerSearch} from '../../redux-reducers/global';
import { useSelector, useDispatch } from 'react-redux';

import './SeachBanner.scss';
import { useState } from 'react';
import notify from '../../utils/Utils';
import { useNavigate } from "react-router-dom";
import ReactGA from "react-ga4";
import MuiAutoComplete from './../../components/mui-autocomplete/index';
import { useGetSearchCriteriaQuery } from './../../rtk/Search';

function SearchBanner() {
  const [searchQuery1,setSearchQuery1] =useState();
  const searchObj = {
    searchQuery: {},
    expDetails: {}
  }
  const [location,setLocation] =useState();
  const [expDetails,setExpDetails] =useState();
  const { data, error, isLoading } = useGetSearchCriteriaQuery();
  const dispatch = useDispatch();
  const onChange = e => {
    const value = { "searchValue": e.currentTarget.value, "catagory": "Exp" }
    setExpDetails({"type":"exp","value":value})
  }
  const [searchLocalState, setSearchLocalState] = useState({});
  let navigate = useNavigate();
  const submitButton = e => {
    
    if(searchQuery1 == undefined && location == undefined && expDetails == undefined){
      notify("danger","error messae","no value to search");
    }
    /*
    searchObj.expDetails=expDetails;
    searchObj.searchQuery=searchQuery;
    dispatch(bannerSearch(searchObj));

    if (userInput != searchLocalState.searchValue) {
      const value = { "searchValue": userInput, "catagory": 'skill' }
      setSearchLocalState(value);
    }*/
    let uri = window.location.pathname.split("/").pop();
    dispatch(searchQuery(searchQuery1))
    ReactGA.event({
      category: 'Search job Banner',
      action: 'Action : Search job Banner for '+searchQuery1,
      label:'Search job Banner for '+searchQuery1
    });
    if(uri != 'jobs'){
      navigate("/jobs");
    }
    //e.preventDefault();
    //navigate("/jobs");
  }
 

  return (
    <div>
       <div className='searchBanner'>
        <h2 className='align-center'>Find Jobs</h2>
        <p className='align-center' >Search your career opportunity through 12,800 jobs</p>
        <div className='pad-t-15'>
          <div className="input-wrapper-ban">
        {
        data && <MuiAutoComplete data={data} setSearchQuery={setSearchQuery1} type={"searchBanner"}/>
      }
       </div>
        </div>
      </div></div>

  )
}

export default SearchBanner;