import React from 'react'

function JobSkills(props) {
  const{data} =props;
  return (
    <div><div className='card'>
    <div className='card-body'>
      <p className="card-text">

        <span>Skills :</span>

        {
      data.skills.map(
  (item, i, arr) => <span className='skill-bg mar-l-5'>{item.skillName} </span>)

}

      </p>

    </div>
  </div></div>
  )
}

export default JobSkills