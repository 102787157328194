import React from 'react'

function OtherDetails(props) {
  const {data} =props;
  return (
    <div><div className='card'>
    <div className='card-body'>

      <label className=" col-form-label title">No. of opening :


        <span className='font-14'> {data.noOfOpening}</span>
      </label>
      <label className=" col-form-label title">Time prefernce  :


        <span className='font-14'> {data.jobReqTimePeriod}</span>
      </label>


    </div>
  </div></div>
  )
}

export default OtherDetails