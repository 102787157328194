import React, { useEffect, useState } from 'react'
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState, convertToRaw, ContentState,convertFromHTML } from 'draft-js';
import { convertToHTML } from 'draft-convert';
import htmlToDraft from 'html-to-draftjs';
import draftToHtml from 'draftjs-to-html';
import { Formik, Field, Form, ErrorMessage,useFormikContext } from 'formik';
import * as Yup from 'yup';
import { stepperDetails } from '../../redux-reducers/global';
import { useSelector, useDispatch } from 'react-redux';


function JobDetails(props) {

  const { jobDetailsState, setJobDetailsState,jobDetailsRef } = props;
const [editorState1,setEditorState1] =useState();
   const html = jobDetailsState.JobDescText;
   const blocksFromHTML = convertFromHTML(html)
   const _contentState = ContentState.createFromBlockArray(blocksFromHTML)

const raw = convertToRaw(_contentState)
const [contentState, setContentState] = useState(raw) 
const [convertedContent, setConvertedContent] = useState() 
const dispatch = useDispatch();
function validationSchema() {
  return Yup.object().shape({
    jobTag: Yup.string().required('Job Tag is required'),
           
    JobDescText: Yup.string().required('Job Desc is required'),
   
  });
}


const handleEditorChange = (state) => {
  setContentState(state);
  convertContentToHTML(state);
 
}

useEffect(() => {
  setJobDetailsState(() => {
    return {
      ...jobDetailsState,
      ['jobDesc']: convertedContent
    };
  });
}, [convertedContent])

const convertContentToHTML = (state) => {
  console.log("state ::"+JSON.stringify(state))
  let currentContentAsHTML = convertToHTML(state.getCurrentContent());
  setConvertedContent(currentContentAsHTML);
  setJobDetailsState(() => {
    return {
      ...jobDetailsState,
      ['jobDesc']: convertedContent
    };
  });
}
const handleChange = (event) => {
  const { name, value } = event.target;
  setJobDetailsState(() => {
    return {
      ...jobDetailsState,
      [name]: value
    };
  });
}
function handleSubmit(data) {
  console.log("IN handleSubmit "+JSON.stringify(data))
  setJobDetailsState(data);
  dispatch(stepperDetails({"presentStep":1,"toStep":2,"step1Data":true}))
  
}
console.log()
  return (
    <div className='pad-t-15'>
<Formik
          initialValues={jobDetailsState}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          
        >
           {({ errors, touched, resetForm,isValidating,handleChange,setFieldValue  }) => (
            <Form>
      <div className='card '>
        <div className='card-body'>
          <div className='card-header flex-layout justify-content-between'>
            <div className='align-items-start flex-column'>
              Describe the job

            </div>

          </div>

          <div className='pad-t-10'>
            <div className="form-group row pad-5  ">
              <label className="col-xl-3 col-lg-3 col-form-label font-14">Job tagline </label>
              <div className="col-lg-9 col-xl-6">

                < Field as="textarea" 
                 className={
                  'form-control form-control-lg form-control-solid font-14' +
                  (errors.jobTag && touched.jobTag ? ' is-invalid' : '')
                }
                id="exampleFormControlTextarea1" rows="2"
                 type="text"
                  name="jobTag"
                  
                ></Field>
                 {errors.jobTag  ? (
          <div className='danger-font'>{errors.jobTag}</div>
         ) : null}
              </div>
             
            </div>
          </div>

          <div className='pad-t-10'>
            <div className="form-group row pad-5  ">
              <label className="col-xl-3 col-lg-3 col-form-label font-14">Job Description </label>
              <div className="col-lg-9 col-xl-6">
             
              
              {
                /*
                <Editor
          onChange={e=>setFieldValue("JobDescText",jobDetailsState.jobDesc)}
          wrapperClassName="demo-wrapper"
          editorClassName="demo-editor"
          wrapperStyle={{ width: 600, border: "1px solid black" }}
        />
        */
              }
              
              <Field  component={CustomInputComponent} 
                id="JobDesc"
                name="JobDesc"
                
             defaultContentState={contentState}
           // onContentStateChange={setContentState}
            onEditorStateChange={handleEditorChange}
            onChange={e=>setFieldValue("JobDescText",jobDetailsState.jobDesc)}
/>

               {
                  /*
 <Field  component={CustomInputComponent} 
                id="JobDesc"
                name="JobDesc"
                
             defaultContentState={contentState}
            onContentStateChange={setContentState}
            onEditorStateChange={handleEditorChange}
            onChange={e=>setFieldValue("JobDescText",jobDetailsState.jobDesc)}
/>
                  */
               }
           
           
{errors.JobDesc  ? (
          <div className='danger-font'>{errors.JobDesc}</div>
         ) : null}
                
                
              </div>
            </div>
          </div>

        </div>
      </div>
      
      <button ref={jobDetailsRef} name="subBut" type="submit" style={{display:'none'}}>Submit</button>
<input type="hidden"  name="hiddenErrors" id="hiddenErrors" value={JSON.stringify(errors) }></input>
<input type="hidden"  name="hiddentouched" id="hiddenErrors" value={JSON.stringify(touched) }></input>
 
       </Form>
       )}
   </Formik>
    </div>
  )
}

const CustomInputComponent = ({
  
  field, // { name, value, onChange, onBlur }
  form: { touched, errors,handleChange,form }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  options,
  styles,
  ...props
}
) => (
 

<Editor
                 
                  toolbarClassName="toolbarClassName"
                  wrapperClassName="wrapperClassName"
                  editorClassName="editorClassName"
                  id="JobDesc"
                name="JobDesc"
                  wrapperStyle={{ width: 600, border: "1px solid #ced4da" ,borderRadius:"20px 1px solid black"}}
                  {...field} {...props}
                 
                />
)



export default JobDetails;