import React,{useState,useEffect} from 'react'
import * as Icon from 'react-bootstrap-icons';
import Skeleton from '@mui/material/Skeleton';
function JobHeaderDetailsSkeleton(props) {
 
  return (
    <div className='card'>
              <div className='card-body'>
                <div className='job-info f-left'>
                  <h1 className="card-title"><Skeleton variant="rectangular" height={30} /></h1>
                  <p className='sub-header'><Skeleton variant="rectangular" height={30} /></p>
                  <div className='flex-layout'>
                    <div className=''>
                      <p className='sub-info'>
                      <Skeleton variant="rectangular" height={30} />
                       
                      </p>
                    </div>

                  
                  </div>
                  <div >
                   
                   

                    <div>
                    
                                
                    <Skeleton variant="rectangular" height={30} />
                                



                    </div>
                  </div>
                </div>
                <div className='comp-img f-right'>
                  {/*<img className='image-style' src={comLogo} />*/}

                </div>



              </div>

            </div>
  )
}

export default JobHeaderDetailsSkeleton;