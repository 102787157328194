import { createSlice } from '@reduxjs/toolkit';

export const AuthState = createSlice({
    name: 'AuthState',
    initialState: {
      userId:null,
      firstName :null,
      lastName:null,
      isAuthenticated:false,
      email:null,
      mobileName :null,
      userType:null,
      skills:null,
      isEmailVerified:null,
      emailVCode:null,
      profilePicName:null
     },
    reducers: {
      storeUser: (state, action) => {
        console.log("Auth STore state ::"+JSON.stringify(action.payload))
        // Redux Toolkit allows us to write "mutating" logic in reducers. It
        // doesn't actually mutate the state because it uses the Immer library,
        // which detects changes to a "draft state" and produces a brand new
        // immutable state based off those changes
        state.userId=action.payload.userId;
        state.firstName=action.payload.firstName;
        state.lastName=action.payload.lastName;
        state.email=action.payload.email;
        state.isAuthenticated=true;
        state.mobileName=action.payload.mobileName;
        state.userType=action.payload.userType;
        state.isEmailVerified=action.payload.emailVerified;
        state.emailVCode=action.payload.emailVerifiedCode;
        state.profilePicName=action.payload.profilePicName;
        
       // localStorage.setItem('authData', JSON.stringify(action.payload.data));
        //state.value += 1
      },
      updateStoreUser: (state, action) => {
        console.log("updateStoreUser Auth STore state ::"+JSON.stringify(action.payload))
        // Redux Toolkit allows us to write "mutating" logic in reducers. It
        // doesn't actually mutate the state because it uses the Immer library,
        // which detects changes to a "draft state" and produces a brand new
        // immutable state based off those changes
        state.firstName=action.payload.firstName;
        state.lastName=action.payload.lastName;
        state.email=action.payload.email;
        state.mobileName=action.payload.mobileNumber;
        
       // localStorage.setItem('authData', JSON.stringify(action.payload.data));
        //state.value += 1
      }, updateUserProfilePic: (state, action) => {
        console.log("updateUserProfilePic Auth STore state ::"+JSON.stringify(action.payload))
        // Redux Toolkit allows us to write "mutating" logic in reducers. It
        // doesn't actually mutate the state because it uses the Immer library,
        // which detects changes to a "draft state" and produces a brand new
        // immutable state based off those changes
        state.profilePicName=action.payload;
       
      },

      updateSkills: (state, action) => {
        console.log("Auth STore updateSkills ::"+action.payload)
        // Redux Toolkit allows us to write "mutating" logic in reducers. It
        // doesn't actually mutate the state because it uses the Immer library,
        // which detects changes to a "draft state" and produces a brand new
        // immutable state based off those changes
        state.skills=action.payload;
        },
      updateEmailVerificationStatus: (state, action) => {
          console.log("Auth STore updateEmailVerificationStatus ::"+action.payload)
          // Redux Toolkit allows us to write "mutating" logic in reducers. It
          // doesn't actually mutate the state because it uses the Immer library,
          // which detects changes to a "draft state" and produces a brand new
          // immutable state based off those changes
          state.emailVerified=action.payload;
          },
      logout : (state, action) => {
        console.log("Auth STore LOgout  ::")
        state.userId=null;
        state.firstName=null;
        state.lastName=null;
        state.isAuthenticated=false;
        state.mobileName=null;
        state.userType=null;
        state.skills =null;
        state.isEmailVerified=null;
      }
      
    },
  })
  
  // Action creators are generated for each case reducer function
  export const { storeUser,logout,updateSkills ,updateEmailVerificationStatus,updateStoreUser,updateUserProfilePic} = AuthState.actions
  
  export default AuthState.reducer;