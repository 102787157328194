import React, { useState, useEffect } from 'react'
import { useFormik, Form, Field, Formik, useField, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import MuiFormikAutoComplete from './../ui-component/FormFields/MuiFormikAutoComplete';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import Modal from 'react-bootstrap/Modal';
import { noticePeriodType } from './../../utils/Constant';
import { duplicateEmploymentType } from './../../utils/Constant';
import * as Icon from 'react-bootstrap-icons';
import clsx from 'clsx';
import { Divider } from '@mui/material';
import { useLazyGetDataQuery } from './../../rtk/MetaData';
import { useSelector } from 'react-redux';
import { useSaveEmploymentDetailsMutation } from './../../rtk/profile';
import { useLazyGetEmployementDetailsQuery } from './../../rtk/profile';
import JSpinner from './../../components/jspinner/JSpinner';
import { monthNames } from './../../utils/Constant';
import notify from '../../utils/Utils';
import CardSkeleton from './skeleton/CardSkeleton';
export default function Employment(props) {
  const {setIsmodified} =props;
  const userId = useSelector((state) => state.authState.userId);
  const [showModal, setShowModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  let [fetchTrigger, { data, error, isLoading, isFetching }] = useLazyGetDataQuery(userId);
  
  const [fetchEmploymentDetails, { data: educationData, error: eucationError, isLoading: educationIsLoading }] = useLazyGetEmployementDetailsQuery(userId);
 
  const [companyData, setCompanyData] = useState();
  const [designationData, setDesignationData] = useState();
  const [deleteEmploymentList, setDeleteEmploymentList] = useState([]);
  const [employmentLoading, setEmploymentLoading] = useState(true);
  const [employmentDetailsList, setEmploymentDetailsList] = useState([]);
  
  const [
    updateEmployment, // This is the mutation trigger
    { error: updateProgramError, isLoading: isUpdating }, // This is the destructured mutation result
  ] = useSaveEmploymentDetailsMutation();

  async function fetchEducationDataList() {
    const response = await fetchEmploymentDetails();
    setEmploymentDetailsList(response.data);
    setEmploymentLoading(false);
  }
  async function fetchCompanyData() {
    const response = await fetchTrigger("company");
    setCompanyData(response.data);
  }
  async function fetchDesignationData() {
    const response = await fetchTrigger("designation");
    setDesignationData(response.data);
  }


  useEffect(() => {
    fetchEducationDataList();
    fetchCompanyData();
    fetchDesignationData();
    }, [])


  const [initState, setInitState] = useState({
    employmentId:0,
    currentjob: '',
    companyName: null,
    desgnation:null,
    employmentType: '',
    employmentFrom: '',
    employmentTo: '',
    jobProfile: '',
    noticePeriod: ''
  });


  function handelAddEducation() {
    setShowModal(true);
    setInitState({
      employmentId:0,
      currentjob: '',
      companyName: null,
      desgnation:null,
      employmentType: '',
      employmentFrom: '',
      employmentTo: '',
      jobProfile: '',
      noticePeriod: ''
    })
    setIsEdit(false);
  }

  function hideModel() {
    setIsEdit(false);
    setShowModal(false);
  }

  function handleEditEducation(index) {
    const toEditEmployment = employmentDetailsList.filter(a =>
      a.companyName.id === index
    );
    setInitState(toEditEmployment[0]);
    setIsEdit(true);
    setShowModal(true);
  }
 
  function handledeleteEductaion(index) {
    setEmploymentDetailsList(employmentDetailsList.filter(a =>
      a.companyName.id !== index
    ));


    const eduList =employmentDetailsList.filter(a =>
      a.companyName.id === index
    )[0];
    setDeleteEmploymentList([...deleteEmploymentList,eduList.employmentId])
    

  }
  
  const saveEducationDetails = async () => {
    var requestObject = { data: {employmentDetails: employmentDetailsList,deleteDetails: deleteEmploymentList}, userid: userId }
    setLoading(true);
    var returned = await updateEmployment(requestObject).unwrap().catch(res => error = res);
    setLoading(false);
    if (error == undefined) {
     // notify("success", "Success Message", "Details saved Successfully");
    } else {
      notify("danger", "Rrror Message", "Error in saving details");
    }
    setIsmodified(false);
  }
  return (
    <>
      <div className='pad-10'>
        <div className='card-header flex-layout justify-content-between'>
          <div className='align-items-start flex-column'>
            Employment Information
            <p className='profile-tagline'>
              Update your Employment informaiton
            </p>
          </div>
          <div className='align-items-end flex-column'>
            {
              employmentDetailsList.length != 0 && 
              <>
              <button className='btn-style secondry-color' onClick={handelAddEducation}>Add Employment Details</button>
             
              </>
            }
             <button className='btn-style secondry-color' type="submit" onClick={saveEducationDetails} >
            {!loading && <span className='indicator-label'>Save</span>}
              {loading && (
                <span className='indicator-progress' style={{ display: 'block' }}>
                  Please wait...
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}

            </button>
            
          </div>
        </div>
      </div>
      <EmploymentComp show={showModal} onHide={hideModel} employmentdetailslist={employmentDetailsList} companydata={companyData} designationdata={designationData} setemploymentdetailslist={setEmploymentDetailsList} isedit={isEdit} initValues={initState} setInitState={setInitState} setIsmodified={setIsmodified} />
      {
      employmentLoading ? ( <><CardSkeleton/>
    </>) : (
      employmentDetailsList.length != 0 ? ( employmentDetailsList.map((row, index) => (
        <>
          <div className='card no-border no-box-shadow' >
            <div className='card-header flex-layout justify-content-between'>
              <b>
                {
                  row.desgnation.name + " in " + row.companyName.name
                }
              </b>
              <div>
                <button className='mar-l-5 no-border no-backgroud' onClick={() => handleEditEducation(row.companyName.id)} ><Icon.PencilSquare size={25} /> </button>
                <button className='mar-l-5 no-border no-backgroud' onClick={() => handledeleteEductaion(row.companyName.id)}><Icon.ArchiveFill size={25} /></button>
              </div>
            </div>
            <div className='card-body '>
            
              <div>{row.employmentType} |{monthNames[(row.employmentFrom.split("-")[0])-1] +" "+row.employmentFrom.split("-")[2]} - 
            {row.employmentTo == 'Till date' ?(" Present"):( monthNames[(row.employmentTo.split("-")[0])-1 ] +" "+row.employmentTo.split("-")[2] )}</div>
              <div>{noticePeriodType.map((constantData) => (
                <>{constantData.id == row.noticePeriod && "Notice in "+constantData.name} </>
              ))}</div>
              <div>{row.jobProfile}</div>
            </div>
          </div>
        </>

      ) )) : (
        <div
        style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '30vh',
        }}
    >
       <b>Nothing to display. <button className='btn-style secondry-color' onClick={handelAddEducation}>Add Employment Details</button></b>
    </div>
      ))
      }
      <div>
      </div>
    </>

  )
}

export function EmploymentComp(props) {
  const [value, setValue] = React.useState(null);
  const [defaultDateValue, setDefaultDateValue] = React.useState();
  const { employmentdetailslist, setemploymentdetailslist, isedit, initValues, onHide, setInitState,companydata,designationdata ,setIsmodified} = props;
  const [errorMessage, setErrorMessage] = React.useState();
  const [isCurrentEmployment, setIsCurrentEmployment] = React.useState();
  const companyOption = companydata;
  const designationOption = designationdata;
  const dateValidation = (to, from,ctx) => {
    console.log("to ::"+to + " from:"+from )
    let errors = "employmentTo";
    let isError = false;
    let msg=null;
    if(from == undefined){
      msg='Please select from Date' ;
    // isError=true;
    let ve = ctx.createError({ message: 'Please select from Date' });
      ve.errors = errors; // these are not shown
      console.log("ve.errors ::"+JSON.stringify(ve))
      return ve;
    }
    let fromDate,toDate,ve;
    if(!isError){
      fromDate= new Date(from);
      toDate= new Date(to);
    }
    if(fromDate > new Date()){
      msg='To date should be less then Today Date' ;
      // isError=true;
      let ve = ctx.createError({ message: 'To date should be less then from date' });
      ve.errors = errors; // these are not shown
      console.log("ve.errors ::"+JSON.stringify(ve))
      return ve;
    }
    return true;
  }
  useEffect(() => {
    setIsCurrentEmployment(initValues.currentjob);
   }, [onHide,isedit])

  const eductaionValidation = Yup.object().shape({
    currentjob: Yup.string().required('Please select If its current job not'),
    companyName: Yup.mixed().required('Please select Company name'),
    desgnation: Yup.mixed().required("Please enter designation"),
    employmentType: Yup.string().required('Please select employment Type'),
    employmentFrom: Yup.string().required('Please Enter valid date'),
    employmentTo: Yup.string().required('Please Enter valid date').
    test("date test",(employmentFrom,employmentTo) =>{
      console.log("courseDurationFrom::"+employmentFrom +" courseDurationTo::"+employmentTo.parent.employmentFrom)
      return dateValidation(employmentFrom,employmentTo.parent.employmentFrom,employmentTo);  
   })
    ,
    jobProfile: Yup.string().required('Please Enter valid Job Profile'),
    noticePeriod: Yup.string().required('Please Enter valid Job Profile')
  });





  const handleCurrentEmployement = (event, setFieldValue) => {
    setIsCurrentEmployment(event.target.value);
    setFieldValue("currentjob", event.target.value)
    if (event.target.value === 'yes') {
      setFieldValue("employmentTo", "Till date")
    } else {
    }
   }

  return (
    <div> <Modal
      {...props}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
      </Modal.Header>
      <Modal.Body>
        <Formik
          enableReinitialize={true}
          initialValues={initValues}
          validationSchema={eductaionValidation}
          onSubmit={(values, { setSubmitting, setErrors }) => {
            if(isedit){
              var index = -1;
              var filteredObj = employmentdetailslist.find(function(item, i){
                if(item.companyName.id === values.companyName.id){
                  index = i;
                  return i;
                }
              });
              let tempArray =[...employmentdetailslist];
              tempArray[index] = values;
              setemploymentdetailslist(tempArray);
            onHide();
            }else {
            const isExisting = duplicateEmploymentType(employmentdetailslist, values.companyName.id);
            if (isExisting) {
              setemploymentdetailslist([...employmentdetailslist, values])
              onHide();
            } else {
              setErrorMessage("Explicit error")
            }
          }
          setIsmodified(true);
          }}
        >
          {({ errors, touched, resetForm, isValidating, handleChange, setFieldValue }) => (
            <Form>
              <div className='card-header flex-layout justify-content-center'>
                <div className='align-items-start flex-column'>
                  Add Employment Information
                  <p className='profile-tagline'>
                    Update your Employment informaiton
                  </p>
                </div>
                <div className='align-items-end flex-column'>


                </div>
              </div>
              <Divider />

              <div className='card-body'>
                <div className="form-group row pad-5">
                  <label className="col-xl-3 col-lg-3 col-form-label font-14">Is it current Job</label>
                  <div className="col-lg-9 col-xl-6">
                    <div className="form-check form-check-inline">

                      <Field className="form-check-input font-14"
                        name="currentjob"
                        type="radio"
                        value="yes"
                        checked={isCurrentEmployment == 'yes'}
                        onChange={(e) => {
                          handleCurrentEmployement(e,setFieldValue)
                         
                        }}
                      /><label className="form-check-label" htmlFor="inlineRadio1">Yes</label>
                    </div>
                    <div className="form-check form-check-inline">

                      <Field className="form-check-input font-14"
                        name="currentjob"
                        type="radio"
                        value="no"
                        checked={isCurrentEmployment == 'no'}
                        onChange={(e) => {
                          handleCurrentEmployement(e,setFieldValue)
                         
                        }}
                      />
                      <label className="form-check-label" htmlFor="inlineRadio2">No</label>
                    </div>
                    {touched.currentjob && errors.currentjob && (
                      <div className='error-message p-2'> {errors.currentjob}</div>
                    )}
                  </div>

                </div>

                <div className="form-group row pad-5">
                  <label className="col-xl-3 col-lg-3 col-form-label font-14">Employment Type</label>
                  <div className="col-lg-9 col-xl-6">
                    <div className="form-check form-check-inline">

                      <Field className="form-check-input font-14"
                        name="employmentType"
                        type="radio"
                        value="fullTime"
                        onChange={(e) => {
                          setFieldValue("employmentType","fullTime")
                        }
                      }
                      /><label className="form-check-label" htmlFor="inlineRadio1">Full Time</label>
                    </div>
                    <div className="form-check form-check-inline">

                      <Field className="form-check-input font-14"
                        name="employmentType"
                        type="radio"
                        value="partTime"
                        onChange={(e) => {
                          setFieldValue("employmentType","partTime")
                        }
                      }
                      />
                      <label className="form-check-label" htmlFor="inlineRadio2">Part Time</label>
                    </div>
                    <div className="form-check form-check-inline">

                      <Field className="form-check-input font-14"
                        name="employmentType"
                        type="radio"
                        value="freelancer"
                        onChange={(e) => {
                          setFieldValue("employmentType","freelancer")
                        }
                      }
                      />
                      <label className="form-check-label" htmlFor="inlineRadio2">Freelancer</label>
                    </div>
                    <div className="form-check form-check-inline">

                      <Field className="form-check-input font-14"
                        name="employmentType"
                        type="radio"
                        value="internship"
                        onChange={(e) => {
                          setFieldValue("employmentType","internship")
                        }
                      }
                      />
                      <label className="form-check-label" htmlFor="inlineRadio2">Internship</label>
                    </div>
                    {touched.employmentType && errors.employmentType && (
                      <div className='error-message p-2'> {errors.employmentType}</div>
                    )}
        </div>
                </div>
                <div className="form-group row pad-5">
                  <label className="col-xl-3 col-lg-3 col-form-label font-12">Company name</label>
                  <div className="col-lg-9 col-xl-6" >

                    <MuiFormikAutoComplete keyName="companyName" options={companyOption} selectedValue={initValues.companyName} error={errors.companyName} isDisabled={isedit}/>
                    {touched.companyName && errors.companyName && (
                      <div className='error-message p-2'> {errors.companyName}</div>
                    )}

                  </div>
                </div>
                <div className="form-group row pad-5">
                  <label className="col-xl-3 col-lg-3 col-form-label font-12">Designation</label>
                  <div className="col-lg-9 col-xl-6">

                    <MuiFormikAutoComplete keyName="desgnation" options={designationOption} selectedValue={initValues.desgnation} error={errors.desgnation} />
                    {touched.desgnation && errors.desgnation && (
                      <div className='error-message p-2'> {errors.desgnation}</div>
                    )}

                  </div>
                </div>
                <div className="form-group row pad-5">
                  <label className="col-xl-3 col-lg-3 col-form-label font-12">Job duration</label>
                  <div className="col-lg-9 col-xl-6">
                    <div className="row">
                      <div className="col">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker className='custom-input' error={touched.employmentFrom} views={['month', 'year']} defaultValue={dayjs(new Date(initValues.employmentFrom))} onChange={(value) => setFieldValue("employmentFrom", new Date(value).getMonth()+1 + "-1-" + new Date(value).getFullYear())} />
                        </LocalizationProvider>
                      </div>
                      <div className="col">
                        {
                          isCurrentEmployment != 'yes' && <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker className='custom-input' error={touched.employmentTo} views={['month', 'year']} defaultValue={dayjs(new Date(initValues.employmentTo))}  onChange={(value) => setFieldValue("employmentTo", new Date(value).getMonth()+1 + "-1-" + new Date(value).getFullYear())} />
                          </LocalizationProvider>
                        }
                      </div>
                    </div>
                    {
                    //touched.employmentFrom && errors.employmentFrom || touched.employmentTo && errors.employmentTo && (
                      <div className='error-message p-2'> {errors.employmentTo}</div>
                    //)
                    }
                  </div>
                </div>
                <div className="form-group row pad-5  ">
                  <label className="col-xl-3 col-lg-3 col-form-label font-14">Job profile </label>
                  <div className="col-lg-9 col-xl-6">
                    < Field as="textarea"
                      className={
                        'form-control form-control-lg form-control-solid font-14' +
                        (errors.jobProfile && touched.jobProfile ? ' is-invalid' : '')
                      }
                      id="exampleFormControlTextarea1" rows="2"
                      type="text"
                      name="jobProfile"
                    ></Field>
                    {touched.jobProfile && errors.jobProfile && (
                      <div className='error-message p-2'> {errors.jobProfile}</div>
                    )}
                  </div>
                </div>
                <div className="form-group row pad-5">
                  <label className="col-xl-3 col-lg-3 col-form-label font-14">Notic period</label>
                  <div className="col-lg-9 col-xl-6">
                    <Field as="select" name="noticePeriod"
                      className={
                        clsx(
                          'form-control',
                          { 'is-invalid': touched.noticePeriod && errors.noticePeriod },
                          {
                            'is-valid': touched.noticePeriod && !errors.noticePeriod,
                          }
                        )}
                      id="exampleFormControlSelect1">
                      <option className='font-14' value="">Select Notice Period</option>
                      {noticePeriodType.map((row, index) => (
                        <option className='font-14' value={row.id}>{row.name}</option>
                      ))}
                    </Field>
                    {touched.noticePeriod && errors.noticePeriod && (
                      <div className='error-message p-2'> {errors.noticePeriod}</div>
                    )}
                  </div>
                </div>
              </div>
              {
                isedit ? ( <> <div className='flex-layout justify-content-center'>
                <button className='btn-style secondry-color' type="submit" >Edit Employment details</button>
              </div></> ) : (  <div className='flex-layout justify-content-center'>
                <button className='btn-style secondry-color' type="submit" >Add Employment details</button>
              </div>)
              }

            
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal></div>
  )
}



