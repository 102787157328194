import React from 'react'

export default function ResumeBuilderAssistance() {
  return (
    <div className='pad-t-70 box-115'>
         <div className='container-fluid'>
      <div className='row'>
    
    <div className='pad-t-15'>
    <h3>Crafting Your Ideal Resume: A Tailored Guide</h3>
    <p>Are you ready to create a resume that opens doors? Whether you're a recent graduate or a seasoned executive, this guide will help you build a resume that showcases your unique value. Let's dive in!</p>    
    <h5 className='pt-2'>Tailoring Your Resume to Your Career Level</h5>
    <p>Your career stage significantly impacts your resume's focus:</p>
    <ul>
        <li><b>Recent Graduates:</b> Highlight your education, internships, and relevant coursework.</li>
        <li><b>Entry-Level Professionals:</b> Emphasize transferable skills and any relevant experience.</li>
        <li><b>Mid-Career Professionals:</b> Showcase your career progression and key achievements.</li>
        <li><b>Senior Executives: </b> Focus on leadership impact and strategic contributions.</li>
        <li><b>Career Changers:</b> Bridge your past experience with your target industry.</li>
        <i><b>Pro Tip: </b></i>No matter your level, quantify your achievements wherever possible!
    </ul>
    <h5 className='pt-4'>Industry-Specific Resume Tailoring</h5>
    <p>Let's look at how to tailor your resume for your target industries:</p>
    <h6>Technology</h6>
    <ul>
        <li>Highlight technical skills and certifications</li>
        <li>Showcase projects with measurable outcomes</li>
        <li>Include a link to your GitHub or portfolio</li>
    </ul>

    <h6>Finance</h6>
    <ul>
        <li>Emphasize analytical skills and financial software proficiency</li>
        <li>Highlight any relevant licenses or certifications (e.g., CFA, CPA)</li>
        <li>Quantify your impact on financial performance</li>
    </ul>

    <h6>Education</h6>
    <ul>
        <li>Showcase teaching methodologies and student engagement strategies</li>
        <li>Highlight any curriculum development or leadership roles</li>
        <li>Include relevant certifications and continued learning</li>
    </ul>

<h5 className='pt-2'>Showcasing Your Key Skills</h5>
<p>Your skills are your secret weapon. Here's how to make them shine:</p>
<ul>
    <li> <b>Technical Skills:</b> List relevant programming languages, software, or tools.</li>
    <li> <b>Soft Skills:</b> Weave these into your experience descriptions (e.g., "Led a cross-functional team to deliver project ahead of schedule").</li>
    <li> <b>Industry-Specific Certifications:</b> Create a dedicated section to highlight these.</li>
    <li> <b>Project Management: </b>Use the CAR method (Challenge, Action, Result) to describe your projects.</li>
    <li> <b>Leadership Abilities: </b> Quantify your impact (e.g., "Mentored 5 junior developers, all of whom were promoted within 2 years").</li>
</ul>    
<h5>Tailoring Work Experience to Your Career Stage</h5>
<h6>0-2 Years Experience</h6>
<p>Focus on internships, relevant coursework, and projects. Use action verbs to describe your contributions.</p>

<h6>3-5 Years Experience</h6>
<p>Highlight your growing responsibilities and key achievements. Begin to show career progression.</p>


<h6>6-10 Years Experience</h6>
<p>Emphasize leadership roles, strategic contributions, and measurable impacts on the organization.</p>


<h6>10+ Years Experience</h6>
<p>Focus on high-level achievements, industry influence, and strategic vision. Be selective about earlier roles.</p>


    </div>
    </div>
    </div>
    </div>
  )
}
