import { Store } from 'react-notifications-component';

function notify  (type,title,message) {
  // https://github.com/teodosii/react-notifications-component
    Store.addNotification({
      title: title,
      message: message,
      type: type, //success,default,warning,info,danger,custom
      insert: "top",
      // content: (
        // <div>
         //  <div>success</div> <span onClick={this.remove}>undo</span>
         //</div>
       //),
      container: "top-right",
      animationIn: ["animated", "fadeIn"],
      animationOut: ["animated", "fadeOut"],
      dismiss: {
        duration: 5000,
        onScreen: true
      },
      onNotificationRemoval: () => this.remove()
    });
  };

  const remove = () => {
    Store.removeNotification({
    });
  };




  export default notify;