import React,{useState,useEffect} from 'react'
import './CardProfile.css';
import {useSaveProfilePicMutation} from './../../rtk/profile';
import { useSelector } from 'react-redux';
import { updateUserProfilePic} from './../../redux-reducers/authStore';
import {  useDispatch } from 'react-redux';

export default function CardProfile() {
  const [
    uploadProfilePic, // This is the mutation trigger
    { error: updateProgramError, isLoading: isUpdating }, // This is the destructured mutation result
  ] = useSaveProfilePicMutation()
  const userId = useSelector((state) => state.authState.userId);
  const [file, setFile] = useState();
  const [fileName, setFileName] = useState('');
  const [imagePreviewUrl, setImagePreviewUrl] = useState('https://github.com/OlgaKoplik/CodePen/blob/master/profile.jpg?raw=true');
  const [name, setName] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [status, setStatus] = useState('');
  const [active, setActive] = useState('edit');
  const [fileFormate, setFileFormate] = useState(['png','jpg','jpeg']);
  const [loading,setLoading] =useState(false);
  const dispatch = useDispatch();
  const profilePicName = useSelector((state) => state.authState.profilePicName);
  useEffect(() => {
    if(profilePicName !=null && profilePicName.length !=0){
      setImagePreviewUrl(process.env.REACT_APP_HOSTNAME+'/index.php/profile/imageFile?name='+profilePicName)
    }
  }, [])
  const photoUpload = e =>{
    e.preventDefault();
    const reader = new FileReader();
    const file = e.target.files[0];
    setFileName(e.target.files[0].name);
    //console.log("filename ::"+filename)
    reader.onloadend = () => {
      setFile(file);
      setImagePreviewUrl(reader.result);
      
    }
    reader.readAsDataURL(file);
  }
 const editName = e =>{
    const name = e.target.value;
   
  }
  
 const editStatus = e => {
    const status = e.target.value;
    setStatus(status);
   
  }
  
  const handleSubmit= async e =>{
    if(file != undefined){
    e.preventDefault();
    setLoading(true);
    let fName =Math.floor(Math.random()*100000+1)+""+userId+"."+fileName.substring(fileName.lastIndexOf('.') + 1);
    const returned = await uploadProfilePic({ filename : fName,resume :file } ).unwrap();
    dispatch(updateUserProfilePic(fName));
    setLoading(false);
    let activeP = active === 'edit' ? 'profile' : 'edit';
    setActive(activeP);
    }else {
      setErrorMessage("Please select Profile image")
    }
   
  }
  return (
    <div>
       <div>
          {(active === 'edit')?(
            <Edit onSubmit={handleSubmit} loading={loading} errorMessage={errorMessage}>
              <ImgUpload onChange={photoUpload} src={imagePreviewUrl}/>
             
            </Edit>
          ):(
            <Profile 
              onSubmit={handleSubmit} 
              src={imagePreviewUrl} 
              name={name} 
              status={status}/>)}
          
        </div>
    </div>
  )
}

const ImgUpload =(props) =>{
  const {onChange,src} =props;
  return (
    <>
    <label htmlFor="photo-upload" className="custom-file-upload fas">
    <div className="img-wrap img-upload" >
      <img for="photo-upload" src={src}/>
    </div>
    <input id="photo-upload" className="input-file-none" type="file" onChange={onChange}/> 
    
  </label>
  </>
  )
}

const Profile =(props)=>{
const {onSubmit,src,name,status} =props;
  return(
    <div >
    <form onSubmit={onSubmit}>
      <label className="custom-file-upload fas">
        <div className="img-wrap" >
          <img for="photo-upload" src={src}/>
        </div>
      </label>
      <div className="name">{name}</div>
      <div className="status">{status}</div>
      <div className='flex-layout justify-content-center '>
      <button type="submit" className="btn btn-primary rounded" >Edit Profile Pic</button>
      </div>
     
    </form>
  </div>
  )
}

const Edit =(props) =>{
  const {onSubmit,children,loading,errorMessage }=props;
  return (
    <div >
    <form onSubmit={onSubmit}>
        {children}
        <div className='flex-layout justify-content-center '>
        {errorMessage}
        </div>
        <div className='flex-layout justify-content-center '>
      <button type="submit" className="btn btn-primary rounded " >
      {!loading && <span className='indicator-label'>Save</span>}
      {loading && (
                <span className='indicator-progress' style={{ display: 'block' }}>
                  Please wait...
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
      </button>
      </div>
    </form>
  </div>
  )
}