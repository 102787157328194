
import MasterLayout from './layout/MasterLayout';

function App() {
  
  return (
    <div >
    <MasterLayout/>
  </div>
  );
}

export default App;
