import React from 'react'
import Footer from './../../components/footer/Footer'
function TermsAndCond() {
  return (
    <>
        <div className='pad-t-70 box-115'>
    <div className='container-fluid'>
      <div className='row'>
    
    <div className='pad-t-15'>
    <h4>Understanding user Terms And Condition</h4>
    <div className='pad-t-15'>
    <h5>Privacy Reminder</h5>
    <p className="card-text">Protect your personal information by never providing credit card,
         bank account numbers or any other 
        personal information open to misuse, to prospective employers</p>
        </div>
    </div>
    <div className='pad-t-15'>
    <h5>Registration</h5>
    <p className="card-text">
        <li>Registration is not required to use most of the functions of the Site or Mobile Application
             or to access most of the Services. However, personal information are captured in order to 
             apply to a job posting or registration is required to redeem. This is so that we can provide
              you with easy access to print your delivery orders,
             view your past activities and modify your preferences</li>
        <li>If you are discovered to have registered multiple User Accounts using a
             single postal mailing address/ EMail/ Mobile to abuse the Site or Mobile Application or system, 
            they will be deleted automatically without prior notification</li>

        <li>Each person is permitted to register only once, upon which a User Account will be created</li>
        <li>If you are under 18 years of age, please seek parental consent before registering.</li>
        <li>Accuity reserves the right, at its sole discretion, to refuse or revoke registration to any applicant,
             and we are not obliged to give a reason for doing so.</li>
        <li>You warrant that your registration details, including but not limited to your name, 
            mobile number, email address and date of birth, are true and accurate at all times and 
            that you shall not impersonate any person or entity. You will maintain and promptly 
            update the information in your User Account to keep it true, accurate, current and complete.
             Our Privacy Policy shall apply in respect of any such information provided by you</li>
        <li>Once registered, you shall be the sole user and operator of your User Account</li>
        <li>
         All User Accounts must be registered with a valid email address that you access regularly, 
        in order that, among other things, moderation emails 
        (where required, including notification emails in respect of registration) and notification 
        emails in respect of redemptions made by you may be sent to you. Any User Accounts which 
        have been registered with temporary email addresses may be closed without notice. 
        We may require Users to re-validate their 
        User Accounts if we believe that they have been registered with an invalid email address
        </li>
       <li>If you use multiple logins for the purpose of disrupting a community or annoying other users,
         you may have action taken against you,
         including but not limited to suspension of any or all of your User Accounts.</li>
             </p>
             </div>

             <div className='pad-t-15'>
    <h5>Site Restrictions</h5>
    <p className="card-text">
    Users shall not use any Accuity Site in order to host, display, upload, modify, publish, transmit, update, distribute, share, store or destroy material, including without limitation Monster Content,
        <li>in violation of any applicable law or regulation</li>
        <li>in a manner that will infringe the copyright, trademark, trade secret or other intellectual property or proprietary rights of others or violate the privacy, publicity or other personal rights of others</li>
        <li>that belongs to another person and to which the user does not have any right to,</li>
        <li>deceives or misleads the addressee about the origin of such message or communicates any information which is grossly offensive or menacing in nature,</li>
        <li>impersonate another person or entity,</li>
       <li>contains software viruses or any other computer code, files or programs designed to interrupt, destroy or limit the functionality of Accuity computer systems or site or Monster’s users, customer’s computer systems or site</li>
       <li>accessing data not intended for such user or logging into a server or account which the user is not authorized to access</li>
       <li>attempting to probe, scan or test the vulnerability of a system or network or to breach security or authentication measures without proper authorization</li>
       <li>Forging any TCP/IP packet header or any part of the header information in any e-mail or newsgroup posting. Violations of system or network security may result in civil or criminal liability. The Company will investigate occurrences which may involve such violations and may involve, and cooperate with, law enforcement authorities in prosecuting users who are involved in such violations.</li>

        </p>
        </div>

        <div className='pad-t-15'>
    <h5>Contests</h5>
    <p className="card-text">
        <li>The Contest is organised by AccuitySavvy.</li>
        <li>Participation in the Contest is subject to these Terms and Conditions 
            (“these Terms and Conditions“).
9.3 By participating in the Contest, each participant accepts and agrees to be bound by 
these Terms and Conditions, and any amendments, additions, replacements, variations and 
modifications hereto, which shall be final and binding in all respects. Accuity reserves 
the right at its sole discretion to amend, replace, vary and/or modify these 
Terms and Conditions without notice to the participant. Any non-compliance with or breach 
of any of these Terms and Conditions may disqualify a participant at any stage of the Contest,
 and any prizes won may be forfeited, withheld, withdrawn or reclaimed.
</li>
    </p>
    </div>

    <div className='pad-t-15'>
    <h5>Your Responsibility</h5>
    <p className="card-text">
    You shall use the Services subject to the following conditions:
    <li>you agree to abide by and comply with these Terms and Conditions at all times</li>
    <li>you agree to abide by and comply with the Terms of Use of the Site and Accuity</li>
    <li>you agree to submit a copy of your NRIC/FIN for verification purposes if requested by us;</li>
    <li>you have provided all information we require in connection with your registration, and such information is accurate and complete</li>
    <li>You acknowledge that incomplete or erroneous information provided by you may result in loss or delay in the delivery of your product, sample or voucher to your mailing address.</li>

    </p>
    </div>

    <div className='pad-t-15'>
    <h5>Our Responsibility</h5>
    <p className="card-text">
   
    <li>We will notify you of the charges you have to pay for your use of the Services</li>
    <li>In providing the Services, we act as an independent contractor and we are not an agent of any Merchant(s) or act in any other capacity unless otherwise specifically stated.</li>
    
    </p>
    </div>
    </div>

    </div>
    </div>
    
    </>
  )
}

export default TermsAndCond;