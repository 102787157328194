import React from 'react'
import { Link } from 'react-router-dom'
import * as Icon from 'react-bootstrap-icons';
import { ReactComponent as Boost } from './../../assets/SVG-PL/rocket-svgrepo-com.svg';
import TotalIncomeCard from './../../layout/ui-component/cards/Skeleton/TotalIncomeCard';
export default function BoostProfile(props) {
    const {isLoading}=props;

  return (
    <>
    {
      isLoading ? (
      <TotalIncomeCard />
    ) : (
    <Link to="/boost-profile" className='remove-underline'>
    <div><div className="card bg-boost" >
    
    <div className="card-body text-center">
    <div className='svg'><Boost/></div>
      <h5 className="card-title">{"Boost you profile"} </h5>
      {/*<p className="card-text">130+ jobs</p>*/}
      <div >Build visibility for yourself</div>
      </div>
  </div></div>
  </Link>
    )
  }
  </>
    
  )
}
