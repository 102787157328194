import React from 'react'
import { useSelector,useDispatch } from 'react-redux';

import { useGetJobTypesQuery } from '../../rtk/jobs.js';
import { jobsFilter } from '../../redux-reducers/global'
import JSpinner from '../jspinner/JSpinner.jsx';
import SideFilterMenuSkeleton from './SideFilterMenuSkeleton.jsx';
function SideFilterMenu() {

 // const { data, error, isLoading } = useGetJobFiltersQuery();
  const { data, error, isLoading } = useGetJobTypesQuery({skip:true});
 
  return (
    
      <div>
        <div className="card secondry-color" >

        {error ? (
          <div className='pad-t-30'> Oh no, there was an error</div>
      ) : isLoading ? (
       <>
        <SideFilterMenuSkeleton />
        
       </>
      ) : data ? (
        <>
          {
          
              <div className="card-body">
            <h6>Employment Type</h6>
            <div className='list-group'>
            {
            data.JobType.map((jobsFilterItem, index) => (
              <DisplayItem value={jobsFilterItem.jobType} count={jobsFilterItem.count} />
            ))
          }
          </div>
          </div>
           
          }
        </>
      ) : null}


          </div>
      </div>
   
  )
}

const DisplayItem = (props) => {
  const dispatch = useDispatch();
  const { value, count } = props;
  const jobFilterDataObj = useSelector((state) => state.golbalReducer.jobFilterData);
  const onChange = e => {
    const userInput = e.currentTarget.value;
    dispatch(jobsFilter(userInput))
  // console.log("iN On change button"+userInput)
  };
  return (
    <div>
      <label className='list-group-item d-flex justify-content-between align-items-center side-menu-f-s'>
        <span className="d-flex">
          <input className="form-check-input me-2 pxp-jobs-page-type " type="checkbox" value={value} onChange={onChange}/>{value}</span>
        {/*<span className="badge rounded-pill">{count}</span> */}
      </label>
    </div>
  );

}
export default SideFilterMenu