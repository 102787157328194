import React from 'react'
import Skeleton from '@mui/material/Skeleton';
export default function UserProfileSkeleton() {
  return (
    <div> <div className='card-body'>
    <div className="form-group row pad-5  ">
        <label className="col-xl-3 col-lg-3 col-form-label font-14">Email</label>
        <div className="col-lg-9 col-xl-6">

        <Skeleton variant="rectangular" height={30} />
        </div>
      </div>
      <div className="form-group row pad-5  ">
        <label className="col-xl-3 col-lg-3 col-form-label font-14">First name</label>
        <div className="col-lg-9 col-xl-6">

        <Skeleton variant="rectangular" height={30} />
        </div>
      </div>
      <div className="form-group row pad-5  ">
        <label className="col-xl-3 col-lg-3 col-form-label font-14">Last name</label>
        <div className="col-lg-9 col-xl-6">

        <Skeleton variant="rectangular" height={30} />
        </div>
      </div>
     
      <div className="form-group row pad-5  ">
        <label className="col-xl-3 col-lg-3 col-form-label font-14">Mobile number</label>
        <div className="col-lg-9 col-xl-6">
        

        <Skeleton variant="rectangular" height={30} />
        </div>
      </div>
      <div className="form-group row pad-5  ">
        <label className="col-xl-3 col-lg-3 col-form-label font-14">Age</label>
        <div className="col-lg-9 col-xl-6">

        <Skeleton variant="rectangular" height={30} />
        </div>
      </div>

      <div className="form-group row pad-5">
        <label className="col-xl-3 col-lg-3 col-form-label font-12">Gender</label>
        <div className="col-lg-9 col-xl-6">
        

          <Skeleton variant="rectangular" height={30} />
         
        </div>
      </div>

    </div></div>
  )
}
