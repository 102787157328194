import React from 'react'
import Skeleton from '@mui/material/Skeleton';

export default function CardSkeleton() {
  return (
    <div> <div className='card no-border no-box-shadow' >
          
    <div className='card-header'>
    <div className='row'>
      <div className='col-8'>
      <Skeleton variant="rectangular" height={30} />
      </div>
      <div className='col-2'>
      <Skeleton variant="rectangular" height={30} />
      </div>
      <div className='col-2'>
      <Skeleton variant="rectangular" height={30} />
      </div>
     </div>
    </div>
    <div className='card-body '>
      <div><Skeleton variant="rectangular" height={25} /></div>
      <div> .</div>
      <div><Skeleton variant="rectangular" height={25} /></div>
    </div>
  </div></div>
  )
}
