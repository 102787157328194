import React,{useState} from 'react'
import Modal from 'react-bootstrap/Modal';
import { useFormik, Form, Field, Formik, useField, ErrorMessage } from 'formik';
import { Divider } from '@mui/material';
import * as Yup from 'yup';
import { Link, useNavigate } from 'react-router-dom'

export default function JobPreference() {
  const [showModal, setShowModal] = useState(false);
  let navigate = useNavigate();
  function hideModel() {
   
    setShowModal(false);
  }

  function handleClick(){
    navigate('/jobs');
  }
  return (
    <div className="card" >
  <div className="card-body">
  <div class="container">
    <div className='row'>
    <div className="col-xl-9 col-md-8 pt-2">
   <h4>Explore personalized jobs for you </h4>
    
    
    </div>
    <div className='col-xl-3 col-md-4'>   
      <button type="submit" className="btn btn-primary rounded submit" onClick={handleClick}>view personalized Job</button> 
    {
      /*
 <button type="submit" className="btn btn-primary rounded submit mar-l-20" onClick={() => setShowModal(true)}>Set personalized preferences</button> 
    <JobPreferencePopup show={showModal} onHide={hideModel}  />
      */
    }
    </div>
    
     
    </div>
    </div>
</div>
        </div>
   
  )
}

export function JobPreferencePopup(props) {
  const initValues="";
  const eductaionValidation = Yup.object().shape({

  })

  return (
    <div> <Modal
      {...props}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
      </Modal.Header>
      <Modal.Body>
        <Formik
          initialValues={initValues}
          validationSchema={eductaionValidation}
          onSubmit={(values, { setSubmitting, setErrors }) => {
          console.log("In sunmot")
        }
      }
        >
          {({ errors, touched, resetForm, isValidating, handleChange, setFieldValue }) => (
            <Form>
              <div className='card-header flex-layout justify-content-center'>
                <div className='align-items-start flex-column'>
                  Add Education Information
                  <p className='profile-tagline'>
                    Update your Education informaiton
                  </p>
                </div>
                <div className='align-items-end flex-column'>
                </div>
              </div>
              <Divider />
              <div className='card-body'>
                <div class="form-group row pad-5">
                  <label class="col-xl-3 col-lg-3 col-form-label font-14">Education</label>
                  <div class="col-lg-9 col-xl-6">
                    sdfdsfsd
                  </div>
                </div>

               
                </div>

             
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal></div>
  )
}
