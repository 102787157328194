import React,{useState,useEffect} from 'react'
import { MuiOtpInput } from 'mui-one-time-password-input'
import { useParams } from "react-router-dom";
import { useLazyGetDetailsForVerificationQuery,useVerifyOTPMutation } from '../../rtk/profile'
import Skeleton from '@mui/material/Skeleton';
import * as Icon from 'react-bootstrap-icons';
import { useSelector } from 'react-redux';
import { updateEmailVerificationStatus} from './../../redux-reducers/authStore';
import {  useDispatch } from 'react-redux';

export default function VerifyAccount() {
    const { token } = useParams();
   // const [otp, setOtp] = useState('');
   const [otp, setOtp] = React.useState('')
   const [tokenDetails, setTokenDetails] = React.useState()
   const [loading, setLoading] = React.useState(true);
   const [loadingB, setLoadingB] = React.useState(false);
   const [errorMessage, setErrorMessage] = React.useState("");
   const [verifed, setVerifed] = React.useState(false);
   const isAuthenticated = useSelector((state) => state.authState.isAuthenticated);
   const dispatch = useDispatch();

   const [trigger, result,{ 
    isLoading, isError, data, error 
   }] = useLazyGetDetailsForVerificationQuery();

   const [verifyOtp] = useVerifyOTPMutation();

   async function fetchUniversityData(resend) {
    setLoading(true);
    console.log("resend::"+resend)
    const response = await trigger({token:token,resend1:resend,type:"token",template:"accountVerification"});
    setLoading(false);
    setTokenDetails(response.data);
    setVerifed(response.data.emailVerified == 1 ? true : false)
  }
  function maskEmail(email) {
    let split = email.split('@')
    return email.substr(0,2) + new Array(split[0].length - 1).fill('x').join('') + "@" + split[1]
  }
  useEffect(() => {
    fetchUniversityData(false);
  }, [])
  

   console.log("token ::"+token + "tokenDetails ::"+JSON.stringify(tokenDetails))
  const handleChange = (newValue) => {
    setOtp(newValue)
  }
  const handlelear=()=>{
    console.log("otp::"+otp)
    setOtp('');
    setErrorMessage("");
  }
  const handleGetOtp =()=>{
    console.log("otp::"+otp);
    fetchUniversityData(true);

  }
  const handleVerify =async()=>{
    console.log("In handleVerify otp::"+otp);
     if(otp.length < 6){
        setErrorMessage("Please enter the OTP completely")
    }else {
        var requestObject = {otp: otp,email:tokenDetails.email}
        let error;
        setLoadingB(true);
        var returned = await verifyOtp(requestObject).unwrap().catch(res => error = res);
        console.log("error :+"+JSON.stringify(error));
        
        if (returned.status == "success") {
         /// notify("success", "Success Message", "Details saved Successfully");
         setVerifed(true);
         if(isAuthenticated){
            console.log("is isAuthenticated true ");
            dispatch(updateEmailVerificationStatus(1));
         }
        } else {
            console.log("error.errorMessage :+"+JSON.stringify(error.errorMessage))
         setErrorMessage(error.data.errorMessage);
        }
        setLoadingB(false);
      }
    

  }
  
  return (
   
    <div className='pad-t-70 box-115'>
    <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: '60vh',
                    }}
                >
                    {
                        loading ? (<> <div className='otpClass  pad-t-15'>
                            
                            <Skeleton variant="rectangular" height={30} />
                             </div>
                             <div className='otpClass p-4'>
                             <Skeleton variant="rectangular" height={30} />
                           
                             </div>
                            
                                <div className='otpClass'>
                                <Skeleton variant="rectangular" height={60} />
                            
                                
                            
                             <div className='otpClass pad-t-15 p-4'>
                             <Skeleton variant="rectangular" height={20} />
                          
                              
                             </div>
                             </div>
                            </>) :(<> 

                            {
                                !verifed ? (<> <div className='otpClass flex-layout justify-content-center pad-t-15'>
                                    <p className='error-message'>{errorMessage}</p>
                     </div>
                                <div className='otpClass flex-layout justify-content-center pad-t-15'>
                                    <p >{errorMessage}</p>
                    <h5>Verify your Connect Nex profile</h5>
                    <Skeleton variant="rectangular" height={30} />
                     </div>
                     <div className='otpClass flex-layout justify-content-justify p-2'>
                     <p>We have sent an OTP to your email {maskEmail(tokenDetails.email)}, Enter the OTP below to verify</p>
                   
                     </div>
                    
                        <div className='otpClass'>
                     <MuiOtpInput value={otp} length={6} onChange={handleChange}  />
                    
                        
                    
                     <div className='otpClass flex-layout justify-content-center pad-t-15'>
                    <p>Did not received code? <b className='pointerTest' onClick={()=>handleGetOtp()}>resend</b>
                    </p>
                      
                     </div>
                     </div>
                     <div className='otpClass flex-layout justify-content-between pad-t-30'>
                     <button type="submit" className="btn btn-primary rounded submit p-2 px-4" onClick={()=>handlelear()}>Clear</button>
                     <button type="submit" className="btn btn-primary rounded submit p-2 px-4" onClick={()=>handleVerify()}>
                     {!loadingB && <span className='indicator-label'   disabled={loadingB}>Verify</span>}
              {loadingB && (
                <span className='indicator-progress' style={{ display: 'block' }}>
                  Please wait...
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
                     </button>
                      
                     </div> </>) : (<><SuceessVerified/> </>)
                            }
                               </>)
                    }
                    
                
                
                </div>
    </div>
   
   
  )


 
}

const SuceessVerified = () =>{


    return (
        < div
        style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '30vh',
        }}
    >
     <div className='successMsgIcon'><Icon.Check2Circle/></div> 
    <div><h3>Connect nex account and email is Verified</h3>  </div>
    </div>
    )
  }
