import { useEffect, useState } from 'react';

// material-ui
import { Grid } from '@mui/material';

// project imports

import { gridSpacing } from './../../redux-reducers/constant';
import { Link, useNavigate } from 'react-router-dom';
import PostJobLink from './PostJobLink';
// ==============================|| DEFAULT DASHBOARD ||============================== //

const Dashboard = () => {
  const [isLoading, setLoading] = useState(true);
  useEffect(() => {
    setLoading(false);
  }, []);
  let navigate = useNavigate();
const navPostJob=()=>{
  navigate('/auth/app/post-job')
}
// <button type="submit" onClick={()=>navPostJob()} className="btn btn-primary rounded submit p-2 px-4">Post Job</button>
      
  return (
    <Grid container spacing={gridSpacing}>
       <Grid item xs={12}>
       <Grid container spacing={gridSpacing}>
         
          <Grid item lg={4} md={6} sm={6} xs={12}>
          
          </Grid>
          <Grid item lg={4} md={6} sm={6} xs={12} onClick={()=>navPostJob()} className='pointer'>
            <PostJobLink isLoading={isLoading} />
          </Grid>
          <Grid item lg={4} md={12} sm={12} xs={12}>
            <Grid container spacing={gridSpacing}>
              <Grid item sm={6} xs={12} md={6} lg={12}>
             
              </Grid>
             
            </Grid>
          </Grid>
        </Grid>
       </Grid>
      {/*
        <Grid item xs={12}>
        <Grid container spacing={gridSpacing}>
          <Grid item lg={4} md={6} sm={6} xs={12}>
            <EarningCard isLoading={isLoading} />
          </Grid>
          <Grid item lg={4} md={6} sm={6} xs={12}>
            <TotalOrderLineChartCard isLoading={isLoading} />
          </Grid>
          <Grid item lg={4} md={12} sm={12} xs={12}>
            <Grid container spacing={gridSpacing}>
              <Grid item sm={6} xs={12} md={6} lg={12}>
                <TotalIncomeDarkCard isLoading={isLoading} />
              </Grid>
              <Grid item sm={6} xs={12} md={6} lg={12}>
                <TotalIncomeLightCard isLoading={isLoading} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={gridSpacing}>
          <Grid item xs={12} md={8}>
            {//<TotalGrowthBarChart isLoading={isLoading} />
            }
          </Grid>
          <Grid item xs={12} md={4}>
            <PopularCard isLoading={isLoading} />
          </Grid>
        </Grid>
      </Grid>
      */}
    </Grid>
  );
};

export default Dashboard;
