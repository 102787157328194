import React,{useState,useEffect} from 'react'
import comLogo from '../../assets/images/Hitachi-logo.jpg'
import * as Icon from 'react-bootstrap-icons';
import ApplyJobPopUp from '../../components/applyJobPopUp/ApplyJobPopUp';
import ReactGA from "react-ga4";
import {useJobApplyMutation} from './../../rtk/jobs';
import notify from '../../utils/Utils';
import LoginPopup from '../../components/popupModel/LoginPopup';
import { useLazyGetJobsByFilterQuery,useLazyGetApplliedJobQuery } from '../../rtk/jobs'
import { useSelector,useDispatch } from 'react-redux';
import { updateApplied } from '../../redux-reducers/global';

function JobHeaderDetails(props) {
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const isAuthenticated = useSelector((state) => state.authState.isAuthenticated);
  const dispatch = useDispatch();
  const {data} =props;
  const [isApplied, setIsApplied] = useState(false);
  const appliedJobs = useSelector((state) => state.golbalReducer.appliedJob);
  const [loginModal, setLoginModal] = useState(false);
  const [getaAppliedJobs] = useLazyGetApplliedJobQuery();
  const [
    updatePost, // This is the mutation trigger
    {error: updateProgramError , isLoading: isUpdating }, // This is the destructured mutation result
  ] =useJobApplyMutation();

  function OpenModel(){
    setShowLoginModal(true);
    /*ReactGA.event({
      category: 'Apply Job Model Opened',
      action: 'Action : Apply Job Model opned from job Desc for '+data.jobId,
      label:'Apply Job Model opned from job Desc for '+data.jobId
    });
    */
  }
  useEffect(() => {
    const fetchAppliedJob = async () => {
      const returned = await getaAppliedJobs();
     console.log("fetchAppliedJob returned :::"+JSON.stringify(returned.data));
     dispatch(updateApplied(returned.data));
    }
   
    fetchAppliedJob();
   

    console.log("In iuse effect ::"+JSON.stringify(appliedJobs))
    var filteredObj = appliedJobs.find(function(item, i){
        if(item.job_id === data.jobId){
          return true;
        }
      });
      console.log("filteredObj ::"+JSON.stringify(filteredObj))
      if(filteredObj!=undefined){
        setIsApplied(true);
      }
  }, [appliedJobs])

  const clickLoginApply = async(jobId)=>{
    if(!isAuthenticated){
      setLoginModal(true)
  }else {
    console.log("jobId ::"+jobId)
    let error = undefined;
    setLoading(true);
    const returned =await updatePost({jobId:jobId}).unwrap().catch(res => error = res);
    
    console.log("returned ::"+JSON.stringify(error))
    console.log("returned returned  ::"+JSON.stringify(returned))
    if(error !=undefined){
        notify("danger","Error Message",error.data.errorMessage);

    }else {
        console.log("Adding data ::"+JSON.stringify(returned))
      //  setAppliedJobs(returned)
        dispatch(updateApplied(returned));
    }
    setLoading(false);
  }
  
   /* ReactGA.event({
        category: 'Apply Job Model Opened',
        action: 'Action : Apply Job Model opned from job List for '+jobDetails.jobId,
        label:'Apply Job Model opned from job List for '+jobDetails.jobId
      });
      */
}

  return (
    <div className='card'>
              <div className='card-body'>
                <div className='job-info f-left'>
                  <h1 className="card-title">{data.jobTitle}</h1>
                  <p className='sub-header'>{data.company}</p>
                  <div className='flex-layout'>
                    <div className=''>
                      <p className='sub-info'> <Icon.BriefcaseFill size={12} />
                      {data.jobtype.map(
  (item, i, arr) => <span className='pad-l-5'>{item.jobType} {i != (arr.length-1) ? ', ' : ''}</span>)

}
                       
                      </p>
                    </div>

                    <div className='pad-l-10'>
                      <p className='sub-info'> <Icon.PersonWorkspace size={12} />
                        <span className='pad-l-5'>{data.workType}</span>
                      </p>
                    </div>

                  </div>
                  {
                                data.location.city.length !=0 || data.location.state.length!=0 || data.location.country.length !=0 ? (
                               <>
                                <div className='pad-l-10'>
                                <p className='sub-info'> <Icon.GeoAltFill size={12} /> 
                                    <span className='pad-l-5'>{data.location.city}, {data.location.state},{data.location.country}</span>
                                </p>
                            </div>
                               </>
                                ) :(
                                    ""
                                )
                           }
                  <div >
                    {
                      /*
 <div className='flex-layout '>
                      <Icon.Link size={20} /> <p className='sub-info pad-l-5'>{data.jobLink}</p>
                    </div>
                      */
                    }
                   

                    <div className='flex-layout justify-content-end'>
                    {
                                isApplied ? (<button className='btn-style succes-color' disabled={true} > <Icon.Check2Circle/> Applied </button>):
                                (<>
                                {
                                    !isAuthenticated && <button className='btn-style secondry-color' onClick={() =>OpenModel()}>Easy Apply</button>
                                }
                                
                                <button className='btn-style secondry-color' onClick={() =>clickLoginApply(data.jobId)} disabled={loading}>
                                {!loading && <span className='indicator-label'>Apply</span>}
              {loading && (
                <span className='indicator-progress' style={{ display: 'block' }}>
                  Please wait...
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
                                   </button>
                                </>)
                            }

<LoginPopup
        show={loginModal}
        onHide={() => setLoginModal(false)}
      />

                      <ApplyJobPopUp
                                show={showLoginModal}
                                setShowLoginModal={setShowLoginModal}
                                onHide={() => setShowLoginModal(false)}
                                jobidProp={data.jobId}
                            />
                      {/*<button className='btn-style secondry-color' >Save</button> */}
                    </div>
                  </div>
                </div>
                <div className='comp-img f-right'>
                  {/*<img className='image-style' src={comLogo} />*/}

                </div>



              </div>

            </div>
  )
}

export default JobHeaderDetails;