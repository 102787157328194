import React from 'react';
import AboutUsBanner from '../../components/aboutus-banner/AboutUsBanner';
import {HrCandidate,Teamwork} from '../../components/svg/Icon';
import Footer from './../../components/footer/Footer'

function AboutUs() {
  return (
    <>
    <div className='pad-t-70 box-115'>
      <div className='container-fluid'>
        <div className='row'>
          <div className="col-xl-9">
          <div className='pad-t-70'>
          <h5 className="card-title">About us </h5>
          <div className='pad-t-15'>
            <p className="card-text">
            Connect Nex is the most innovative online job portal. Connect Nex connects jobseekers and 
            recruiters by  
            matching candidate profiles to the relevant job openings. Connect Nex focuses on the 
            entire career growth of candidates and also stream line recruiters jobs b make there day to day task.
            Connect Nex works closely to bridge the gap between talent & opportunities and offers
             end-to-end recruitment solutions. 
               </p>
         </div>
          </div>
          </div>
          <div className="col-xl-3 d-none d-xl-block">
          <div className='svg'><HrCandidate/></div> 
          </div>
        </div>

        <div className='row'>
          <div className="col-xl-3 d-none d-xl-block">
          <div className='svg'><Teamwork/></div> 
          </div>
          <div className="col-xl-9">
          <div className='pad-t-70'>
          <h5 className="card-title">AccuitySavvy purpose and vision </h5>
          <div className='pad-t-15'>
            <p className="card-text"> 
            we work to bring opportunity to the job market for candidate looking jobs and companies looking to hire 
            full time or freelancer and enhance lives, businesses and communities around the world.
            our vision is to make platform for people looking for better job and connecting each other.
            </p>
         </div>
          </div>
          </div>
        </div>

        
      </div>
    </div>
    
    </>

  )
}

export default AboutUs