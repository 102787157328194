import React from 'react'

import JobListComponent from '../../components/jobListComponent/JobListComponent'
import SearchBanner from '../../components/searchBanner/SearchBanner'
import SideFilterMenu from '../../components/sideFilterMenu/SideFilterMenu'
import { useSelector } from 'react-redux';
import Footer from './../../components/footer/Footer';
function JobsListLayout() {
//  const searchQueryDataObj = useSelector((state) => state.golbalReducer.searchQueryData);
return (
  <>
    <div className='pad-t-70'>
      <div className='container-fluid'>
      <div className='row'>
          <div className="col-12">
        <SearchBanner />
        </div>
        </div>
        <div className='row'>
          <div className="col-lg-3 col-md-3 col-sm-12">
            <SideFilterMenu />
          </div>
          <div className="col-lg-9 col-md-9 col-sm-12">
            <JobListComponent />
          </div>
        </div>
      </div>
    </div>
    
    </>
  )
}

export default JobsListLayout