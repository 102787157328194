import React from 'react'
import Skeleton from '@mui/material/Skeleton';

export default function SideFilterMenuSkeleton() {
  return (
    <div className="card-body">
            <h6>Employment Type</h6>
            <div className='pt-2'>
            <Skeleton variant="rectangular" height={30} />
          </div>
          <div className='pt-2'>
            <Skeleton variant="rectangular" height={30} />
          </div>
          <div className='pt-2'>
            <Skeleton variant="rectangular" height={30} />
          </div>
          </div>
  )
}
