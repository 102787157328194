import { useFormik } from 'formik'
import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import * as Yup from 'yup';
import clsx from 'clsx';
import { ReactComponent as YourSvg } from './../../assets/SVG-PL/business-electricity-connect-energy-commerce-svgrepo-com.svg';
import { useDispatch } from 'react-redux';
import { useGetLoginMutation } from '../../rtk/auth';
import notify from '../../utils/Utils';
import CryptoJS from 'crypto-js';
import { MuiOtpInput } from 'mui-one-time-password-input'
import { useLazyGetDetailsForVerificationQuery,useVerifyOTPMutation } from '../../rtk/profile'
import { useSetNewPasswordMutation } from '../../rtk/auth'
import * as Icon from 'react-bootstrap-icons';

export default function ForgotPassword(props) {
 
  const {storeUserData,enableRegister,setIsOpenLogin,onHid,enableLogin} =props;
  const [otp, setOtp] = React.useState('');
  const [loadingB, setLoadingB] = React.useState(false);
  const [otpValidated, setOtpValidated] = React.useState(false);
  const [emailSent, setEmailSent] = React.useState(false);
  const [displayOtpButton, setDisplayOtpButton] = React.useState(true);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [message, setMessage] = React.useState("");
  const [getOtpData, setGetOtpData] = React.useState("");
  const [verifyOtp] = useVerifyOTPMutation();
  const [getVerifcatinonDetails] = useLazyGetDetailsForVerificationQuery();
  const [setNewPassword] = useSetNewPasswordMutation();
  const [passwordChanged, setPasswordChanged] = React.useState(false);
  const handleChange = (newValue) => {
    setOtp(newValue)
  }
  const handleVerify =async()=>{
    console.log("In Forget password handleVerify ::"+otp.length);
   
    if( otp.length < 6){
      setErrorMessage("Please enter the OTP completely")
  }else {
      var requestObject = {otp: otp,email:formik.values.email}
      let error;
      setLoadingB(true);
      var returned = await verifyOtp(requestObject).unwrap().catch(res => error = res);
      console.log("error :+"+JSON.stringify(error));
      
      if (returned.status == "success") {
       /// notify("success", "Success Message", "Details saved Successfully");
      // setVerifed(true);
      setLoadingB(false);
    
      setOtpValidated(true);
      setEmailSent(false);
      setDisplayOtpButton(false);
      } else {
          console.log("error.errorMessage :+"+JSON.stringify(error.errorMessage))
       setErrorMessage(error.data.errorMessage);
      }
      
  }
   
  }
  const initialValues = {
    email: '',
    password: '',
    confirmPassword: ''
  }
  const handlelear=()=>{
    console.log("otp::"+otp)
    setOtp('');
    setErrorMessage("");
  }
  const handleGetOtp =async(type)=>{
    console.log("handleGetOtp ::"+otp +" type ::"+type);
    let error ;
    setLoading(true);
   
      setEmailSent(false);
      setDisplayOtpButton(true);
    const response = await getVerifcatinonDetails({token:formik.values.email,resend1:true,type:"email",template:"forgotPassword"}).unwrap().catch(res => error = res);;
    setLoading(false);
    console.log("error  verification detal ::"+JSON.stringify(error))
    if(error == undefined){
      setOtpValidated(false);
      setEmailSent(true);
      setDisplayOtpButton(false);
      setErrorMessage("");
      if(type == 'send'){
        setMessage('OTP Sent');
      }else if (type == 'resend'){
        setMessage('OTP Resent');
      }
    }else {
      setErrorMessage(error.data.errorMessage);
      setMessage("");
    }
    console.log("response ::"+response);
  
  }

  const [
    login, // This is the mutation trigger
    {error: updateProgramError , isLoading: isUpdating }, // This is the destructured mutation result
  ] =useGetLoginMutation();
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  let navigate = useNavigate();
  const loginSchema = Yup.object().shape({
    email: Yup.string()
      .email('Wrong email format')
      .min(3, 'Minimum 3 symbols')
      .max(50, 'Maximum 50 symbols')
      .required('Email is required'),
    password: Yup.string()
      .min(3, 'Minimum 3 symbols')
      .max(50, 'Maximum 50 symbols')
      .required('Password is required'),
    conformPassword: Yup.string()
      .min(3, 'Minimum 3 symbols')
      .max(50, 'Maximum 50 symbols')
      .required('Password is required')
      .oneOf([Yup.ref('password'), null], 'Passwords must match'),
  })
  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true)
      initialValues.email = values.email;
      console.log("process.env.REACT_APP_SECRET_KEY ::"+process.env.REACT_APP_SECRET_KEY)
      const password =CryptoJS.AES.encrypt(values.password, process.env.REACT_APP_SECRET_KEY).toString();
      initialValues.password =password; 
      let errorres;
      const returned =await setNewPassword(initialValues).unwrap().catch(res => errorres = res);
      console.log("setNewPassword returned ::"+JSON.stringify(returned) + " errorres ::"+errorres)
      if(errorres != undefined){
        console.log("process.env. error parse ::"+errorres.data.errorMessage)
        setError(errorres.data.errorMessage);
        setLoading(false);
       
      }else {
        console.log("Login in login return succuess ")
       // storeUserData(returned);
       // onHide(false);
        setLoading(false);
        setPasswordChanged(true);
      }
      loadingB(false);
      /*
      if(returned.userId != null){
        console.log("Login in login return succuess ")
        storeUserData(returned);
        onHide(false)
       }else {
        console.log("Login in login return not succuess ")
        setLoading(false);
        setError(errorres.data.errorMessage);
        //notify("danger","Rrror Message","Error in uploaded Successfully");
       }
        */
     }
  })

  return (
    <>
    <div className='row'>
      <div className='col-6'>
      <div className='vertical-center'>
      <h4 className="text-center mb-4">Plan Next with Connect Nex , Welocme to Connect Nex</h4>
        <div className='justify-svg-center'><YourSvg/></div>
        </div>
        </div>
        {
          !passwordChanged ? (<>
           <div className='col-6'>
      <h5 className="text-center mb-4">Have an account? Login </h5>
      <div className='row justify-content-center error-message p-2'>
                                    <p className='error-message'>{errorMessage}</p>
                     </div>
      <div className='row justify-content-center error-message p-2'>
                                    <p className='success-message'>{message}</p>
                     </div>

                     
      <div className='row justify-content-center error-message p-2'> {error}</div>
      {formik.status ? (
        <div>
          <div className='mb-lg-15 alert alert-danger'>
            <div className='alert-text font-weight-bold'>{formik.status}</div>
          </div>
        </div>
      ) : (
        ""
      )}
      <form className="login-form" onSubmit={formik.handleSubmit}>
        <div className="form-group">
          <div className="mb-3">
            <div>
              <label className="col-form-label font-14">Email</label>
            </div>
            <input type="email" placeholder="name@example.com" disabled={emailSent}
              {...formik.getFieldProps('email')}
              className={clsx(
                'form-control',
                { 'is-invalid': formik.touched.email && formik.errors.email },
                {
                  'is-valid': formik.touched.email && !formik.errors.email,
                }
              )}
              name='email'
              autoComplete='off'
            />
             {formik.touched.email && formik.errors.email && (
              <div className='error-message p-2'> {formik.errors.email}</div>
             )}
          </div>
        </div>
       {
        emailSent &&  <div className=' flex-layout justify-content-end '>
        <p>Did not received code? <b className='pointerTest' onClick={()=>handleGetOtp('resend')}>Resend OTP</b>
        </p>
        </div>
       }
        {
          emailSent && 
            <>
            <div className="form-group">
            
            <div className="mb-3">
            <div>
              <label className="col-form-label font-14">Password</label>
            </div>
            <MuiOtpInput value={otp} length={6} onChange={handleChange}  />
          </div>
        </div>
            <div className="form-group">
        <div className="mb-3">
        <div className="flex-layout justify-content-between ">
                     <button type="submit" className="btn btn-primary rounded submit p-2 px-4" onClick={()=>handlelear()}>Clear</button>
                     <button type="submit" className="btn btn-primary rounded submit p-2 px-4" onClick={()=>handleVerify()}>
                     {!loadingB && <span className='indicator-label'   disabled={loadingB}>Verify</span>}
              {loadingB && (
                <span className='indicator-progress' style={{ display: 'block' }}>
                  Please wait...
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
                     </button>
                     </div>
                      </div>
                     </div>
            </>
        
        
        }
        


        
        {
          otpValidated && <>
          <div className="form-group">
          <div className="mb-3">
            <div>
              <label className="col-form-label font-14">Password</label>
            </div>
            <input type="password" placeholder="Password"
              {...formik.getFieldProps('password')}
              className={clsx(
                'form-control',
                { 'is-invalid': formik.touched.password && formik.errors.password },
                {
                  'is-valid': formik.touched.password && !formik.errors.password,
                }
              )}
            />
             {formik.touched.password && formik.errors.password && (
              <div className='error-message p-2'> {formik.errors.password}</div>
             )}
          </div>
        </div>
        <div className="form-group">
          <div className="mb-3">
            <div>
              <label className="col-form-label font-14">Confirm Password</label>
            </div>
            <input type="password" placeholder="confirm Password"
              {...formik.getFieldProps('conformPassword')}
              className={clsx(
                'form-control',
                { 'is-invalid': formik.touched.conformPassword && formik.errors.conformPassword },
                {
                  'is-valid': formik.touched.conformPassword && !formik.errors.conformPassword,
                }
              )}
            />
             {formik.touched.conformPassword && formik.errors.conformPassword && (
              <div className='error-message p-2'> {formik.errors.conformPassword}</div>
             )}
          </div>
        </div>
          </>
        }


        
        <div className="form-group">
          <div >
          </div>
        </div>
        {
          otpValidated && <>
           <div className="form-group">
          <div className='d-grid mb-10'>
            <button
              type='submit'
              className="btn btn-primary rounded submit"
              disabled={formik.isSubmitting || !formik.isValid}
            >
              {!loading && <span className='indicator-label'>Continue</span>}
              {loading && (
                <span className='indicator-progress' style={{ display: 'block' }}>
                  Please wait...
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>

            <div className="flex-layout justify-content-center ">
              Don't have account, <Link onClick={enableRegister} className='d-flex flex-row-reverse link ms-1'>Register</Link>
            </div>
           
          </div>
        </div>
        </>}
        { displayOtpButton  && 

        <>
          <div className="form-group">
          <div className='d-grid mb-10'>
            <button
              type='submit'
              className="btn btn-primary rounded submit"
              disabled={loading}
              onClick={()=>handleGetOtp('send')}
            >
              {!loading && <span className='indicator-label'>Get OTP</span>}
              {loading && (
                <span className='indicator-progress' style={{ display: 'block' }}>
                  Please wait...
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>

            <div className="flex-layout justify-content-center ">
              Don't have account, <Link onClick={enableRegister} className='d-flex flex-row-reverse link ms-1'>Register</Link>
            </div>
           
          </div>
        </div>
        </>
        }
       
      </form>
      </div>
      </>) :(<><div className='col-6'><SuceessVerified enableLogin={enableLogin}/></div></>)
        }
     
      </div>
    </>
  )
}

const SuceessVerified = (props) =>{

  const {enableLogin} =props;

  return (
      < div
      style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          paddingTop:'120px',
          height: '30vh',
      }}
  >
   <div className='successMsgIcon'><Icon.Check2Circle/></div> 
  <div><h5>Password Reset Successfully,  <Link onClick={enableLogin} className='success-message'>Login Again</Link></h5>  

            </div>
  </div>
  )
}
