import  React,{useEffect,useState} from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';

const steps = [
  'Applied',
  'Reviewed',
  'Interview Schedule',
  'Completed',
];

export default function JobStatusStepper(props) {
    const {status} = props;
    const [stage, setStage] = useState(0);
    
    useEffect(() => {
        console.log("status ------::"+status)
        if(status == 'Applied'){
            setStage(0);
        }else if (status == 'Reviewed') {
            setStage(1);
        }
        else if (status == 'Interview') {
            setStage(2);
        }
        else if (status == 'Completed') {
            console.log("status setting ------::"+status)
            setStage(3);
        }
     
    }, [])
    
  return (
    
      <Stepper activeStep={stage} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
   
  );
}