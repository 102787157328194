import React from 'react'
import { Link } from 'react-router-dom'
import * as Icon from 'react-bootstrap-icons';
export default function JobSqCard(props) {
    const {data}=props;
    
  return (
    
    <div> <div><div className="card" >
    
    <div className="card-body">
    <div className="d-flex justify-content-between">
    <div className='svg'>{data.company}</div>
      <div className="card-title">Active </div>
      </div>
      <h5>{data.jobTitle}</h5>
      
      <div className='pad-l-10'>
                                <p className='sub-info'> <Icon.GeoAltFill size={12} />
                                    <span className='pad-l-5'>{data.location.city}, {data.location.state},{data.location.country}</span>
                                </p>
                            </div>

                            <p className="card-text">
                            
                            {
                                data.skills.map((skills, index) => (
                                    <span className='skill-bg mar-l-5'> {skills.skillName}</span>
                                    
                                ))
                            }
                        </p>
      </div>
  </div></div></div>
  )
}
