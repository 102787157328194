import React from 'react'
import { Link } from 'react-router-dom'

export default function Page404() {
  return (
    <div className='pad-t-70 box-115'>
       <div class="d-flex align-items-center justify-content-center vh-100">
        <div class="text-center">
            <h1 class="display-1 fw-bold">404</h1>
            <p class="fs-3"> <span class="text-danger">Opps!</span> Page not found.</p>
            <p class="lead p-4">
                The page you’re looking for doesn’t exist.
            </p>
            <Link to="/" class="btn btn-primary">Go Home</Link>
        </div>
    </div>
    </div>
  )
}
