import React from 'react'

function ReviewApplication(props) {
const {profileFields,jobDetailsState,compDetailsState} =props;
const AppHTML = () => <div dangerouslySetInnerHTML={{ __html: jobDetailsState.JobDescText }} />;
  return (
    <div>
    <div className='card '>
    <div className='card-body'>
    <div className='card-header flex-layout justify-content-between'>
          <div className='align-items-start flex-column'>
          Job Describe Details 
           
          </div>

        </div>
        <div className='row'>
    <div className='col '>
    <div className='pad-t-10'>
    <div className="form-group row pad-5  ">
              <label className="col-xl-3 col-lg-3 col-form-label font-14">Company Name</label>
              <div className="col-lg-9 col-xl-6">
              <label className="col-xl-9 col-lg-9 col-form-label font-14">{profileFields.hiringCompany.length==0 ? ("N/A - Not disclosed by recruiter") :profileFields.hiringCompany}</label>
              </div>
            </div>
            <div className="form-group row pad-5  ">
              <label className="col-xl-3 col-lg-3 col-form-label font-14">Job Link url</label>
              <div className="col-lg-9 col-xl-6">
              <label className="col-xl-9 col-lg-9 col-form-label font-14">{profileFields.clientJobLink.length==0 ?("N/A"): profileFields.clientJobLink}</label>
              </div>
            </div>
            <div className="form-group row pad-5  ">
              <label className="col-xl-3 col-lg-3 col-form-label font-14">Job Title</label>
              <div className="col-lg-9 col-xl-6">
              <label className="col-xl-9 col-lg-9 col-form-label font-14">{profileFields.jobTitle}</label>
              </div>
            </div>
            <div className="form-group row pad-5  ">
              <label className="col-xl-3 col-lg-3 col-form-label font-14">Job work Location</label>
              <div className="col-lg-9 col-xl-6">
              <label className="col-xl-9 col-lg-9 col-form-label font-14">{profileFields.jobCity},{profileFields.jobState},{profileFields.jobCountry}</label>
              </div>
            </div>
            <div className="form-group row pad-5  ">
              <label className="col-xl-3 col-lg-3 col-form-label font-14">Job work Type</label>
              <div className="col-lg-9 col-xl-6">
              <label className="col-xl-9 col-lg-9 col-form-label font-14">{profileFields.workType}</label>
              </div>
            </div>
            <div className="form-group row pad-5  ">
              <label className="col-xl-3 col-lg-3 col-form-label font-14">Job Type</label>
              <div className="col-lg-9 col-xl-6">
              <label className="col-xl-9 col-lg-9 col-form-label font-14">
              {
profileFields.jobType.map(
  (item, i, arr) => <span>{item} {i != (arr.length-1) ? ', ' : ''}</span>)

}
                </label>
              </div>
            </div>
            <div className="form-group row pad-5  ">
              <label className="col-xl-3 col-lg-3 col-form-label font-14">No Of Opening</label>
              <div className="col-lg-9 col-xl-6">
              <label className="col-xl-9 col-lg-9 col-form-label font-14">{profileFields.noOfOpening}</label>
              </div>
            </div>
            <div className="form-group row pad-5  ">
              <label className="col-xl-3 col-lg-3 col-form-label font-14">Job Time Period</label>
              <div className="col-lg-9 col-xl-6">
              <label className="col-xl-9 col-lg-9 col-form-label font-14">{profileFields.jobReqTimePeriod}</label>
              </div>
            </div>
          </div>
    </div>
    </div>
  </div>
      </div>

      <div className='card '>
    <div className='card-body'>
    <div className='card-header flex-layout justify-content-between'>
          <div className='align-items-start flex-column'>
           Job Additional Details
           
          </div>

        </div>
        <div className='row'>
    <div className='col '>
    <div className='pad-t-10'>
            <div className="form-group row pad-5  ">
              <label className="col-xl-3 col-lg-3 col-form-label font-14">Job Tag</label>
              <div className="col-lg-9 col-xl-6">
              <label className="col-xl-9 col-lg-9 col-form-label font-14">
            
   
                {jobDetailsState.jobTag}
                </label>
              </div>
            </div>
            <div className="form-group row pad-5  ">
              <label className="col-xl-3 col-lg-3 col-form-label font-14">Job Desc</label>
              <div className="col-lg-9 col-xl-6">
              <label className="col-xl-9 col-lg-9 col-form-label font-14"><AppHTML/></label>
              </div>
            </div>
            
            
          </div>
    </div>
    </div>
  </div>
      </div>

      <div className='card '>
    <div className='card-body'>
    <div className='card-header flex-layout justify-content-between'>
          <div className='align-items-start flex-column'>
          Compensation Details
           
          </div>

        </div>
        <div className='row'>
    <div className='col '>
    <div className='pad-t-10'>
            <div className="form-group row pad-5  ">
              <label className="col-xl-3 col-lg-3 col-form-label font-14">Salary Range</label>
              <div className="col-lg-9 col-xl-6">
              <label className="col-xl-9 col-lg-9 col-form-label font-14">
                {
                  compDetailsState.maxSal.length==0 ?(
                    <>
                     {compDetailsState.minSal} { " "+compDetailsState.compType}
                    </>
                  ) : (
                    <>
                    {compDetailsState.minSal} - {compDetailsState.maxSal} { " "+compDetailsState.compType}
                   </>
                  )
                }
                </label>
                </div>
            </div>
            <div className="form-group row pad-5  ">
              <label className="col-xl-3 col-lg-3 col-form-label font-14">Benifits</label>
              <div className="col-lg-9 col-xl-6">
              <label className="col-xl-9 col-lg-9 col-form-label font-14">
{
  compDetailsState.benifits.length ==0 ?("N/A"): (
compDetailsState.benifits.map(
  (item, i, arr) => <span>{item} {i != (arr.length-1) ? ', ' : ''}</span>)
)
}

              

                
                </label>
              </div>
            </div>
            
            
          </div>
    </div>
    </div>
  </div>
      </div>
      </div>
  )
}

export default ReviewApplication