import React from 'react'
import Modal from 'react-bootstrap/Modal';
import AuthLayout from './../../auth/AuthLayout'
import { ReactComponent as YourSvg } from '../../assets/svg-icons/shield-user-privacy-user-protection-user-shield-webpage-svgrepo-com.svg';
import Login from '../../auth/component/Login';
function LoginPopup(props) {
  return (
    <Modal
      {...props}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
      </Modal.Header>
      <Modal.Body>
        <AuthLayout viewType={"Login"} onHide={props.onHide}/>
      </Modal.Body>

    </Modal>

  )
}

export default LoginPopup