export const educationType =[
    {id:1,name:"PhD/Doctorate"},
    {id:2,name:"Master/Post-Garduation"},
    {id:3,name:"Garduation/Diploma"},
    {id:4,name:"Bachelors"},
    {id:5,name:"MBA"},
    {id:6,name:"High School"}
]

export const noticePeriodType =[
    {id:1,name:"15 days or less"},
    {id:2,name:"1 Month"},
    {id:3,name:"2 Month"},
    {id:4,name:"3 Month"},
    {id:5,name:"More then 3 Month"},
    {id:6,name:"Serving Notice Period"}
]

export const monthNames = ["January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
];

export const duplicateEmploymentType = (list, value) => {
    console.log("duplicateEmploymentType value :::" + value + " --------- list :" + JSON.stringify(list))
    const isExisting = list.some((option) => value === option.id);
    console.log("isExisting :::" + isExisting)
    return !isExisting;
  };

  export const duplicatProject = (list, value) => {
    console.log("duplicatProject value :::" + value + " --------- list :" + JSON.stringify(list))
    const isExisting = list.some((option) => value === option.projectName);
    console.log("isExisting :::" + isExisting)
    return !isExisting;
  };
