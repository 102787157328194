import React,{useEffect} from 'react'
import Modal from 'react-bootstrap/Modal';
import { ReactComponent as YourSvg } from '../../assets/svg-icons/shield-user-privacy-user-protection-user-shield-webpage-svgrepo-com.svg';
import { Formik, Field, Form, ErrorMessage,useFormikContext } from 'formik';
import * as Yup from 'yup';
import { useSubmitResumeMutation } from '../../rtk/jobs';
import notify from '../../utils/Utils';
import { useState } from 'react';
import * as Icon from 'react-bootstrap-icons';
import ReactGA from "react-ga4";
function ApplyJobPopUp(props) {
    const {setShowLoginModal,jobidProp} =props;
    const [confirmation, setConfirmation] = useState(false);
    const initialData = {
        jobId:jobidProp,
        email: "",
        mobile: "",
        resume:""
      };
      const [
        updatePost, // This is the mutation trigger
        {error: updateProgramError , isLoading: isUpdating }, // This is the destructured mutation result
      ] =useSubmitResumeMutation()
      function validationSchema() {
        return Yup.object().shape({
            email: Yup.string().required('Email is required')
          .min(2, 'Email must be at least 2 characters'),
          mobile: Yup.string().required('Mobile number is required')
          .min(5, 'Mobile number must be at least 9 number'),
          resume : Yup.string().required('Resume is required')
          //.min(2, 'City must be at least 2 characters'),
          
        });
      }
    
      const handleSubmit = async (data) => {
        console.log("In handleSubmit Easy Apply :: "+JSON.stringify(data));
        const returned =await updatePost(data).unwrap();
        if(returned.status =='success'){
        notify("success","Success Message","Resume Upload Successfully");
       
          ReactGA.event({
            category: 'Job Applied ',
            action: 'Action : Job Applied from popup for Job Id'+jobidProp,
            label:'Job Applied from popup for JOb Id'+jobidProp
          });

        setConfirmation(true);
        
       }else {
        ReactGA.event({
          category: 'Job Applied ',
          action: 'Action : Job Applied from popup for Job Id'+jobidProp + " Failed",
          label:'Job Applied from popup for JOb Id'+jobidProp +" Failed"
        });
        notify("danger","Rrror Message","Error in uploaded Successfully");
       }
      }

  return (
     <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
      <div className='card-header flex-layout justify-content-between'>
            <div className='align-items-start flex-column'>
            Post your Resume Free !!
              <p className='profile-tagline'>
                We will take care of your job search.. Happy new Job 

               

              </p>
            </div>

          </div>
      </Modal.Header>
      <Modal.Body>
      {!confirmation ? (
       <Formik
       initialValues={initialData}
       validationSchema={validationSchema}
       onSubmit={handleSubmit}
       
     >
        {({ errors, touched, resetForm,isValidating,handleChange,setFieldValue  }) => (
         <Form >
 
   <div className="form-group row pad-t-30  ">
   <label className="col-xl-3 col-lg-3 col-form-label font-14">Email</label>
       <div className="col">
      
             
       <Field
               name="email"
               type="text"
               pleaceholder="Email"
               className={
                 'form-control' +
                 (errors.email && touched.email ? ' is-invalid' : '')
               }
             />
             <ErrorMessage
               name="email"
               component="div"
               className="invalid-feedback"
             />
             </div>
    </div>

    <div className="form-group row pad-t-15  ">
    <label className="col-xl-3 col-lg-3 col-form-label font-14">Mobile</label>
       <div className="col">
         
       <Field
               name="mobile"
               type="text"
              
               className={
                 'form-control' +
                 (errors.mobile && touched.mobile ? ' is-invalid' : '')
               }
             />
             <ErrorMessage
               name="mobile"
               component="div"
               className="invalid-feedback"
             />
             </div>
    </div>

    <div className="form-group row pad-t-15  ">
    <label className="col-xl-3 col-lg-3 col-form-label font-14">Resume</label>
       <div className="col">
         
       <input
               name="resume"
               type="file"
               
               onChange={(event) => {
             setFieldValue("resume",event.target.files[0])
               }}
               className={
                 'form-control' +
                 (errors.resume && touched.resume ? ' is-invalid' : '')
               }
             />
             <ErrorMessage
               name="resume"
               component="div"
               className="invalid-feedback"
             />
             </div>
    </div>
    <div className="form-group row pad-t-15  ">
       <div className="flex-layout justify-content-center ">
       <button className='btn-style secondry-color' type="submit">
   Submit Resume
   </button>
   <button className='btn-style secondry-color'  onClick={() => setShowLoginModal(false)}>
   cancel
   </button>
       </div>
    </div>
    </Form>
       )}
   </Formik>
      ) : (
       < div
        style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '30vh',
        }}
    >
     <div className='successMsgIcon'><Icon.Check2Circle/></div> 
    <div> Applied &  Uploaded Successfully </div>
    </div>
      
      )}

        
      
      </Modal.Body>
    
    </Modal>
       
  )
}

export default ApplyJobPopUp