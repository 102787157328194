import React from 'react'
import Skeleton from '@mui/material/Skeleton';
function OtherDetailsSkeleton(props) {
  
  return (
    <div><div className='card'>
    <div className='card-body'>

      <p className="p-2"><Skeleton variant="rectangular" height={40} />
      </p>
      <p className="p-2"><Skeleton variant="rectangular" height={40} />
      </p>


    </div>
  </div></div>
  )
}

export default OtherDetailsSkeleton