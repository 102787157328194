import React, { useState, useEffect } from 'react'
import { useFormik, Form, Field, Formik, useField, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import MuiFormikAutoComplete from './../ui-component/FormFields/MuiFormikAutoComplete';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useSaveEducationDetailsMutation } from './../../rtk/profile';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import Modal from 'react-bootstrap/Modal';
import { educationType } from './../../utils/Constant';
import { monthNames } from './../../utils/Constant';
import * as Icon from 'react-bootstrap-icons';
import clsx from 'clsx';
import { Divider } from '@mui/material';
import { useLazyGetDataQuery } from './../../rtk/MetaData';
import { useLazyGetEducationDetailsQuery } from './../../rtk/profile';
import JSpinner from './../../components/jspinner/JSpinner';
import notify from '../../utils/Utils';
import CardSkeleton from './skeleton/CardSkeleton';
import moment from 'moment';

export default function Education(props) {
  const userId = useSelector((state) => state.authState.userId);
const {setIsmodified} =props;
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [educationDetailsList, setEducationDetailsList] = useState([]);
  const [universityData, setUniversityData] = useState();
  const [specializationData, setSpecialization] = useState();
  const [fetchTrigger, { data, error, isLoading, isFetching }] = useLazyGetDataQuery(userId);
  const [fetchEducationDetails, { data: educationData, error: eucationError, isLoading: educationIsLoading }] = useLazyGetEducationDetailsQuery(userId);
  const [educationLoading, setEducationLoading] = useState(true);
  const [deleteEducationList, setDeleteEducationList] = useState([]);
  async function fetchUniversityData() {
    const response = await fetchTrigger("univerisity");
    setUniversityData(response.data);
  }
  async function fetchSpecializationData() {
    const response = await fetchTrigger("specialization");
    setSpecialization(response.data);
  }
  async function fetchEducationDataList() {
    const response = await fetchEducationDetails();
    setEducationDetailsList(response.data);
    setEducationLoading(false)
  }
  useEffect(() => {
    fetchEducationDataList();
    fetchUniversityData();
    fetchSpecializationData();


  }, [])

  const [initState, setInitState] = useState({
    educationId: 0,
    eductaionType: '',
    institute: null,
    specialization: null,
    courseType: '',
    courseDurationFrom: '',
    courseDurationTo: ''
  });


  function handelAddEducation() {
    setShowModal(true);
    setInitState({
      educationId: 0,
      eductaionType: '',
      institute: null,
      specialization: null,
      courseType: '',
      courseDurationFrom: '',
      courseDurationTo: ''
    })
    setIsEdit(false);
  }

  function hideModel() {
    setIsEdit(false);
    setShowModal(false);
  }



  function handleEditEducation(index) {
    const toEditEducation = educationDetailsList.filter(a =>
      a.eductaionType === index
    );
    setInitState(toEditEducation[0]);
    setIsEdit(true);
    setShowModal(true);
    setIsmodified(true);
  }
  const [
    updateEducation, // This is the mutation trigger
    { error: updateProgramError, isLoading: isUpdating }, // This is the destructured mutation result
  ] = useSaveEducationDetailsMutation()

  function handledeleteEductaion(index) {
    setIsmodified(true);
    setEducationDetailsList(educationDetailsList.filter(a =>
      a.eductaionType !== index
    ));
    const eduList =educationDetailsList.filter(a =>
      a.eductaionType === index
    )[0];
    setDeleteEducationList([...deleteEducationList,eduList.educationId])
    
  }
  const saveEducationDetails = async () => {
    const requestObject = { data: {educationDetails: educationDetailsList,deleteDetails: deleteEducationList}, userid: userId }
    let error = "";
    setLoading(true)
    const returned = await updateEducation(requestObject).unwrap().catch(res => error = res);
    setLoading(false);
    if (returned.status == "success") {
      //notify("success", "Success Message", "Details saved Successfully");
      setIsmodified(false);
    } else {
      notify("danger", "Rrror Message", "Error in saving details");
    }
  }

  return (
    <>
      <div className='pad-10'>
        <div className='card-header flex-layout justify-content-between'>
          <div className='align-items-start flex-column'>
            Education Information
            <p className='profile-tagline'>
              Update your Education informaiton
            </p>
          </div>
          <div className='align-items-end flex-column'>
            {
               educationDetailsList.length != 0 &&  
               <>
               <button className='btn-style secondry-color' onClick={handelAddEducation}>Add Education Details</button>
              
            </>
            }
            <button className='btn-style secondry-color' type="submit" onClick={saveEducationDetails} disabled={loading}>
            {!loading && <span className='indicator-label'>save</span>}
              {loading && (
                <span className='indicator-progress' style={{ display: 'block' }}>
                  Please wait...
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
            
          </div>
        </div>
      </div>
      <EducationComp show={showModal} onHide={hideModel} universitydata={universityData} specializationdata={specializationData} educationdetailslist={educationDetailsList} seteducationdetailslist={setEducationDetailsList} isedit={isEdit} initValues={initState} setIsmodified={setIsmodified}/>
     
      {educationLoading ? ( <>
      <CardSkeleton />
    </>) : (
       educationDetailsList.length != 0 ? ( 
      educationDetailsList.map((row, index) => (
        <>
          <div className='card no-border no-box-shadow' >
          
            <div className='card-header flex-layout justify-content-between'>
              <b>
                {
                  educationType.map((constantData) => (
                    <>{constantData.id == row.eductaionType && constantData.name + " in " + row.institute.name} </>
                  ))
                }
              </b>
              <div>
                <button className='mar-l-5 no-border no-backgroud' onClick={() => handleEditEducation(row.eductaionType)} ><Icon.PencilSquare size={25} /> </button>
                <button className='mar-l-5 no-border no-backgroud' onClick={() => handledeleteEductaion(row.eductaionType)}><Icon.ArchiveFill size={25} /></button>
              </div>
            </div>
            <div className='card-body '>
              <div>{row.specialization.name}</div>
              <div>{row.courseType} |  {monthNames[(row.courseDurationFrom.split("-")[0])-1] +" "+row.courseDurationFrom.split("-")[2]} - {monthNames[(row.courseDurationTo.split("-")[0])-1] +" "+row.courseDurationTo.split("-")[2]}</div>
            </div>
          </div>
        </>
      
    )
  )): (<>  <div
    style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '30vh',
    }}
>
   <b>Nothing to display. <button className='btn-style secondry-color' onClick={handelAddEducation}>Add Education Details</button></b>
</div></>))
      }
      <div>
      </div>
    </>

  )
}



export function EducationComp(props) {
  const [value, setValue] = React.useState(null);
  const { educationdetailslist, seteducationdetailslist, isedit, initValues, onHide, universitydata, specializationdata,setIsmodified } = props;
  const [errorMessage, setErrorMessage] = React.useState();
  const instituteOption = universitydata;
  const specializationOption = specializationdata;

  const duplicateEducationType = (list, value) => {
    const isExisting = list.some((option) => value === option.eductaionType);
    return !isExisting;
  };
  const dateValidation = (to, from,ctx) => {
    console.log("to ::"+to + " from:"+from )
    let errors = "courseDurationTo";
    let isError = false;
    let msg=null;
    if(from == undefined){
      msg='Please select from Date' ;
    // isError=true;
    let ve = ctx.createError({ message: 'Please select from Date' });
      ve.errors = errors; // these are not shown
      console.log("ve.errors ::"+JSON.stringify(ve))
      return ve;
    }
    let fromDate,toDate,ve;
    if(!isError){
      fromDate= new Date(from);
      toDate= new Date(to);
    }
    if(fromDate > new Date()){
      msg='To date should be less then Today Date' ;
      // isError=true;
      let ve = ctx.createError({ message: 'To date should be less then from date' });
      ve.errors = errors; // these are not shown
      console.log("ve.errors ::"+JSON.stringify(ve))
      return ve;
    }
    
    if(fromDate > toDate){
      msg='To date should be less then from date' ;
      // isError=true;
      let ve = ctx.createError({ message: 'To date should be less then from date' });
      ve.errors = errors; // these are not shown
      console.log("ve.errors ::"+JSON.stringify(ve))
      return ve;
    }
    
    console.log("fromDate < toDate :: sd " + (fromDate < toDate) + " isError ::"+isError)

    if(isError){
      console.log("In Error return ")
      let ve = ctx.createError({ message: msg });
      ve.errors = errors; // these are not shown
      console.log("ve.errors ::"+JSON.stringify(ve))
      return ve;
    }
   return true;
  };
  let eductaionValidation ="";
  if(isedit){
     eductaionValidation = Yup.object().shape({
      eductaionType: Yup.string().required('Please select eductaion type'),
      institute: Yup.mixed().required('Please Enter Institute Name'),
      specialization: Yup.mixed().required('Please Enter specialization'),
      courseType: Yup.string().required('Please select course type'),
      courseDurationFrom: Yup.string().required('Please Enter valid date'),
      courseDurationTo: Yup.string().required('Please Enter valid date')
    });
  }else {
     eductaionValidation = Yup.object().shape({
      eductaionType: Yup.string().required('Please select eductaion type').
        test("duplicate name", "This Education details is already given", (values) => {
          return duplicateEducationType(educationdetailslist, values);
        }),
      institute: Yup.mixed().required('Please Enter Institute Name'),
      specialization: Yup.mixed().required('Please Enter specialization'),
      courseType: Yup.string().required('Please select course type'),
      courseDurationFrom: Yup.string().required('Please Enter valid date'),
      courseDurationTo: Yup.string().required('Please Enter valid date').
      test("date test",(courseDurationFrom,courseDurationTo) =>{
    
       console.log("courseDurationFrom::"+courseDurationFrom +" courseDurationTo::"+courseDurationTo.parent.courseDurationFrom)
       return dateValidation(courseDurationFrom,courseDurationTo.parent.courseDurationFrom,courseDurationTo);  
    }),
     
    });
  }

  const [
    updateEducation,// This is the mutation trigger
    { error: updateProgramError, isLoading: isUpdating, isSuccess }, // This is the destructured mutation result
  ] = useSaveEducationDetailsMutation();

  return (
    <div> <Modal
      {...props}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
      </Modal.Header>
      <Modal.Body>
        <Formik
          initialValues={initValues}
          validationSchema={eductaionValidation}
          onSubmit={(values, { setSubmitting, setErrors,dirty }) => {
            if(isedit){
              var index = -1;
              var filteredObj = educationdetailslist.find(function(item, i){
                if(item.eductaionType === values.eductaionType){
                  index = i;
                  return i;
                }
              });
              let tempArray =[...educationdetailslist];
              tempArray[index] = values;
              seteducationdetailslist(tempArray);
            onHide();
            }else {
            const isExisting = duplicateEducationType(educationdetailslist, values.educationType);
            if (isExisting) {
              seteducationdetailslist([...educationdetailslist, values])
              onHide();
            } else {
              setErrorMessage("Explicit error")
            }
            
          }
          console.log(" Education Details setinng true")
          setIsmodified(true);
        }
          
        }
        >
          {({ errors, touched, resetForm, isValidating, handleChange, setFieldValue }) => (
            <Form>
              <div className='card-header flex-layout justify-content-center'>
                <div className='align-items-start flex-column'>
                  Add Education Information
                  <p className='profile-tagline'>
                    Update your Education informaiton
                  </p>
                </div>
                <div className='align-items-end flex-column'>
                </div>
              </div>
              <Divider />
              <div className='card-body'>
                <div class="form-group row pad-5">
                  <label class="col-xl-3 col-lg-3 col-form-label font-14">Education</label>
                  <div class="col-lg-9 col-xl-6">
                    <Field as="select" name="eductaionType" disabled={isedit}
                      className={
                        clsx(
                          'form-control',
                          { 'is-invalid': touched.eductaionType && errors.eductaionType },
                          {
                            'is-valid': touched.eductaionType && !errors.eductaionType,
                          }
                        )}
                      id="exampleFormControlSelect1">
                      <option className='font-14' value="">Select Education Type</option>
                      {educationType.map((row, index) => (
                        <option className='font-14' value={row.id}>{row.name}</option>
                      ))}
                    </Field>
                    {touched.eductaionType && errors.eductaionType && (
                      <div className='error-message p-2'> {errors.eductaionType}</div>
                    )}
                  </div>
                </div>

                <div class="form-group row pad-5">
                  <label class="col-xl-3 col-lg-3 col-form-label font-12">University/Institute</label>
                  <div class="col-lg-9 col-xl-6">
                    <MuiFormikAutoComplete keyName="institute" options={instituteOption} selectedValue={initValues.institute} error={errors.institute} />
                    {touched.institute && errors.institute && (
                      <div className='error-message p-2'> {errors.institute}</div>
                    )}
                  </div>
                </div>

                <div class="form-group row pad-5">
                  <label class="col-xl-3 col-lg-3 col-form-label font-12">Specialization</label>
                  <div class="col-lg-9 col-xl-6">
                    <MuiFormikAutoComplete keyName="specialization" options={specializationOption} selectedValue={initValues.specialization} error={errors.specialization} />
                    {touched.specialization && errors.specialization && (
                      <div className='error-message p-2'> {errors.specialization}</div>
                    )}
                  </div>
                </div>

                <div className="form-group row pad-5">
                  <label className="col-xl-3 col-lg-3 col-form-label font-12">Course Type</label>
                  <div className="col-lg-9 col-xl-6">
                    <div className="form-check form-check-inline">
                      <Field className="form-check-input font-14"
                        name="courseType"
                        type="radio"
                        value="fullTime"
                      /><label className="form-check-label" htmlFor="inlineRadio1">Full Time</label>
                    </div>
                    <div className="form-check form-check-inline">
                      <Field className="form-check-input font-14"
                        name="courseType"
                        type="radio"
                        value="partTime"
                      />
                      <label className="form-check-label" htmlFor="inlineRadio2">Part Time</label>
                    </div>
                    <div className="form-check form-check-inline">
                      <Field className="form-check-input font-14"
                        name="courseType"
                        type="radio"
                        value="distance"
                      />
                      <label className="form-check-label" htmlFor="inlineRadio2">Correspondence/Distance learning</label>
                    </div>
                    {touched.courseType && errors.courseType && (
                      <div className='error-message p-2'> {errors.courseType}</div>
                    )}
                  </div>
                </div>


                <div class="form-group row pad-5">
                  <label class="col-xl-3 col-lg-3 col-form-label font-12">Course duration</label>
                  <div class="col-lg-9 col-xl-6">
                    <div class="row">
                      <div class="col">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker label="From date" className='custom-input' views={['month', 'year']} defaultValue={dayjs(new Date(initValues.courseDurationFrom))} onChange={(value) => setFieldValue("courseDurationFrom", new Date(value).getMonth() + 1 + "-1-" + new Date(value).getFullYear())}  />
                        </LocalizationProvider>
                      </div>
                      <div class="col">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker label="To date" className='custom-input' views={['month', 'year']} defaultValue={dayjs(new Date(initValues.courseDurationTo))} onChange={(value) => setFieldValue("courseDurationTo", new Date(value).getMonth() + 1 + "-1-" + new Date(value).getFullYear())}  />
                        </LocalizationProvider>
                      </div>
                    </div>
                    
                    { //touched.courseDurationFrom && errors.courseDurationFrom || touched.courseDurationTo && errors.courseDurationTo && (
                      <div className='error-message p-2'> {errors.courseDurationTo}</div>
                    //)
                    }

                  </div>
                </div>
              </div>

              {
                isedit ? ( <> <div className='flex-layout justify-content-center'>
                <button className='btn-style secondry-color' type="submit" >Edit Education details</button>
              </div></> ) : (  <div className='flex-layout justify-content-center'>
                <button className='btn-style secondry-color' type="submit" >Add Education details</button>
              </div>)
              }


             
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal></div>
  )
}



