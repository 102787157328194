import React,{ useRef } from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { ReactComponent as YourSvg } from '../../assets/svg-icons/shield-user-privacy-user-protection-user-shield-webpage-svgrepo-com.svg';
import AuthLayout from './../../auth/AuthLayout'
function RegisterPopup(props) {
  const {registerationtype} =props;
  return (
    <div>
      <Modal
      {...props}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
      
      </Modal.Header>
      <Modal.Body>
      <AuthLayout viewType={"Register"} registerationType={registerationtype} onHide={props.onHide}/>
      </Modal.Body>
    
    </Modal>
    </div>
  )
}

export default RegisterPopup