import React,{useContext,useEffect} from 'react'
import {Routes, Route, BrowserRouter, Navigate} from 'react-router-dom';
import MainContentLayout from '../layout/MainContentLayout';

import JobsListLayout from '../layout/jobsListLayout/JobsListLayout';
import ErrorsComponent from '../components/error/ErrorsComponent';
import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';
import PrivateRouter from './PrivateRouter';
import PublicJobDetails from '../layout/publicjobDetails/PublicJobDetails';
import UserProfile from '../layout/userProfileUpdater/UserProfile';
import CompanyProfile from '../layout/companyProfile/CompanyProfile';
import AlertPopup from '../components/alertPopup/AlertPopup';
import AboutUs from '../layout/aboutUs/AboutUs';
import TermsAndCond from '../layout/termsAndCond/TermsAndCond';
import PolicyTerms from '../layout/policyTerms/PolicyTerms';
import ReportIssue from '../layout/reportIssue/ReportIssue';
import ReactGA from "react-ga4";
import AuthRouter from './../auth/AuthRouter';
import { useSelector } from 'react-redux';
import Page404 from '../layout/404Layout/Page404';
import VerifyAccount from '../auth/component/VerifyAccount';
import BoostProfile from '../components/serviceList/BoostProfile';
import BoostProfileLayout from '../components/serviceList/BoostProfileLayout';
import ResumeBuilderAssistance from '../components/serviceList/ResumeBuilderAssistance';
function AppRouter() {

  //const TRACKING_ID = "UA-249068926-1"; // OUR_TRACKING_ID
  //const TRACKING_ID = "G-EG81HM74M8"; // OUR_TRACKING_ID
  const isAuthenticated = useSelector((state) => state.authState.isAuthenticated);
  
  //ReactGA.initialize(TRACKING_ID);
  useEffect(() => {
    console.log("window.location.pathname ::"+ window.location.pathname + " window.location.search ::"+window.location.search)
    ReactGA.send( { hitType: "pageview", page: window.location.pathname + window.location.search})
  }, []);

  return (
    <>
    <Header/>
    
     <Routes>
     
     <Route path='/home' element={<MainContentLayout/>} />
     <Route path='/' element={<MainContentLayout/>} />
     <Route path='/jobs' element={<JobsListLayout/>} />
     <Route path='/user/:userId' element={<UserProfile/>} />
     <Route path='/job/:userId' element={<PublicJobDetails/>} />
     <Route path='/company/:comapnyId' element={<CompanyProfile/>} />
     <Route path='/about-us' element={<AboutUs/>} />
     <Route path='/terms-and-cond' element={<TermsAndCond/>} />
     <Route path='/policy-terms' element={<PolicyTerms/>} />
     <Route path='/report-issue' element={<ReportIssue/>} />
     <Route path='/verify-account/:token' element={<VerifyAccount/>} />
     <Route path='/boost-profile' element={ <BoostProfileLayout/>} />
     <Route path='/resume-assist' element={ <ResumeBuilderAssistance/>} />
     <Route path='*' element={<Page404 />} />
     
     {isAuthenticated ? (
    
            <>
              <Route path='/*' element={<PrivateRouter />} />
              <Route index element={<Navigate to='/home' />} />
              <Route path='auth/*' element={<PrivateRouter />} />
            </>
          ) : (
            <>
              {/*
              <Route path='auth/*' element={<AuthRouter />} />
              <Route path='*' element={<Navigate to='/auth' />} />
              */
              }
              <Route path='*' element={<MainContentLayout/>} />
            </>
          )}
      <Route/>
     </Routes>
     <Footer/>
   </>
  )
}

export default AppRouter;



